import { makePreRequest, makeRequest } from '@/common/MaRequestPooler';
import { cacheRequest } from '@/common/MaRequestPooler';
import { MIN_IN_MS } from '@/common/MaUtils.mjs';
import { paramsToQuery } from '@/common/MaUtils.mjs';

export function getUserInfo() {
    return makeRequest('/account/user/info');
}

export function getIntegrations() {
    return makeRequest('/sa/integrations');
}

export function getUserApps(live = false) {
    let qs = '';
    if (live) {
        qs = `?${ paramsToQuery({ live }) }`;
    }
    return makeRequest(`/sa/account/user/apps${qs}`);
}

export function deleteIntegration(integId) {
    return makeRequest(`/sa/integrations/${ integId }`, {
        method: 'DELETE',
    });
}

export function refreshIntegration(integId) {
    return makeRequest(`/sa/integrations/${ integId }`);
}

export function updateContactInfo(data) {
    return makeRequest('/account/user/contact-info', {
        method: 'PUT',
        data,
    });
}

export function getTeamMembers() {
    return makeRequest(`/account/sa/team-member`);
}

export function inviteTeamMembers(emails) {
    return makeRequest(`/account/team-member`, {
        method: 'POST',
        data: emails,
    });
}

export function makeTeamMemberAsAdmin({ userId }) {
    return makeRequest(`/account/team-member/${ userId }/make-admin`, {
        method: 'POST',
    });
}

export function reInviteTeamMember({ userId }) {
    return makeRequest(`/account/team-member/${ userId }/reinvite`, {
        method: 'POST',
    });
}

export function deleteTeamMember({ userId }) {
    return makeRequest(`/account/team-member/${ userId }`, {
        method: 'DELETE',
    });
}

export function getProducts(cacheInvalidate = false) {
    // deduplicate: false -> let this request be duplicate if cacheInvalidate: true
    return cacheRequest(`/account/products`, {}, {
        cacheTimeout: MIN_IN_MS,
        deduplicate: false,
        cacheInvalidate,
    });
}

export function getRefreshToken( impersonationId ) {
    return makePreRequest(`/account/impersonate?impersonationId=${ impersonationId }`);
}

export function sendIntercomEvent(eventName, platform, optionalParams) {
    const params = optionalParams || {};
    return makeRequest(`/experiment/intercom/platform/${platform}/event/${eventName}/send?${ paramsToQuery(params) }`, {
        method: 'POST',
    });
}


/**
 * Add SSO Client
 * @param clientId //
 * @param metadataUrl //
 * @returns {Promise} //
 */
export function addSSOClient({ clientId, metadataUrl }) {
    return makeRequest('/account/sso', {
        method: 'POST',
        data: {
            clientId,
            metadataUrl,
        },
    });
}

/**
 * Update SSO Client
 * @param clientId //
 * @param metadataUrl //
 * @returns {Promise} //
 */
export function updateSSOClient({ clientId, metadataUrl }) {
    return makeRequest('/account/sso', {
        method: 'PUT',
        data: {
            clientId,
            metadataUrl,
            enable: true,
        },
    });
}

/**
 * Get SSO Clients
 * @returns {Promise} //
 */
export function getSSOClients() {
    return makeRequest('/account/sso');
}

/**
 * Check SSO
 * @returns {Promise} //
 */
export function checkSSO() {
    return makeRequest('/account/sso/enable');
}

/**
 * Delete SSO Client
 * @param idpType //
 * @returns {Promise} //
 */
export function deleteSSOClient(idpType) {
    return makeRequest(`/account/sso?${ paramsToQuery({ idpType })}`, {
        method: 'DELETE',
    });
}
