import { mapState } from 'pinia';
import { useAccountStore } from '@/stores/Account';

export default {
    computed: {
        ...mapState(useAccountStore, ['activeOrg']),
        accountIntegrated() {
            return this.activeOrg !== null;
        },
    },
};
