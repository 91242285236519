<template>
    <div :class="{'ma-sidebar-v2': true, 'collapsed': isCollapsed }">
        <!--Quick Actions section-->
        <div v-if="!isCollapsed" class="border border-gray-800 rounded-lg mx-3 mt-3">
            <div class="flex justify-between px-2 py-3">
                <img
                    src="@/assets/sa-logo-v2.svg"
                    class="w-[116px] cursor-pointer"
                    alt="Search Ads Logo"
                    @click="goHome()"
                >
                <ma-side-menu-icon-button
                    icon="sidebar-left"
                    active-icon="sidebar-right-bold"
                    class="bg-transparent"
                    @click="changeCollapse"
                />
            </div>
            <div class="border-t border-gray-800 flex">
                <div class="flex flex-col items-center border-r border-gray-800 p-3">
                    <ma-toggle size="small" @click="goToMobileAction"/>
                    <span class="text-gray-400 text-xs mt-1 leading-3">{{ t('switchTo') }}</span>
                    <span class="text-white text-sm leading-3">{{ t('ma') }}</span>
                </div>
                <div class="grow flex flex-col">
                    <span class="text-gray-200 text-sm ml-2 mt-2">{{ t('quickActions') }}</span>
                    <div class="flex justify-around mt-2">
                        <ma-tooltip :title="t('settings')">
                            <ma-icon-button
                                icon="settings"
                                size="xs"
                                class="bg-transparent"
                                :disabled="isNoUserAccess"
                                @click="navigateTo('settings')"
                            />
                        </ma-tooltip>
                        <ma-tooltip :title="t('requestDemo')">
                            <a
                                :href="REQUEST_DEMO_URL"
                                target="_blank"
                                class="flex items-center"
                                @click="trackDemo"
                            >
                                <ma-icon-button
                                    icon="calendar"
                                    size="xs"
                                    class="bg-transparent"
                                    active-icon="calendar-bold"
                                />
                            </a>
                        </ma-tooltip>
                        <ma-tooltip :title="t('contactSupport')">
                            <ma-icon-button
                                icon="messages"
                                size="xs"
                                class="bg-transparent"
                                @click="showIntercom"
                            />
                        </ma-tooltip>
                        <ma-tooltip :title="t('competitors')">
                            <ma-icon-button
                                icon="cup"
                                size="xs"
                                class="bg-transparent"
                                :disabled="isNoUserAccess"
                                @click="navigateTo('competitors')"
                            />
                        </ma-tooltip>
                        <ma-tooltip :title="t('integrations')">
                            <router-link :to="{ name: 'integration' }" class="flex items-center">
                                <ma-icon-button
                                    icon="plug"
                                    size="xs"
                                    class="bg-transparent"
                                    :disabled="isNoUserAccess"
                                    active-icon="plug-bold"
                                />
                            </router-link>
                        </ma-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="flex items-center justify-center mt-3">
            <ma-side-menu-icon-button
                icon="sidebar-right"
                active-icon="sidebar-left-bold"
                class="bg-transparent"
                @click="changeCollapse"
            />
        </div>
        <!--Menu Items-->
        <div class="ma-sidebar-menu-section">
            <template v-for="(item, ind) in SIDEBAR_ITEMS" :key="ind">
                <ma-sidebar-item
                    v-if="!item.isGroup && (!item.checkFeature || userHasFeature(item?.feature))"
                    :item="item"
                    :is-collapsed="isCollapsed"
                />
                <template v-else>
                    <span v-if="!isCollapsed" class="text-gray-500 text-sm my-1">
                        {{ t(item.titleKey) }}
                    </span>
                    <ma-tooltip
                        v-else
                        :title="t(item.titleKey)"
                        placement="right"
                        type="primary"
                    >
                        <div class="text-gray-500 text-sm my-1 text-center">
                            {{ t(item.titleKeyAbb) }}
                        </div>
                    </ma-tooltip>
                    <template v-for="(groupItem, gIndex) in item.groupItems" :key="`${gIndex}-${ind}`">
                        <ma-sidebar-item
                            v-if="!groupItem.checkFeature || userHasFeature(groupItem?.feature)"
                            :item="groupItem"
                            :is-collapsed="isCollapsed"
                        />
                    </template>
                </template>
            </template>
        </div>
        <div>
            <router-link :to="{ name: 'setup-guide' }">
                <div v-if="!isCollapsed" class="ma-setup-completeness">
                    <span class="text-gray-400 font-medium text-md">
                        {{ t('setupCompleteness') }}
                    </span>
                    <ma-progress
                        :percent="onboardingProgress"
                        color="green"
                    />
                </div>
                <ma-tooltip
                    v-else
                    :title="t('setupCompleteness')"
                    placement="right"
                    type="primary"
                >
                    <div class="font-medium bg-secondary-blue h-12 flex items-center justify-center">
                        <span class="text-green-300">{{ onboardingProgress }}%</span>
                    </div>
                </ma-tooltip>
            </router-link>
        </div>
    </div>
</template>


<script>
    import i18n from '@/components/MaSidebar/Sidebar.i18n';
    export default {
        i18n,
    };
</script>

<script setup>
    import { useRouter, useRoute } from 'vue-router';
    import {
        MaSideMenuIconButton,
        MaToggle,
        MaIconButton,
        MaTooltip,
        MaProgress,
    } from '@mobileaction/action-kit';
    import MaSidebarItem from '@/components/MaSidebar/MaSidebarItem.vue';
    import { useGlobal, useMaStorage } from '@/composables';
    import {
        requestDemoEvent,
        contactSupportEvent,
        saMaSwitchEvent,
        sidebarEvents,
    } from '@/plugins/segment/eventDefinitions';
    import { INSIGHTS_DOMAIN, REQUEST_DEMO_URL } from '@/common/Config';
    import { useI18n } from 'vue-i18n';
    import { SIDEBAR_ITEMS } from '@/common/SidebarUtils';
    import { useSetupGuideStore } from '@/stores/SetupGuide';
    import { storeToRefs } from 'pinia';
    import { useAccountStore } from '@/stores/Account';
    import { usePlanStore } from '@/stores/Plan';

    const { t } = useI18n();

    const app = useGlobal();
    const router = useRouter();
    const route = useRoute();

    const isCollapsed = useMaStorage('sidebarCollapsed', false, localStorage);
    const changeCollapse = () => isCollapsed.value = !isCollapsed.value;

    const goHome = () => {
        router.push({ name: 'home' });
    };
    const iconRouteEventMapping = {
        competitors: sidebarEvents.competitorsIconClick,
        settings: sidebarEvents.settingsIconClick(route),
    };
    const navigateTo = (name) => {
        app.$segment.track(...iconRouteEventMapping[name].toSegment());
        router.push({ name });
    };
    const trackDemo = () => {
        app.$segment.track(...requestDemoEvent.toSegment());
    };
    const showIntercom = () => {
        app.$segment.track(...contactSupportEvent.toSegment());
        app.$maIntercom.show();
    };
    const goToMobileAction = () => {
        app.$segment.track(...saMaSwitchEvent.toSegment());
        const landingUrl = INSIGHTS_DOMAIN;
        window.open(landingUrl, '_self').focus();
    };
    const setupGuideStore = useSetupGuideStore();
    const { onboardingProgress } = storeToRefs(setupGuideStore);

    const accountStore = useAccountStore();
    const { isNoUserAccess } = storeToRefs(accountStore);
    const planStore = usePlanStore();
    const { validFeatures } = storeToRefs(planStore);
    const userHasFeature = (feature) => {
        if (!feature) {
            return false;
        }
        return validFeatures.value.includes(feature);
    };
</script>

<style lang="less">
.ma-sidebar-v2 {
    @apply ~"w-[248px]" h-screen bg-primary-blue desktop:flex flex-col mobile:hidden transition-[width];
    &.collapsed {
        @apply w-16;
    }
    .ma-sidebar-menu-section {
        @apply mt-3 grow overflow-y-auto ml-1 mr-1;
        &::-webkit-scrollbar {
            @apply hidden;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
    .ma-setup-completeness {
        @apply bg-secondary-blue px-4 py-3 mt-1;
        .ma-progress-line {
            @apply w-full;
        }
    }
}
</style>
