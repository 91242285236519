import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { randomString, MEMBERSHIP_TYPE } from '@/common/MaUtils.mjs';
import jwtDecode from 'jwt-decode';
import * as MaCookieStorage from '@/common/MaCookieStorage';
import { SB_LIMIT_REMOVED_ACCOUNTS } from '@/common/Config';
import { globalProperties as app } from '@/main';


export const useAuthStore = defineStore('auth', () => {

    const unauthorized = ref(app.$maSessionStorage.unauthorized);
    const loginCsrf = ref(app.$maLocalStorage.loginCsrf);
    const userAuth = ref(MaCookieStorage.get(MaCookieStorage.COOKIES.AUTH));
    const newsletterOptIn = ref(app.$maLocalStorage.newsletterOptIn);
    const user = ref(app.$maLocalStorage.userInfo);
    const integrationOnBoardingCompleted = ref(app.$maLocalStorage.integrationOnBoardingCompleted);
    const forwardTo = ref(app.$maLocalStorage.forwardTo);

    const updateUser = (newUser) => {
        user.value = newUser;
        app.$maLocalStorage.userInfo = newUser;
    };

    const regenerateLoginCsrf = () => {
        loginCsrf.value = randomString(32);
        app.$maLocalStorage.loginCsrf = loginCsrf.value;
    };

    const setUserAuth = (userAuthVal) => {
        MaCookieStorage.set(MaCookieStorage.COOKIES.AUTH, userAuthVal, 30, MaCookieStorage.AUTH_COOKIE_OPTIONS);
        userAuth.value = MaCookieStorage.get(MaCookieStorage.COOKIES.AUTH);
    };


    const setIntegrationOnBoarding = (completed) => {
        integrationOnBoardingCompleted.value = completed;
        app.$maLocalStorage.integrationOnBoardingCompleted = completed;
    };

    const setForwardTo = (newForwardTo) => {
        forwardTo.value = newForwardTo;
        app.$maLocalStorage.forwardTo = newForwardTo;
    };

    const userHasAuth = computed(() => !!userAuth.value);

    const integrationOnBoardingCompletedComputed = computed(() => integrationOnBoardingCompleted.value || false);

    const jwtData = computed(() => {
        let jwt = userAuth.value?.jwt;
        if (!jwt) {
            return null;
        }
        try {
            return jwtDecode(jwt);
        } catch (e) {
            app.$log.debug('Failed to parse jwt token', e);
            return null;
        }
    });

    const rftData = computed(() => {
        let jwt = userAuth.value?.refreshToken;
        if (!jwt) {
            return null;
        }
        try {
            return jwtDecode(jwt);
        } catch (e) {
            app.$log.debug('Failed to parse refresh token', e);
            return null;
        }
    });

    const isAdminUser = computed(() => jwtData.value?.membershipType === MEMBERSHIP_TYPE.ADMIN);

    const maImp = computed(() => jwtData.value?.imp || null);

    const maImpFullAccess = computed(() => (jwtData.value?.imp && jwtData.value?.fullAccessImp) || false);

    const maImpPrefix = computed(() => maImp.value ? `MA-admin:${maImp.value}:` : '');

    const isUserSBLimitRemoved = computed(() => {
        const limitRemovedAccounts = SB_LIMIT_REMOVED_ACCOUNTS?.split(',');
        return jwtData.value?.accountId && limitRemovedAccounts?.length && limitRemovedAccounts.includes(`${jwtData.value?.accountId}`);
    });

    return {
        unauthorized,
        loginCsrf,
        userAuth,
        newsletterOptIn,
        user,
        integrationOnBoardingCompleted,
        forwardTo,
        updateUser,
        regenerateLoginCsrf,
        setUserAuth,
        setIntegrationOnBoarding,
        setForwardTo,
        userHasAuth,
        integrationOnBoardingCompletedComputed,
        jwtData,
        rftData,
        isAdminUser,
        maImp,
        maImpFullAccess,
        maImpPrefix,
        isUserSBLimitRemoved,
    };
});
