<script setup>
    import { computed, onMounted, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useTimeZoneController } from '@/controllers/TimeZoneController';
    import messages from '@/components/TimeZoneModal/TimeZone.i18n';
    import MaTimeZoneSelector from '@/components/TimeZoneModal/MaTimeZoneSelector.vue';
    import { MaButton, MaCheckbox, MaModal, MaNotification, MaTypography, MaLinkButton } from '@mobileaction/action-kit';
    import { useSetupGuideStore } from '@/stores/SetupGuide';

    defineOptions({
        i18n: messages,
    });

    const props = defineProps({
        list: { type: Array, required: true },
    });

    const { t } = useI18n();
    const timeZoneController = useTimeZoneController();
    const setupStore = useSetupGuideStore();

    const ReminderCase = Object.freeze({
        ALL: 'ALL',
        SOME: 'SOME',
    });

    const acceptTimeZone = ref(false);
    const modalVisible = ref(true);
    const eligibleTimezones = ref([]);
    const eligibleTimezonesLoading = ref(true);
    const timeZoneUpdateLoading = ref(false);
    const reminderCase = ref(ReminderCase.SOME);
    const integrations = ref([]);

    const fetchCampaignGroupEligibleTimezones = async () => {
        try {
            if (!validOrganizationIds.value.length) {
                return;
            }
            const timezones = await timeZoneController.fetchEligibleTimezones(validOrganizationIds.value);
            eligibleTimezones.value = timezones.map(org => ({
                ...org,
                eligibleTimeZones: org.eligibleTimeZones.sort(tz => tz === 'UTC' ? -1 : 1),
            }));
        } catch (error) {
            console.error(error);
        } finally {
            eligibleTimezonesLoading.value = false;
        }
    };

    const applyTimeZones = async () => {
        timeZoneUpdateLoading.value = true;
        const timeZonesToUpdate = integrations.value
            .filter(org => org.valid && org.status === 'VALID')
            .filter(integ => integ.eligibleTimeZones?.length > 1)
            .map(org => ({
                orgId: org.orgId,
                timeZone: org.timeZone,
            }));
        try {
            await timeZoneController.updateCampaignGroupTimeZones(timeZonesToUpdate);
            await setupStore.fetchOnboardingStatus();
            if (!setupStore.timeZoneStatus) {
                MaNotification.success({
                    title: t('messages.success.operationCompleted'),
                    duration: 3000,
                    placement: 'topRight',
                    type: 'success',
                    icon: 'tick-circle-bold',
                    closable: true,
                    size: 'small',
                    variant: 'filled',
                });
                modalVisible.value = false;
            }
        } catch (e) {
            MaNotification.error({
                title: t('messages.errors.somethingWentWrong'),
                description: t(`messages.errors.${e.errorData.errorCode}`),
                duration: 5000,
                placement: 'topRight',
                type: 'success',
                icon: 'tick-circle-bold',
                closable: true,
                size: 'small',
                variant: 'filled',
            });
            console.error('Failed to apply timezone', e);
        } finally {
            timeZoneUpdateLoading.value = false;
        }

    };

    const checkReminderCase = () => {
        const allHaveMultipleTimeZones = validOrganizations.value.every(org => org.eligibleTimeZones.length === 1);
        const someHaveMultipleTimeZones = validOrganizations.value.some(org => org.eligibleTimeZones.length > 1);

        if (allHaveMultipleTimeZones) {
            reminderCase.value = ReminderCase.ALL;
        } else if (someHaveMultipleTimeZones) {
            reminderCase.value = ReminderCase.SOME;
        }
    };

    const validOrganizationIds = computed(() =>
        Object.values(props.list).flat()
            .filter(org => org.valid && org.status === 'VALID')
            .map(org => org.orgId)
    );

    const validOrganizations = computed(() => {
        const uniqueOrganizations = integrations.value
            .filter(org => org.valid && org.status === 'VALID')
            .reduce((acc, org) => {
                if (!acc.some(existingOrg => existingOrg.orgId === org.orgId)) {
                    acc.push(org);
                }
                return acc;
            }, []);

        const sortedOrganizations = uniqueOrganizations.map((org) => {
            const orgTimeZones = eligibleTimezones.value.find(tz => String(tz.orgId) === String(org.orgId));
            const timeZones = orgTimeZones ? orgTimeZones.eligibleTimeZones.map(tz => ({
                label: tz,
                value: tz,
            })) : [];

            return {
                ...org,
                eligibleTimeZones: timeZones,
            };
        });

        return sortedOrganizations.sort((a, b) => b.eligibleTimeZones.length - a.eligibleTimeZones.length);
    });



    onMounted(async () => {
        await fetchCampaignGroupEligibleTimezones();
        integrations.value = Object.values(props.list)
            .flat()
            .map(org => ({
                ...org,
                eligibleTimeZones: eligibleTimezones.value.find(tz => String(tz.orgId) === String(org.orgId))?.eligibleTimeZones,
                timeZone: (eligibleTimezones.value.find(tz => String(tz.orgId) === String(org.orgId))?.eligibleTimeZones[0]) || null,
            }))
            .filter(org => org.valid && org.status === 'VALID')
            .reduce((acc, org) => {
                if (!acc.some(existingOrg => existingOrg.orgId === org.orgId)) {
                    acc.push(org);
                }
                return acc;
            }, []);
        integrations.value =  integrations.value.sort((a, b) => b.eligibleTimeZones.length - a.eligibleTimeZones.length);
        checkReminderCase();
    });

</script>

<template>
    <ma-modal
        :visible="modalVisible"
        width="900px"
        class="ma-ads-manager-logs-table"
    >
        <template #default>
            <ma-typography type="body-2" weight="medium">
                <span>{{ t('chooseTimezoneTitle') }}</span>
            </ma-typography>
            <ma-typography type="body-4" class="mr-1" weight="regular">
                <span v-if="reminderCase === ReminderCase.ALL">{{ t('chooseTimezoneDescription') }}</span>
                <span v-else-if="reminderCase === ReminderCase.SOME">{{ t('chooseTimezoneDescriptionSome') }}</span>
                <ma-link-button
                    target="_blank"
                    href="https://help.mobileaction.co/en/articles/9679767-integrating-your-apple-search-ads-account-with-searchads-com" size="medium"
                    color="blue"
                >
                    Learn More
                </ma-link-button>
            </ma-typography>
            <a-table
                class="py-10"
                :scroll="{ y: '40dvh' }"
                :data-source="validOrganizations"
                :pagination="false"
                :loading="eligibleTimezonesLoading"
                :row-key="record => record.orgId"
            >
                <a-table-column
                    key="index"
                    title="#"
                    width="150px"
                >
                    <template #default="{ index }">
                        {{ index + 1 }}
                    </template>
                </a-table-column>
                <a-table-column
                    key="orgName"
                    align="left"
                    :title="t('searchAdsCampaignGroups')"
                >
                    <template #default="{ record }">
                        {{ record.orgName }}
                    </template>
                </a-table-column>
                <a-table-column
                    key="timeZone"
                    :title="t('timeZone')"
                    width="250px"
                >
                    <template #default="{ record, index }">
                        <ma-time-zone-selector
                            v-model:time-zone="integrations[index].timeZone"
                            :time-zone-list="record.eligibleTimeZones"
                            :loading="eligibleTimezonesLoading"
                            :time-zone-disabled="record.eligibleTimeZones.length === 1"
                            @update:time-zone="val => integrations[index].timeZone = val"
                        />
                    </template>
                </a-table-column>
            </a-table>
            <div class="flex items-baseline justify-between mb-3">
                <ma-checkbox v-model:checked="acceptTimeZone">
                    <ma-typography type="body-4" weight="regular">
                        <span v-html="t('acknowledgeText')"/>
                    </ma-typography>
                </ma-checkbox>
                <ma-button
                    class="float-right mt-5"
                    size="medium"
                    color="blue"
                    :disabled="!acceptTimeZone || timeZoneUpdateLoading"
                    :loading="timeZoneUpdateLoading"
                    @click="applyTimeZones"
                >
                    {{ t('buttons.apply') }}
                </ma-button>
            </div>
        </template>
    </ma-modal>
</template>


<style lang="less">
    @import '@/actionkit/MaLogsTable.less';
    .ant-table-body {
        &::-webkit-scrollbar {
            @apply sticky;
        }
        &::-webkit-scrollbar-track {
            @apply my-1;
        }
        &::-webkit-scrollbar:vertical {
            @apply ~"w-2";
        }
        &::-webkit-scrollbar-thumb {
        @apply rounded-xl bg-gray-200;
            &:hover {
                 @apply bg-gray-300;
            }
        }
    }
</style>
