import { isProduction, INTERCOM_APP_ID } from '@/common/Config';
import { loadScriptAsync, parseQuery } from '@/common/MaUtils.mjs';
import * as MaCookieStorage from '@/common/MaCookieStorage';
import { utms } from '@/common/MaUtmKeys';


export default function install(Vue, options = {}) {
    const parsed = parseQuery(window.location.search);
    utms.forEach((key) => {
        const parameter = parsed[key];
        if (parameter && parameter !== '') {
            MaCookieStorage.set(key, parameter);
        }
    });
    window.intercomSettings = window.intercomSettings || {};
    window.intercomSettings.app_id = INTERCOM_APP_ID;
    if (!isProduction) {
        console.warn('Intercom is initialized in development mode', INTERCOM_APP_ID);
    }
    //Add user tracking cookies
    utms.forEach((d) => {
        window.intercomSettings[d] = MaCookieStorage.get(d);
    });

    // initialization code
    (function () {
        const window_ = window;
        const intercom = window_.Intercom;
        if (typeof intercom === 'function') {
            intercom('reattach_activator');
            intercom('update', window_.intercomSettings);
        } else {
            const i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            window_.Intercom = i;
            const l = () => loadScriptAsync(`https://widget.intercom.io/widget/${ INTERCOM_APP_ID }`);
            if (window_.attachEvent) {
                window_.attachEvent('onload', l);
            } else {
                window_.addEventListener('load', l, false);
            }
        }
    })();

    Vue.config.globalProperties.$maIntercom = new function MaIntercomPlugin() {
        MaIntercomPlugin.prototype.router = options.router || false;

        MaIntercomPlugin.prototype.trackPageView = (pageName, metadata) => {
            window.Intercom('trackEvent', `viewed_sa_${ pageName }`, metadata);
        };

        MaIntercomPlugin.prototype.trackEvent = (event) => {
            window.Intercom('trackEvent', event);
        };

        MaIntercomPlugin.prototype.boot = (options) => {
            window.Intercom('boot', options);
        };

        MaIntercomPlugin.prototype.show = () => {
            window.Intercom('show');
        };

        MaIntercomPlugin.prototype.hide = () => {
            window.Intercom('hide');
        };

        MaIntercomPlugin.prototype.update = (field) => {
            window.Intercom('update', field);
        };

        //hide intercom button
        MaIntercomPlugin.prototype.hideLaunch = () => {
            window.Intercom('update', {
                hide_default_launcher: true,
            });
        };

        //show intercom button
        MaIntercomPlugin.prototype.showLaunch = () => {
            window.Intercom('update', {
                hide_default_launcher: false,
            });
        };

        //move intercom button to left
        MaIntercomPlugin.prototype.toLeft = () => {
            window.Intercom('update', { alignment: 'left' });
        };

        //move intercom button to right
        MaIntercomPlugin.prototype.toRight = () => {
            window.Intercom('update', { alignment: 'right' });
        };

        MaIntercomPlugin.prototype.trackClickEvent = (eventName = 'unknown', metadata) => {
            window.Intercom('trackEvent', 'clicked_' + eventName, metadata);
        };

        MaIntercomPlugin.prototype.trackSearchEvent = (eventName = 'unknown', metadata) => {
            window.Intercom('trackEvent', 'search_sa_' + eventName, metadata);
        };

        MaIntercomPlugin.prototype.trackCreateEvent = (eventName = 'unknown', metadata) => {
            window.Intercom('trackEvent', 'created_sa_' + eventName, metadata);
        };

        MaIntercomPlugin.prototype.trackGoalEvent = (eventName = 'unknown', expected, actual, metadata) => {
            if (expected === actual) {
                //goal achieved.
                window.Intercom('trackEvent', 'goal_sa_' + eventName, metadata);
            } else {
                window.Intercom('trackEvent', 'created_sa_' + eventName, metadata);
            }
        };

        MaIntercomPlugin.prototype.trackKeywordEvent = (eventName = 'unknown', type) => {
            window.Intercom('trackEvent', 'run_sa_' + eventName, {
                path: Vue.$route && Vue.$route.path || window.location.pathname,
                type,
            });
        };

        // if router is given track page views
        if (this.router) {
            this.router.beforeEach((to, from, next) => {
                if (to.name && to.meta.intercom !== false) {
                    let metadata = null;
                    if (to.params && to.params.tab) {
                        metadata = { tab: to.params.tab };
                    }
                    this.trackPageView(to.name, metadata);
                }
                next();
            });
        }
    };
}
