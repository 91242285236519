<template>
    <ma-maintenance-wall v-if="isMaintenanceModeOn"/>
    <a-config-provider v-else :theme="theme">
        <component :is="layout"/>
    </a-config-provider>
</template>

<script>
    import { sendSentryError, redirectToIntegration } from '@/common/MaUtils.mjs';
    import MaLoadingScreen from '@/components/MaLoadingScreen.vue';
    import AuthenticationLayout from '@/layouts/auth.vue';
    import DefaultLayout from '@/layouts/default.vue';
    import TopbarLayout from '@/layouts/topbar.vue';
    import EventBus from '@/common/EventBus.mjs';
    import MaMaintenanceWall from '@/components/Walls/MaMaintenanceWall.vue';
    import { MAINTENANCE_MODE } from '@/common/Config';
    import { mapActions } from 'pinia';
    import { useAuthStore } from '@/stores/Auth';

    export default {
        name: 'dynamic-app-layout',
        components: {
            MaMaintenanceWall,
            DefaultLayout, // eslint-disable-line vue/no-unused-components
            AuthenticationLayout, // eslint-disable-line vue/no-unused-components
            TopbarLayout, // eslint-disable-line vue/no-unused-components
            MaLoadingScreen, // eslint-disable-line vue/no-unused-components
        },
        data() {
            return {
                validLayouts: ['default', 'authentication', 'topbar'],
                loading: false,
            };
        },
        computed: {
            isMaintenanceModeOn() {
                return MAINTENANCE_MODE === 'ON';
            },
            theme() {
                return {
                    hashed: false, // hash needs to be disabled to prevent antdv4 from overriding styles
                    token: {
                        fontFamily: '"Euclid", sans-serif, -apple-system, BlinkMacSystemFont',
                    },
                };
            },
            layout() {
                // When route is not loaded yet, layout should default
                if (this.loading || !this.$route.name) {
                    return 'ma-loading-screen';
                }
                let _layout = this.$route.meta.layout || 'default';
                if (!this.validLayouts.includes(_layout)) {
                    this.$log.error('Unknown layout name:', _layout);
                    sendSentryError(`Unknown layout name: ${ _layout }`);
                }
                return `${ _layout }-layout`;
            },
        },
        methods: {
            ...mapActions(useAuthStore,['setUserAuth']),
        },
        created() {
            // parse hash part of the url ($route might be empty at this point)
            const hashQuery = window.location.hash.substring(1).split('&') // split by & characters
                .filter(v => (v || '').indexOf('=') > -1) // ignore strings without = in it
                .map(v => v.match(/([^=]+)=(.*)/)) // split from first =
                .reduce((m, v) => (m[v[1]] = v[2], m), {}); // convert to mapping
            if (hashQuery.rft) { // impersonating
                // clear old user data
                this.$segment.reset();
                this.$maLocalStorage.clear();
                this.$maSessionStorage.clear();
                this.$sentry?.clearUser();
                // login new impersonation
                this.$nextTick(() => {
                    this.setUserAuth({ refreshToken: hashQuery.rft });
                    this.$router.replace({ hash: '' });
                });
            }
        },
        mounted() {
            // we cannot show i18n messages on MaRequestPooler, so added here
            EventBus.bus.$on(EventBus.c.INTEGRATIONS_EXPIRED, () => {
                this.$notification.warning({
                    message: this.$t('common.warning'),
                    description: this.$t('common.accountIntegrationsExpired'),
                    key: this.$t('common.accountIntegrationsExpired'),
                    duration: 0,
                    onClick: () => {
                        redirectToIntegration();
                    },
                });
            });
            // we cannot show i18n messages on MaRequestPooler, so added here
            EventBus.bus.$on(EventBus.c.MULTI_LOGIN_DETECTED, (multipleLoginDetected) => {
                const content = multipleLoginDetected
                    ? this.$t('common.multiLoginDetected')
                    : this.$t('common.sessionHasExpired');
                this.$message.warning({
                    key: content,
                    content,
                    duration: multipleLoginDetected ? 15 : 5,
                });
            });
        },
        beforeUnmount() {
            EventBus.bus.$off(EventBus.c.INTEGRATIONS_EXPIRED);
            EventBus.bus.$off(EventBus.c.MULTI_LOGIN_DETECTED);
        },
    };
</script>

<style lang="scss">
    // put them in styles/common.less
</style>
