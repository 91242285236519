<template>
    <a-table
        ref="underlyingIssueTable"
        class="ma-keywords-check-issues"
        :class="borderClasses"
        :locale="{ emptyText: $t('noConflictingIssue')}"
        :show-header="false"
        :data-source="list"
        :row-key="record => Object.values(record).join()"
        :pagination="false"
    >
        <a-table-column data-index="text" ellipsis>
            <template #default="{ record: row }">
                <div class="flex justify-start items-center">
                    <ma-status-icon
                        :class="{ 'invisible' : row.keywordType !== 'KEYWORD' }"
                        class="mr-1"
                        :enabled="row.keywordStatus === 'ACTIVE'"
                    />
                    <div :title="matchTypeTitle(row.matchType)">
                        <span v-if="row.matchType === 'BROAD'">{{ row.text }}</span>
                        <span v-else>[{{ row.text }}]</span>
                    </div>
                </div>
            </template>
        </a-table-column>

        <a-table-column :title="$t('components.keywordAddition.keywordType')">
            <template #default="{ record: row }">
                <a-tag
                    v-if="row.keywordType === 'KEYWORD'"
                    color="blue"
                >
                    {{ $t('columns.keyword') }}
                </a-tag>
                <a-tag
                    v-else
                    color="red"
                >
                    {{ $t('components.keywordAddition.negative') }}
                </a-tag>
            </template>
        </a-table-column>

        <a-table-column
            v-if="type === 'KEYWORD'"
            data-index="bidAmount.amount"
            :title="$t('columns.bidAmount')"
        >
            <template #default="{ record: row }">
                <ma-bid-amount
                    v-model:new-bid="row.newBid"
                    v-model:bid-amount="row.bidAmount"
                    v-model:loading="row.loading"
                    v-model:is-update="row.isUpdate"
                    :row="row"
                    :no-update="!row.actions.includes('UPDATE_BID_AMOUNT')"
                />
            </template>
        </a-table-column>

        <a-table-column :title="$t('common.campaign')" ellipsis>
            <template #default="{ record: row }">
                <ma-status-icon
                    v-if="row.keywordType === 'KEYWORD'"
                    :enabled="row.campaignStatus === 'ENABLED'"
                />
                {{ row.campaignName }}
            </template>
        </a-table-column>

        <a-table-column :title="$t('common.adGroup')" ellipsis>
            <template #default="{ record: row }">
                <div v-if="row.adGroupId">
                    <ma-status-icon
                        v-if="row.keywordType === 'KEYWORD'"
                        :enabled="row.adGroupStatus === 'ENABLED'"
                    />
                    {{ row.adGroupName }}
                </div>
                <div v-else>
                    -
                </div>
            </template>
        </a-table-column>

        <a-table-column
            data-index="issue"
            :title="$t('issue')"
            align="center"
            width="60"
            :custom-cell="cellClassFunc ? (row) => ({ class: cellClassFunc(row) }) : () => {}"
        >
            <template #default="{ record: row }">
                <a-tooltip
                    overlay-class-name="ma-keywords-check-tooltip"
                >
                    <template #title v-if="row.explanation">
                        <span v-html="row.explanation"/>
                    </template>
                    <span>
                        <fa :icon="ICON_MAP[row.issueType] || 'exclamation-triangle'"/>
                    </span>
                </a-tooltip>
            </template>
        </a-table-column>

        <a-table-column :title="$t('buttons.actions')" align="right">
            <template #default="{ record: row }">
                <a-popconfirm
                    :ok-text="$t('buttons.confirm')"
                    :cancel-text="$t('buttons.cancel')"
                    :title="row.statusConfirmTitle"
                    @confirm="toggleKeywordStatus(row)"
                >
                    <a-button
                        v-if="row.actions.includes('PAUSE_KEYWORD')"
                        :title="$t('toggleStatus')"
                        :loading="row.loading"
                    >
                        <fa :icon="row.keywordStatus === 'ACTIVE' ? 'pause' : 'play'"/>
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                    :ok-text="$t('buttons.confirm')"
                    :cancel-text="$t('buttons.cancel')"
                    :title="row.matchTypeConfirmTitle"
                    @confirm="updateMatchType(row)"
                >
                    <a-button
                        v-if="row.actions.includes('UPDATE_MATCH_TYPE')"
                        :title="$t('updateMatchType')"
                        :loading="row.loading"
                    >
                        <fa icon="sync"/>
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                    :ok-text="$t('buttons.confirm')"
                    :cancel-text="$t('buttons.cancel')"
                    ok-type="danger"
                    :title="$t('sureDeleteNegativeKeyword')"
                    @confirm="removeKeyword(row)"
                >
                    <a-button
                        v-if="row.actions.includes('REMOVE_NEGATIVE_KEYWORD')"
                        :loading="row.loading"
                    >
                        <fa icon="trash"/>
                    </a-button>
                </a-popconfirm>
            </template>
        </a-table-column>
    </a-table>
</template>

<script>
    import MaBidAmount from '@/components/MaBidAmount.vue';
    import { parseError } from '@/common/MaUtils.mjs';
    import { deleteMetadata, changeStateOfMetadata } from '@/common/MaRequests/MetadataManagement';
    import { KEYWORD_TYPE, KEYWORD_TYPE_LIST } from '@/common/ManagementUtils.mjs';
    import MetadataUtils from '@/mixins/MetadataUtils.mjs';
    import messages from '@/components/MaKeywordsCheck/MaKeywordsCheck.i18n';
    import { checkError } from '@/common/ErrorMessages';
    import { changeActiveOrgId } from '@/common/FilteringUtils';
    import { mapState } from 'pinia';
    import { useAccountStore } from '@/stores/Account';

    export default {
        name: 'ma-keywords-check-issues',
        components: {
            MaBidAmount,
        },
        mixins: [MetadataUtils],
        props: {
            issues: { type: Array },
            issueType: { type: String },
            type: { type: String, validator: t => KEYWORD_TYPE_LIST.includes(t) },
            cellClassFunc: { type: Function, default: null },
        },
        emits: ['resolved', 'loading-change'],
        data() {
            return {
                loading: {
                    delete: false,
                    pause: false,
                    match: false,
                },
                ICON_MAP: {
                    danger: 'times-circle',
                    warning: 'exclamation-triangle',
                    success: 'check',
                },
            };
        },
        computed: {
            ...mapState(useAccountStore, ['activeOrg']),
            list() {
                return this.issues
                    .map((issue) => {
                        const type = issue.matchType === 'BROAD' ? 'Exact' :  'Broad';
                        const status = issue.keywordStatus ===  'ACTIVE' ? 'Passive' : 'Active';
                        issue.matchTypeConfirmTitle = this.$t('matchTypeConfirmTitle', { type });
                        issue.statusConfirmTitle = this.$t('statusConfirmTitle', { status });
                        return issue;
                    });
            },
            borderClasses() {
                return this.issueType ? [this.issueType] : [];
            },
        },
        methods: {
            matchTypeTitle(matchType) {
                // Match Type: Exact
                return `${ this.$t('columns.matchType') }: ${ this.$t(`components.keywordAddition.${matchType}`) }`;
            },
            toggleKeywordStatus(row) {
                //.warn('pause', row)
                // pause or activate Metadata
                const requestLevel = row.keywordType === 'KEYWORD' ? 'KEYWORD' : 'NEGATIVE';
                const activate = row.keywordStatus === 'ACTIVE' ? false : true;
                const keyword = {
                    keywordId: row.id,
                    adGroupId: row.adGroupId,
                    campaignId: row.campaignId,
                    orgId: row.orgId || this.activeOrg.orgId,
                };
                row.loading = true;
                changeStateOfMetadata([keyword], requestLevel, activate)
                    .then(() => {
                        // todo should we cancel adding keyword
                        row.keywordStatus = row.keywordStatus === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
                        this.$emit('resolved', row, 'keywordStatus');
                    })
                    .catch((e) => {
                        const { errors, displayedMessage } = parseError(e);
                        let errorMessage = displayedMessage;
                        if (checkError(errors, 'ERROR_RECEIVED_FROM_CLIENT')) {
                            errorMessage = this.$t('messages.errors.checkPermission');
                        }

                        this.$message.error({
                            content: this.$t('messages.errors.failedChangeState',  { level: requestLevel, msg: errorMessage }),
                            duration: 7,
                        });
                    })
                    .then(() => {
                        this.$emit('loading-change', false);
                        row.loading = false;
                    });
            },
            updateMatchType(row) { // remove negative keyword and add it with new matchtype
                row.loading = true;
                this.removeKeyword(row)
                    .then(() => {
                        const { matchType, orgId, text, campaignId, adGroupId, adGroupName, campaignName } = row;
                        const matchTypeNew = matchType === 'BROAD' ? 'EXACT' : 'BROAD';
                        changeActiveOrgId(orgId);
                        let keyword = {
                            text,
                            campaignId,
                            orgId,
                            matchType: matchTypeNew,
                        };

                        let request = new Promise();
                        if (adGroupId) {
                            keyword.adGroupId = adGroupId;
                            this.saveKeywordsRequest([keyword], orgId, campaignId, adGroupId, adGroupName, KEYWORD_TYPE.NEGATIVE);
                        } else {
                            request = this.saveNegativeKeywordsOnCampaign([keyword], orgId, campaignId, campaignName, KEYWORD_TYPE.NEGATIVE);
                        }
                        request
                            .then(() => {
                                row.matchType = matchTypeNew;
                                this.$emit('resolved', row, 'matchType');
                            })
                            .catch((e) => {
                                this.$log.error('Failed to remove/update match type, errors:', e);
                            })
                            .then(() => {
                                row.loading = false;
                            });
                    })
                    .catch((e) => {
                        row.loading = false;
                        this.$log.error('Failed to remove/update match type, error:', e);
                    });
            },
            removeKeyword(row) {
                return new Promise((resolve, reject) => {
                    const keyword = {
                        keywordId: row.id,
                        adGroupId: row.adGroupId,
                        campaignId: row.campaignId,
                        orgId: row.orgId || this.activeOrg.orgId,
                    };

                    this.loading.delete = true;
                    deleteMetadata([keyword], 'NEGATIVE')
                        .then((data) => {
                            this.$emit('resolved', row);
                            resolve(data);
                        })
                        .catch((err) => {
                            const { displayedMessage } = parseError(err);
                            this.$message.error(this.$t('messages.errors.unexpectedError', { msg: displayedMessage }));
                            this.$log.error(err);
                            reject(err);
                        })
                        .then(() => {
                            this.loading.delete = false;
                        });

                });
            },
        },
        i18n: { messages },
    };
</script>
