import { globalProperties as app } from '@/main';
import { isProduction } from '@/common/Config';
import { sendSentryError } from '@/common/MaUtils.mjs';
import { useAuthStore } from '@/stores/Auth';

export function initializeBeamer(user) {
    const authStore = useAuthStore();
    try {
        let skipMsg = null;
        if (!user) {
            skipMsg = 'missing user';
        } else if (!window?.Beamer) {
            skipMsg = 'beamer not initialized';
        } else if (!isProduction) {
            skipMsg = 'not in prod';
        }
        if (skipMsg) {
            app.$log.debug('MaBeamer: skipped ', skipMsg);
            return;
        }
        const imp = authStore.maImp;
        const prefix = imp ? `MA-admin:${ imp }:` : '';
        // if our admins logged in we have to give a different user id so it
        // won't mess up user info on FS
        const userId = `${ prefix }${ user.userId }`;
        window.beamer_config = Object.assign({}, window.beamer_config, {
            user_firstname: user.contactInfo.name || '-',
            user_lastname: user.contactInfo.surname || '-',
            user_email: `${ prefix }${ user.username }`,
            user_id: userId,
        });
        window.Beamer.init(); // call init every time because page DOM might be updated
    } catch (e) {
        console.error('Failed to initialize beamer', e);
        sendSentryError(e);
    }
}
