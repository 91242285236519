<template>
    <ma-dropdown
        :trigger="['click']"
    >
        <div class="flex items-center justify-between border border-gray-200 px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-50 gap-[6px]">
            <div class="flex items-center gap-2">
                <ma-icon
                    round
                    size="xs"
                    :name="'flag-' + flagCode[$i18n.locale]"
                />
                <ma-typography type="body-4" weight="medium">
                    {{ $t(($i18n.locale).toUpperCase()) }}
                </ma-typography>
            </div>
            <ma-icon
                size="xs"
                name="chevron-down"
                class="justify-end"
            />
        </div>
        <template #overlay>
            <ma-menu @click="({ key }) => selectLocale(key)">
                <ma-menu-item
                    v-for="l in locales"
                    :key="l.locale"
                >
                    <div class="flex items-center gap-1">
                        <ma-icon
                            round
                            size="xs"
                            :name="'flag-'+ l.flag"
                        />
                        <ma-typography type="body-4" weight="medium">
                            {{ l.text }}
                        </ma-typography>
                    </div>
                </ma-menu-item>
            </ma-menu>
        </template>
    </ma-dropdown>
</template>

<script>
    import dayjs from '@/plugins/dayjs.mjs';
    import { MaDropdown, MaIcon, MaMenu, MaMenuItem, MaTypography } from '@mobileaction/action-kit';
    import messages from '@/components/Topbar/MaLocaleSelector.i18n';

    export default {
        name: 'ma-locale-selector',
        components: { MaMenuItem, MaMenu, MaTypography, MaIcon, MaDropdown },
        props: {
            placement: { type: String, default: 'bottomLeft' },
            isTopbar: { type: Boolean, default: false },
        },
        data() {
            return {
                // active  locales
                list: {
                    en: () => import('dayjs/locale/en'),
                    ja: () => import('dayjs/locale/ja'),
                    ko: () => import('dayjs/locale/ko'),
                    cn: () => import('dayjs/locale/zh-cn'),
                },
                flagCode: {
                    en: 'us',
                    ja: 'jp',
                    ko: 'kr',
                    cn: 'cn',
                },
                //  translation texts
                translationTexts: {
                    en: 'english',
                    ja: 'japanese',
                    ko: 'korean',
                    cn: 'chinese',
                },
            };
        },
        computed: {
            locales() {
                return Object.keys(this.list).map(locale => ({
                    locale,
                    flag: this.flagCode[locale],
                    text: this.$t(this.translationTexts[locale]),
                }));
            },
        },
        methods: {
            selectLocale(locale, onMount = false) {
                this.$maLocalStorage.locale = locale;
                this.$i18n.locale = locale;
                this.list[locale]();
                dayjs.updateLocale(locale, { weekStart: 1 });
                if (!onMount) {
                    this.$segment.setUserProperty('locale', locale);
                    window.location.reload(); // some translations are embedded deeply so we unfortunately had to do this
                }
            },
        },
        mounted() {
            this.selectLocale(this.$maLocalStorage.locale, true);
        },
        i18n: {
            messages,
        },
    };
</script>
<style lang="less" scoped>
    .ant-menu-vertical {
        @apply border-r-0;
    }
</style>
