import { utmMap } from '@/common/MaUtmKeys';
import { globalProperties as app } from '@/main';
import events from '@/plugins/segment/events';
import { useAuthStore } from '@/stores/Auth';

export const AUTH_EVENT = {
    SIGN_UP: 'SA-Signup',
    LOGIN: 'SA-Login',
};

export default function SegmentWrapper(segmentAnalytics) {
    this.identify = (user) => {
        const authStore = useAuthStore();
        const { jwtData } = authStore;
        const { maImp } = authStore;
        const { name, surname, userId } = user;
        const username = user.username || jwtData.username; //user email

        //123:456 where 123 is impersonating user's id and 456 is the actual (impersonated) user's id
        const userIdForImpersonation = `${maImp}:${userId}`;

        const { company, jobFunction: jobTitle } = user.contactInfo || {};

        const userIdForSegment = maImp ? userIdForImpersonation : userId;
        const userEmailForSegment = maImp ? `${maImp}-${username}` : username;

        //Identify current user on Segment and add user details
        const userDetails = {
            userId: userIdForSegment,
            email: userEmailForSegment,
            imp: maImp,
            accountId: user.accountId,
            pricingPlan: user.plan,
            name: (name && surname)
                ? `${name} ${surname}`
                : '',
            platform: jwtData?.platform,
            membershipType: jwtData?.membershipType,
            registrationDate: user.createdAt,
            lastLogin: new Date(jwtData.iat * 1000).toISOString(),
            locale: app?.$maLocalStorage?.locale || 'en',
            company,
            jobTitle,
            ...utmMap,
        };

        segmentAnalytics.identify(userIdForSegment, userDetails);
    };

    this.track = (eventName, eventProperties = {}, eventOptions = {}) => {
        segmentAnalytics.track(eventName, eventProperties, eventOptions);
    };

    //Update a property on the current user's profile.
    //E.g. when a user changes selected locale.
    this.setUserProperty = (key, value) => {
        const authStore = useAuthStore();
        const { maImp } = authStore;
        const { user: { userId } } = authStore;

        //123:456 where 123 is impersonating user's id and 456 is the actual (impersonated) user's id
        const userIdForImpersonation = `${maImp}:${userId}`;
        const userIdForSegment = maImp ? userIdForImpersonation : userId;

        //Segment recommends using identify to update user properties
        //See here: https://segment.com/docs/connections/spec/identify/
        segmentAnalytics.identify(userIdForSegment, {
            [key]: value,
        });
    };

    this.s = { // sample property values
        onboarding: 'SA_ONBOARDING',
        experiment: 'EXPERIMENT',
        quickCampaign: 'QUICK_CAMPAIGN_SETUP',
        integration: 'INTEGRATION',
        keywordAdvisor: 'KEYWORD_ADVISOR',
        quickAutomation: 'QUICK_AUTOMATION',
        budgetAllocation: 'BUDGET_ALLOCATION',
        spotlight: 'SPOTLIGHT',
        sa: 'SA',
    };
    this.e = events;

    this.trackAuth = (isNewUser, userId, type, isExperiment) => {
        if (isNewUser) {
            this.track([AUTH_EVENT.SIGN_UP, {
                UserId: userId,
                SignupProduct: this.s.sa,
                SignupBy: type?.toUpperCase(),
                Place: (isExperiment ? this.s.experiment : null),
            }]);
        } else {
            this.track([AUTH_EVENT.LOGIN, {
                UserId: userId,
                LoginProduct: this.s.sa,
                LoginBy: type?.toUpperCase(),
                Place: (isExperiment ? this.s.experiment : null),
            }]);
        }
    };

    this.reset = () => {
        return segmentAnalytics.reset();
    };

    this.trackLink = (element, eventName, eventProperties = {}) => {
        segmentAnalytics.trackLink(element, eventName, eventProperties);
    };

    this.requiredPages = [
        'accounts',
        'campaigns',
        'ad-groups',
        'keywords',
    ];
    this.isPageRequired = (pageRoute) => {
        return this.requiredPages.some(page => pageRoute.includes(page));
    };
}
