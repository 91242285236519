{
    "addedKeywords": {
        "keyword": "Added Keywords",
        "negative-keyword": "Added Negative Keywords"
    },
    "bidAmountMustBeNumber": "Bid Amount must be number. Decimal separator is dot(00.0)",
    "bidAmountRequired": "Bid Amount is required and must greater than zero(0)",
    "buttons": {
        "changeAll": "Change all",
        "changeSelected": "Change selected",
        "removeAll": "Remove all",
        "removeSelected": "Remove selected"
    },
    "createKeywordGroup": "Create a keyword group to see it here",
    "createLabel": "Create Label",
    "editKeywords": "Edit {count} keywords",
    "enterKeywords": "Enter or paste {type} keywords. You can separate each keyword by commas or enter one per line.",
    "goToKeywordTracking": "Go to Keyword Tracking",
    "keywordBid": "Keyword bid",
    "keywordGroup": "Keyword Group",
    "limitForAdGroup": "Limit for Ad Group keywords is 5000, keywords exceeding the limit are not added to the list",
    "noNegativeKeywords": "No negative keywords added yet",
    "none": "None",
    "pleaseInputCorrectBidAmount": "Please input correct bid amount",
    "selectAll": "Select All",
    "specialCharacters": "Special characters ({chars}) are not allowed. Remove these characters and try again.",
    "youCanEitherAddKeywords": "You can either add keywords manually here or choose from left side",
    "addLabelsToSelected": "Apply labels to selected"
}
