{
    "addKeywordsHeading": "Add Keywords",
    "addNegativeKeywordsHeading": "Add Negative Keywords",
    "checkIssuesHeading": "Check Issues",
    "addKeywords": "Add keywords",
    "addNegativeKeywords": "Add negative keywords",
    "addLabel": "Add label",
    "addedKeywords": "Added keywords",
    "addedNegativeKeywords": "Added negative keywords",
    "addedKeywordsTooltip": "Keywords will be added with the default max bid set for the ad group. If you want to customize the bids, you can enable the ‘Set Custom Bids’ option.",
    "editMatchType": "Edit match type",
    "selectTarget": "Select target",
    "selectTargetExplanation": "Choose your campaign(s) or ad group(s) that the negative keywords will be applied to.",
    "selectTargetExplanationNegative": "Choose your ad group(s) that the keywords will be applied to.",
    "addKeywordsExplanation": "Select keywords from organic, paid, and ASO lists, and add to the chosen ad groups.",
    "addNegativeKeywordsExplanation": "Add negative keywords to exclude them from your campaigns or ad groups.",
    "applyKeywordsTo": "Apply keywords to",
    "applyNegativeKeywordsTo": "Apply negative keywords to",
    "organic": "Organic",
    "paid": "Paid",
    "asoTracked": "ASO Tracked",
    "suggestions": "Suggestions",
    "filterByKeyword": "Filter by keyword",
    "addAllKeywords": "Add all keywords on page",
    "added": "Added",
    "createKeywordGroup": "Create a keyword group to see it here",
    "goToKeywordTracking": "Go to Keyword Tracking",
    "noKeywordGroups": "There are no keyword groups",
    "keywordGroups": "Keyword groups",
    "keywordsWillBeAddedTo": "Keywords will be added to",
    "negativeKeywordsWillBeAddedTo": "Negative keywords will be added to",
    "sharedWithMe": "Shared with me",
    "noTrackedKeywords": "No keyword is tracked for this app and country.",
    "trackKeywordsForSelected": "To view your tracked keywords list, track keywords for the selected app & country.",
    "enterOrPasteKeywords": "Enter or paste keywords. You can separate each keyword by common or enter one per line.",
    "specialCharacters": "Special characters ({chars}) are not allowed. Remove these characters and try again.",
    "matchTypes": {
        "EXACT": "Exact match",
        "BROAD": "Broad match"
    },
    "keywordTypes": {
        "KEYWORD": "Keyword",
        "NEGATIVE": "Negative keyword"
    },
    "selected": "Selected",
    "useCustomBidTooltip": "Enable this option to customize bids for each keyword.",
    "editBidAmount": "Edit bid amount",
    "setCustomBidValues": "Set custom bid values",
    "setCustomBidValuesTooltip": "Enable the ‘Set Custom Bids’ option to customize the bids.",
    "successfulKeywordsMessage": "{n} of {total} keywords will be successfully added to selected ad groups.",
    "warningKeywordsMessage": "{n} of {total} keywords have some conflicting issues. By confirming, you can still go ahead with these keywords.",
    "errorKeywordsMessage": "{n} of {total} keywords have duplicate errors. Keywords that already exist with the same match type can not be added.",
    "warnings": "Warnings",
    "warningsSectionTitle": "{n} keywords are expected to be added even though there are conflicting issues.",
    "errors": "Errors",
    "errorsSectionTitle": "{n} keywords won't be added as they exist in the ad group with the same match type.",
    "reasons": "Reasons",
    "details": "Details",
    "keywordType": "Keyword type",
    "successNotificationText": "Done! Added {n} keywords successfully.",
    "failedNotificationText": "{n} keywords have errors and not added.",
    "operationalError": "Operational Error",
    "operationalErrorMessage": "We encountered an operational error and are unable to complete your request. You can click 'Retry' to attempt again. If the issue persists, please close this window and try again later.",
    "conflictingKeywordsWarning": "Are you sure you want to continue with conflicting keywords?",
    "conflictingKeywordsDescription": "This will process the conflicting keywords (total warning keyword count), and your campaigns may not work properly.",
    "addOnlyKeywordsWithoutWarnings": "Add only keywords without warnings"
}
