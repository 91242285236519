<template>
    <a-card class="ma-keyword-list">
        <template #title>
            <div class="flex space-x-1 items-center">
                <ma-typography type="body-4" weight="semibold" class="text-gray-900">
                    {{ $t(`addedKeywords.${keywordType}`) }}
                </ma-typography>

                <ma-tooltip
                    overlay-class-name="ma-added-kw-tooltip"
                    type="primary"
                >
                    <template #title>
                        {{ $t('enterKeywords', { type: isKeyword ? '' : 'negative ' }) }}
                        <a
                            href="https://help.mobileaction.co/en/articles/6545308-adding-keywords-under-campaigns"
                            target="_blank"
                        >
                            {{ $t('buttons.learnMore') }}
                        </a>
                    </template>
                    <ma-icon name="info-circle-2" size="xs"/>
                </ma-tooltip>
            </div>
        </template>

        <div
            v-show="textInput"
            class="mb-6"
        >
            <ma-input
                ref="textarea"
                v-model:value="keywordsInput"
                type="textarea"
                :placeholder="$t('enterKeywords', {type : isKeyword?'':'negative ' })"
                :auto-size="{ minRows: 8, maxRows: 30 }"
            />
            <div class="flex justify-end mt-2 gap-2">
                <ma-button
                    color="blue"
                    variant="ghost"
                    size="medium"
                    @click="textInput=false"
                >
                    {{ $t('buttons.cancel') }}
                </ma-button>
                <ma-button
                    :disabled="!hasInput"
                    size="medium"
                    @click="saveKeywords"
                >
                    {{ $t('buttons.save') }}
                </ma-button>
            </div>
        </div>

        <ma-input
            v-show="!textInput"
            :placeholder="$t('enterKeywords', {type : isKeyword?'':'negative ' })"
            read-only
            @focus="openTextInput"
        />
        <div>
            <div v-if="list.length" class="ma-keyword-list-actions">
                <div class="text-gray-500 flex text-sm items-center">
                    <ma-checkbox
                        :checked="keywordsAllSelected"
                        class="[&>.antd-checkbox]:top-0"
                        @change="handleSelectAll"
                    />
                    <div class="ml-3">
                        {{ $t('selectAll') }}
                    </div>
                </div>
                <div v-if="keywordsOneSelected" class="flex items-center gap-2">
                    <ma-dropdown trigger="click" overlay-class-name="p-2 w-[180px]">
                        <ma-tooltip :title="changeButtonText">
                            <ma-icon-button
                                type="secondary"
                                variant="withBg"
                                icon="edit-2"
                                size="xs"
                            />
                        </ma-tooltip>
                        <template #overlay>
                            <ma-menu>
                                <ma-menu-item
                                    :key="DIALOG_TYPES.MATCH_TYPE"
                                    class="!p-2 !h-10 !flex !items-center"
                                    @click="changeSelected(DIALOG_TYPES.MATCH_TYPE)"
                                >
                                    {{ $t('columns.matchType') }}
                                </ma-menu-item>
                                <ma-menu-item
                                    v-if="isKeyword"
                                    :key="DIALOG_TYPES.BID"
                                    class="!p-2 !h-10 !flex !items-center"
                                    @click="changeSelected(DIALOG_TYPES.BID)"
                                >
                                    {{ $t('keywordBid') }}
                                </ma-menu-item>
                            </ma-menu>
                        </template>
                    </ma-dropdown>
                    <ma-tooltip :title="removeButtonText">
                        <ma-icon-button
                            type="secondary"
                            variant="withBg"
                            icon="trash"
                            size="xs"
                            @click="removeSelected"
                        />
                    </ma-tooltip>
                    <label-selector
                        v-if="isKeyword"
                        @labels-selected="applyLabelsToSelected"
                    >
                        <ma-tooltip :title="$t('addLabelsToSelected')">
                            <ma-icon-button
                                type="secondary"
                                variant="withBg"
                                icon="tag"
                                size="xs"
                            />
                        </ma-tooltip>
                    </label-selector>
                </div>
                <div v-else>
                    <ma-input
                        v-model:value="searchText"
                        placeholder="Search keyword"
                        size="small"
                        :allow-clear="true"
                    />
                </div>
            </div>
            <p v-if="keywordAddingError" class="text-red-500">
                <small>{{ keywordAddingError }}</small>
            </p>
            <div class="mt-2 p-2 bg-gray-100 rounded-md flex items-center">
                <div class="flex items-center">
                    <ma-checkbox
                        :checked="keywordsAllSelected"
                        class="[&>.antd-checkbox]:top-0"
                        @change="handleSelectAll"
                    />
                </div>
                <ma-typography
                    type="caption-1"
                    weight="medium"
                    class="ml-3 w-36"
                >
                    {{ $t('columns.keyword') }}
                </ma-typography>
                <div class="flex flex-grow justify-end items-center space-x-1">
                    <ma-typography
                        v-if="isKeyword"
                        type="caption-1"
                        weight="medium"
                        class="ml-3 w-28 flex items-center justify-center"
                    >
                        {{ $t('columns.label') }}
                    </ma-typography>
                    <ma-typography
                        type="caption-1"
                        weight="medium"
                        class="ml-3 w-28 flex items-center justify-center"
                    >
                        {{ $t('columns.matchType') }}
                    </ma-typography>
                    <ma-typography
                        v-if="isKeyword"
                        type="caption-1"
                        weight="medium"
                        class="ml-3 w-28 flex items-center justify-center"
                    >
                        {{ $t('columns.bidAmount') }}
                    </ma-typography>
                    <!--Actions-->
                    <div class="w-10"/>
                </div>
            </div>
            <div
                v-if="list.length"
                :class="list.length > 10 ? ['ma-data-paginated'] : []"
                class="ma-keyword-adding-list"
            >
                <div
                    v-for="(kw, ind) in filteredList"
                    :key="kw.text +'-'+ kw.matchType +'-'+ ind"
                    class="ma-keyword-list-row"
                >
                    <div class="flex items-center ma-kw-match-type-input-small">
                        <div class="flex items-center">
                            <ma-checkbox
                                v-model:checked="kw.selected"
                                class="[&>.antd-checkbox]:top-0"
                            />
                        </div>
                        <ma-tooltip
                            :title="kw.text"
                            type="primary"
                            class="ml-3 max-w-36 truncate"
                        >
                            <ma-typography type="caption-1" weight="medium" class="text-gray-900">
                                {{ kw.text }}
                            </ma-typography>
                        </ma-tooltip>
                    </div>
                    <div class="flex flex-grow justify-end items-center space-x-1">
                        <div
                            v-if="isKeyword"
                            class="flex min-w-28 justify-center gap-0.5"
                        >
                            <ma-keyword-label-column
                                v-model:label-ids="kw.labels"
                            />
                        </div>
                        <ma-select
                            v-model:value="kw.matchType"
                            size="small"
                            :dropdown-match-select-width="false"
                            dropdown-class-name="z-[1000]"
                            class="[&_.antd-select-selection-item]:!text-[12px]"
                        >
                            <ma-select-option value="BROAD">
                                {{ $t('columns.broadMatch') }}
                            </ma-select-option>
                            <a-select-option value="EXACT">
                                {{ $t('columns.exactMatch') }}
                            </a-select-option>
                        </ma-select>
                        <div v-if="isKeyword" class="flex items-center space-x-1 w-28">
                            <ma-input
                                v-if="!useDefaultCpc"
                                v-model:value="kw.bidAmount.amount"
                                :placeholder="$t('columns.bidAmount')"
                                :min="0"
                                type="number"
                                size="small"
                                :addon-before="currencySymbol"
                                @keyup="mx_validateNumber(kw.bidAmount.amount)"
                            />
                            <ma-input
                                v-else
                                :value="defaultBid"
                                type="number"
                                size="small"
                                :addon-before="currencySymbol"
                                disabled
                            />
                            <ma-tooltip
                                :class="{ invisible: !mx_validateNumber(kw.bidAmount.amount) }"
                                overlay-class-name="ma-tooltip-kw-list"
                            >
                                <template #title>
                                    <span v-html="mx_validateNumber(kw.bidAmount.amount)"/>
                                </template>
                                <fa icon="exclamation-triangle"/>
                            </ma-tooltip>
                        </div>
                        <div
                            v-if="!isKeyword"
                            class="flex"
                            @click="removeKeyword(kw)"
                        >
                            <ma-icon name="trash" class="text-gray-600 cursor-pointer hover:text-red-500" size="sm"/>
                        </div>
                        <ma-popover
                            v-else
                            trigger="click"
                            overlay-class-name="[&_.antd-popover-inner]:!p-2"
                            :get-popup-container="trigger => trigger.parentElement"
                            auto-adjust-overflow
                        >
                            <ma-button
                                icon="ellipsis-vertical"
                                variant="ghost"
                                size="medium"
                            />
                            <template #overlay>
                                <ma-menu>
                                    <ma-menu-item @click="removeKeyword(kw)">
                                        <div class="flex gap-2">
                                            <ma-icon name="trash" class="text-gray-600" size="sm"/>
                                            <ma-typography type="body-4" weight="medium" class="text-gray-900">
                                                {{ $t('buttons.delete') }}
                                            </ma-typography>
                                        </div>
                                    </ma-menu-item>
                                </ma-menu>
                            </template>
                        </ma-popover>
                    </div>
                </div>
            </div>
            <div v-if="list.length" class="flex justify-end py-2">
                <a-pagination
                    v-model:current="paginationOptions.currentPage"
                    v-model:page-size="paginationOptions.pageSize"
                    :total="paginationOptions.total"
                    :page-size-options="paginationOptions.pageSizes"
                    :show-total="total => $t('common.totalCount', { total })"
                    :show-size-changer="paginated"
                    :hide-on-single-page="true"
                    size="small"
                />
            </div>
            <div v-if="!list.length" class="ma-no-keywords-message">
                <span v-if="!isKeyword">{{ $t('noNegativeKeywords') }}</span>
                <span v-else>{{ emptyText !== '' ? emptyText : $t('youCanEitherAddKeywords') }}</span>
            </div>
        </div>

        <ma-create-label-popup
            :visible="showLabelCreation && createLabelVisible"
            :existing-labels="labelsStore.labelsList"
            @close="createLabelVisible = false"
            @created="createdLabelId => handleLabelCreated(createdLabelId, selectedKeyword)"
        />

        <a-modal
            :open="dialog.visible"
            :title="dialogTitle"
            append-to-body
            class="ma-kw-list-modal"
            :z-index="2500"
            @cancel="dialog.visible = false"
        >
            <a-form
                ref="changeForm"
                :model="dialog"
                layout="horizontal"
            >
                <a-form-item
                    v-if="dialog.dialogType === DIALOG_TYPES.MATCH_TYPE"
                    :label="$t('columns.matchType')"
                    name="matchType"
                >
                    <a-select
                        v-model:value="dialog.matchType"
                        :dropdown-match-select-width="false"
                        popup-class-name="ma-kw-match-type-dropdown"
                    >
                        <a-select-option key="BROAD">
                            {{ $t('columns.broadMatch') }}
                        </a-select-option>
                        <a-select-option key="EXACT">
                            {{ $t('columns.exactMatch') }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item
                    v-if="isKeyword && dialog.dialogType === DIALOG_TYPES.BID"
                    :label="$t('keywordBid')"
                    :rules="{ required: true, validator }"
                    name="bidAmount"
                >
                    <a-input
                        v-model:value="dialog.bidAmount"
                        type="number"
                        :min="0"
                        :prefix="currencySymbol"
                        :placeholder="$t('columns.bidAmount')"
                    />
                </a-form-item>
            </a-form>
            <template #footer>
                <a-button type="link" @click="dialog.visible = false">
                    {{ $t('buttons.cancel') }}
                </a-button>
                <a-button type="primary" @click="submit">
                    {{ $t('buttons.confirm') }}
                </a-button>
            </template>
        </a-modal>
    </a-card>
</template>

<script>
    import { trimAndCleanKeyword } from '@/common/MaUtils.mjs';
    import NumberUtils from '@/mixins/NumberUtils.mjs';
    import Formatter from '@/mixins/Formatter.mjs';
    import MaCreateLabelPopup from '@/components/MaCreateLabelPopup.vue';
    import {
        MaButton,
        MaCheckbox,
        MaDropdown, MaIcon,
        MaIconButton, MaInput,
        MaMenu,
        MaMenuItem, MaPopover,
        MaSelect,
        MaSelectOption,
        MaTooltip, MaTypography,
    } from '@mobileaction/action-kit';
    import { useLabelsStore } from '@/stores/Labels';
    import LabelSelector from '@/pages/Automations/Components/LabelSelector/LabelSelector.vue';
    import MaKeywordLabelColumn from '@/components/KeywordAddModal/MaKeywordLabelColumn.vue';
    import messages from '@/components/KeywordAddModal/KeywordList.i18n';

    const DIALOG_TYPES = {
        MATCH_TYPE: 'MATCH_TYPE',
        BID: 'BID',
    };

    export default {
        name: 'ma-keyword-list',
        components: {
            MaKeywordLabelColumn,
            MaTypography,
            MaPopover,
            MaButton,
            MaIcon,
            MaInput,
            MaSelectOption,
            MaSelect,
            MaCheckbox,
            MaIconButton,
            LabelSelector,
            MaMenuItem,
            MaMenu,
            MaDropdown,
            MaCreateLabelPopup,
            MaTooltip,
        },
        mixins: [NumberUtils, Formatter],
        props: {
            showLabelCreation: { type: Boolean, default: false },
            list: { type: [Array], required: true },
            defaultBid: { type: [Number, String] },
            currency: { type: String, required: true },
            currencySymbol: { type: String, default: '' },
            keywordType: { type: String, required: true },
            campaignId: { type: [Number, String] },
            adGroupId: { type: [Number, String] },
            emptyText: { type: String, default: '' },
            useDefaultCpc: { type: Boolean, default: false },
        },
        emits: ['update:list'],
        data() {
            return {
                invalidChars: ['[', '{', '}', ']', '"', '\\', ';'],
                keywordAddingError: null,
                keywordsInput: null,
                textInput: false,
                keywordLimit: 5000,
                createLabelVisible: false,
                selectedKeyword: null,
                dialog: {
                    visible: false,
                    matchType: 'EXACT',
                    bidAmount: null,
                    dialogType: DIALOG_TYPES.MATCH_TYPE,
                },
                DIALOG_TYPES,
                paginationOptions: {
                    currentPage: 1,
                    pageSizes: ['50', '100', '250', '500'],
                    pageSize: 100,
                    total: 0,
                },
                allSelected: false,
                searchText: '',
                labelsStore: useLabelsStore(),
            };
        },
        computed: {
            hasInput() {
                return this.keywordsInput?.length;
            },
            paginated() {
                return this.list.length > this.paginationOptions.pageSizes[0];
            },
            layout() {
                return !this.paginated ? 'sizes, prev, pager, next, total' : 'total';
            },
            dialogTitle() {
                return this.$t('editKeywords', { count: this.list.filter(k => k.selected).length });
            },
            isKeyword() {
                return this.keywordType === 'keyword';
            },
            keywordTexts() {
                return this.list.map(k => `${k.text.toLowerCase()}x${k.matchType}`);
            },
            keywordsOneSelected() {
                return this.list.filter(k => k.selected).length > 0;
            },
            keywordsAllSelected() {
                return this.list.filter(k => k.selected).length === this.list.length;
            },
            filteredList() {
                let _list = this.list;

                if (this.searchText) {
                    const isIncludes = (text, search) => text?.toLowerCase().trim()?.includes(search?.toLowerCase().trim());
                    _list = _list.filter(d => isIncludes(d.text, this.searchText));
                }

                if (!this.paginated) {
                    return _list;
                }

                const { currentPage, pageSize } = this.paginationOptions;
                const offset = (currentPage - 1) * pageSize;
                const limit = currentPage * pageSize;
                return _list.slice(offset, limit);
            },
            changeButtonText() {
                if (this.keywordsAllSelected) {
                    return this.$t('buttons.changeAll');
                }
                return this.$t('buttons.changeSelected');
            },
            removeButtonText() {
                if (this.keywordsAllSelected) {
                    return this.$t('buttons.removeAll');
                }
                return this.$t('buttons.removeSelected');
            },
        },
        methods: {
            applyLabelsToSelected(labels) {
                const labelIds = labels.map(l => l.id);
                const updatedList = this.list.map((kw) =>{
                    if (!kw.selected) {
                        return kw;
                    }
                    const labels = kw.labels || [];
                    labelIds.forEach((id) => {
                        if (!labels.includes(id)) {
                            labels.push(id);
                        }
                    });
                    return {
                        ...kw,
                        labels,
                    };
                });
                this.$emit('update:list', updatedList);
            },
            openCreateLabelForKeyword(kw) {
                this.selectedKeyword = kw;
                this.createLabelVisible = true;
            },
            handleLabelCreated(createdId) {
                const selectedKwToLabel = this.filteredList.find(k => k.id === this.selectedKeyword.id);
                if (!('labels' in selectedKwToLabel)) {
                    selectedKwToLabel.labels = [];
                }
                selectedKwToLabel.labels.push(createdId);
                this.selectedKeyword = null;
            },
            validator(rule, value) {
                if (!value || value <= 0) {
                    return Promise.reject(this.$t('bidAmountRequired'));
                } else {
                    return Promise.resolve();
                }
            },
            selectAll() {
                let _list = this.list
                    .map((k) => {
                        k.selected = true;
                        return k;
                    });
                this.$emit('update:list', _list);
            },
            selectNone() {
                let _list = this.list
                    .map((k) => {
                        k.selected = false;
                        return k;
                    });
                this.$emit('update:list', _list);
            },
            handleSelectAll({ target: { checked } }) {
                if (checked) {
                    this.selectAll();
                } else {
                    this.selectNone();
                }
            },
            close() {
                this.$nextTick(() => {
                    this.selectNone();
                });
            },
            changeSelected(key) {
                this.dialog.dialogType = key;
                this.dialog.visible = true;
                this.dialog.matchType = 'EXACT';
                this.dialog.bidAmount = this.defaultBid;
            },
            removeSelected() {
                this.$emit('update:list', this.list.filter(k => !k.selected));
                this.close();
            },
            async submit() {
                try {
                    await this.$refs.changeForm?.validate();
                } catch (e) {
                    return;
                }
                this.applyChange();
            },
            applyChange() {
                let _list = this.list;
                _list.forEach((k) => {
                    if (k.selected) {
                        if (this.dialog.dialogType === DIALOG_TYPES.MATCH_TYPE) {
                            k.matchType = this.dialog.matchType;
                        }
                        if (this.dialog.dialogType === DIALOG_TYPES.BID && k.bidAmount) {
                            k.bidAmount.amount = this.dialog.bidAmount;
                        }
                    }
                });
                this.dialog.visible = false;
                this.$emit('update:list', _list);
                this.close();
            },
            containsInvalidChars(str) {
                let invalid = 0;
                this.invalidChars.forEach((char) => {
                    if (str.indexOf(char) > -1) {
                        invalid++;
                    }
                });
                return invalid > 0;
            },
            removeIfExceedsLimit(list) {
                const totalCount = this.list.length + list.length;
                if (totalCount > this.keywordLimit) {
                    const remainingCount = this.keywordLimit - this.list.length;
                    this.$message.warning({
                        content: this.$t('limitForAdGroup'),
                        duration: 7,
                    });
                    return list.slice(0, remainingCount).join();
                }
                return [...list];
            },
            openTextInput() {
                this.keywordsInput = '';
                this.textInput = true;
                this.$nextTick(() => this.$refs.textarea?.focus());
            },
            saveKeywords() {
                this.textInput = false;
                this.addKeywords();
            },
            addKeywords() {
                let input = this.keywordsInput;
                let kwList = this.keywordsInput2Array(input);

                this.keywordAddingError = null;
                if (this.containsInvalidChars(input)) {
                    this.keywordAddingError = this.$t('specialCharacters', { chars: this.invalidChars.join() });
                    return;
                }

                let _list = this.list;
                // remove if keyword count exceeds limit
                kwList = this.removeIfExceedsLimit(kwList);
                kwList
                    ?.map(k => trimAndCleanKeyword(k))
                    .forEach((k) => {
                        // this prevents duplicate entries
                        let added = this.keywordTexts.includes(`${k.toLowerCase()}xBROAD`);
                        if (k && !added && k.length) {
                            const kw = {
                                text: k,
                                selected: false,
                                adGroupId: this.adGroupId,
                                campaignId: this.campaignId,
                            };
                            if (this.keywordType === 'keyword') {
                                kw.matchType = 'EXACT';
                                kw.bidAmount = {
                                    amount: this.defaultBid,
                                    currency: this.currency,
                                };
                                // this.adGroup.metadata.defaultCpcBid);
                            } else if (this.keywordType === 'negative-keyword') {
                                kw.matchType = 'EXACT';
                            } else {
                                this.$log.warn('Unknown keyword type tried to be added: ', this.keywordType);
                                return;
                            }
                            _list.splice(0, 0, kw);
                        }
                    });
                this.keywordsInput = '';
                this.$emit('update:list', _list);
            },
            keywordsInput2Array(input) {
                return input
                    .replace(/\n/g, ',')
                    .split(',')
                    .slice();
            },
            removeKeyword(kw) {
                this.$emit('update:list', this.list.filter(k => k.text !== kw.text));
            },
            refreshTotal() {
                this.paginationOptions.total = this.list.length;
            },
        },
        mounted() {
            this.refreshTotal();
        },
        watch: {
            list: {
                deep: true,
                handler() {
                    this.refreshTotal();
                },
            },
        },
        i18n: { messages },
    };
</script>

<style lang="less">
    .ma-keyword-list {
        .ant-card-head {
            @apply py-3 px-2;
            .ant-card-head-wrapper, .ant-card-head-title {
                @apply p-0;
            }
        }
        .ant-card-body {
            @apply px-1 py-4;

            .ma-keyword-list-actions {
                @apply flex justify-between items-center mt-2 ml-2 h-8
                border border-white; //align select all checkbox with other checkboxes
                .ant-btn {
                    @apply text-sm;
                }
            }

            .ma-keyword-adding-list {
                @apply my-1 mx-0;
                min-height: 460px;
                .ma-keyword-list-row {
                    @apply flex justify-between items-center px-2 py-2;
                }
                .ma-kw-match-type-input {
                    @apply w-full desktop-lg:w-48;
                    &-small {
                        @apply w-14 laptop:w-24 desktop-lg:w-full;
                    }
                    .anticon.anticon-close:before {
                        @apply hidden;
                    }
                }
                .ma-kw-amount-input {
                    @apply w-24;
                }
            }
            .ma-data-paginated {
                height: 430px;
                overflow: auto;
            }
        }
        .ant-input {
            @apply break-normal;
        }
        .ma-no-keywords-message {
            @apply text-center text-gray-400 my-16 mx-4;
        }
        .fa-exclamation-triangle {
            @apply text-red-500 w-8;
        }
    }

    .ma-kw-match-type-dropdown, .ma-tooltip-kw-list {
        z-index: 4600;
    }
    .ma-kw-list-modal {
        .ant-modal-mask, .ant-modal-wrap {
            z-index: 2050;
        }
    }
    .ma-added-kw-tooltip {
        z-index: 3010;
    }

    .ma-add-label-badge {
        @apply bg-gray-100 text-gray-600 cursor-pointer font-medium;
        .ma-icon {
            @apply h-4 w-4 text-gray-600;
        }
    }
    .ma-add-label-badge--single {
        @apply !p-1;
    }
</style>
