<template>
    <ma-tooltip type="primary" placement="bottomLeft">
        <template #title>
            <span v-html="tooltipContent"/>
        </template>
        <div class="flex gap-2">
            <ma-badge
                v-if="noIntegrations"
                shape="square"
                type="secondary"
                variant="blue"
                icon="circle-exclamation"
            >
                {{ $t('common.noIntegration') }}
            </ma-badge>
            <ma-badge
                v-if="!noIntegrations && noValidIntegrations"
                shape="square"
                type="secondary"
                variant="orange"
                icon="circle-exclamation"
            >
                {{ $t('noValidIntegration') }}
            </ma-badge>
            <ma-badge
                v-if="expired"
                shape="square"
                type="secondary"
                variant="orange"
                icon="circle-exclamation"
            >
                {{ $t('expired') }}
            </ma-badge>
            <ma-badge
                v-if="enabled && !noValidIntegrations"
                shape="square"
                type="secondary"
                variant="green"
                size="large"
                icon="tick-circle-bold"
            >
                {{ $t('live') }}
            </ma-badge>
            <ma-badge
                v-else-if="!enabled && !noValidIntegrations"
                shape="square"
                type="line"
                variant="orange"
                icon="circle-exclamation"
            >
                {{ $t('notLive') }}
            </ma-badge>
        </div>
    </ma-tooltip>
</template>

<script>
    import { apiState } from '@/common/MaRequests/SearchAds';
    import AccountIntegratedCheck from '@/mixins/AccountIntegratedCheck.mjs';
    import { validIntegrationOrgIdList } from '@/common/FilteringUtils';
    import { MaBadge, MaTooltip } from '@mobileaction/action-kit';
    import messages from '@/components/Topbar/MaApiState.i18n';
    import { mapState } from 'pinia';
    import { useAccountStore } from '@/stores/Account';

    export default {
        name: 'ma-api-state',
        components: { MaBadge, MaTooltip },
        mixins: [AccountIntegratedCheck],
        data() {
            return {
                apiStateInfo: null,
            };
        },
        computed: {
            ...mapState(useAccountStore, ['integrations']),
            enabled() {
                return !this.apiStateInfo || this.apiStateInfo.counter < 3;
            },
            noIntegrations() {
                return !Object.keys(this.integrations)?.length;
            },
            noValidIntegrations() {
                return !validIntegrationOrgIdList().length;
            },
            expired() {
                return this.accountIntegrated && !validIntegrationOrgIdList().length;
            },
            tooltipContent() {
                if (this.noIntegrations) {
                    return this.$t('noIntegrationInfo');
                } else if (!this.noIntegrations && this.noValidIntegrations) {
                    return this.$t('noValidIntegrationInfo');
                } else if (this.expired) {
                    return this.$t('expiredInfo');
                } else if (this.enabled) {
                    return this.$t('liveInfo');
                } else {
                    return this.$t('notLiveInfo');
                }
            },
        },
        methods: {
            fetchApiState() {
                apiState()
                    .then((data) => {
                        this.apiStateInfo = data;
                    })
                    .catch((e) => {
                        this.$log.error('Failed to get api state with errors: ', e);
                    });
            },
        },
        created() {
            this.fetchApiState();
        },
        i18n: {
            messages,
        },
    };
</script>

<style lang="less" scoped>
    .ak-badge {
        @apply h-8;
        :deep(svg) {
            @apply  w-4 h-4;
        }
    }
</style>
