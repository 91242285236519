<template>
    <a-menu class="ma-menu-dropdown" @click="({ key }) => menuSelected(key)">
        <a-menu-divider/>
        <template v-for="item in SIDEBAR_ITEMS" :key="item.route?.name || item.key">
            <a-menu-item v-if="!item.isGroup" :key="item.route.name" :disabled="isUserNoAccess">
                <div class="flex gap-2 items-center">
                    <ma-icon :name="item.icon" size="sm"/>
                    {{ $t(item.labelKey) }}
                </div>
            </a-menu-item>
            <template v-else>
                <a-menu-item v-for="groupItem in item.groupItems" :key="groupItem.route?.name" :disabled="isUserNoAccess">
                    <div class="flex gap-2 items-center">
                        <ma-icon :name="groupItem.icon" size="sm"/>
                        &nbsp;{{ $t(groupItem.labelKey) }}
                    </div>
                </a-menu-item>
            </template>
        </template>
        <a-menu-item key="setup-guide" :disabled="isUserNoAccess">
            <div class="flex gap-2 items-center">
                <ma-icon name="tick-circle-bulk" size="sm"/>
                &nbsp;{{ $t('setupCompleteness') }}
            </div>
        </a-menu-item>
    </a-menu>
</template>

<script setup>
    import { MaIcon } from '@mobileaction/action-kit';
    import { SIDEBAR_ITEMS } from '@/common/SidebarUtils';
    import { useRouter } from 'vue-router';
    import { defineOptions } from 'vue';
    import i18n from '@/components/MaSidebar/Sidebar.i18n';
    import { useAccountStore } from '@/stores/Account';
    import { storeToRefs } from 'pinia';

    defineOptions({
        i18n,
    });

    const router = useRouter();
    function menuSelected(command) {
        router.push({ name: command });
    }

    const accountStore = useAccountStore();
    const { isUserNoAccess } = storeToRefs(accountStore);
</script>

<style scoped lang="less">

</style>
