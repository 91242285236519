/**
 * User: fma
 * Date: 27/01/17
 * Time: 7:14 PM
 *
 * This module holds an ajax pool which makes requests by extending CORS headers
 * and enables aborting of all requests in case of page changes
 *
 * settings:
 * cacheEnabled => default: false
 *   if set to true will return result if cached otherwise makes the request and cached the result
 *
 * deduplicate => default: true
 *   if set to false will disable deduplicate GET requests if set to true first cached request will return so that it won't create duplicate requests
 *
 *  formatter => default: null, gets formatter as function
 *   if set promise will use this function to format data on success after converting norm data
 *
 * id => default: current url
 *   if given uses the pool with given id
 *
 * cacheResponseDelay => default: 10
 *   delay to fire cached result event handlers
 *
 * cacheTimeout => default: day in ms
 *   expiration time in ms from now
 */
import { globalProperties as app } from '@/main';
import axios from 'axios';
import { isLayoutAuthentication } from '@/router';
import EventBus from '@/common/EventBus.mjs';
import {
    DAY_IN_MS,
    SEC_IN_MS,
    isNoData,
    randomString,
    redirectToLogin,
    sendSentryError,
} from '@/common/MaUtils.mjs';
import Errors, { checkError } from '@/common/ErrorMessages';
import {
    GATEWAY_DOMAIN,
    DEBUG_SESSION_EXPIRED,
} from '@/common/Config';
import * as MaCookieStorage from '@/common/MaCookieStorage';
import { useAccountStore } from '@/stores/Account';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/Auth';

const pools = {};
const promiseCache = new Map();

const REQUEST_PREFIX = 'MA_REQ_';
// converts data object to the norm object type defined in /dashboard-ui/wiki
function convertDataToNorm(data) {
    if (!data) {
        return {
            success: false,
            errors: ['NO_DATA'],
            data,
            errorData: {},
        };
    }
    // Return directly if it is in correct format
    if ('success' in data &&
        (data.success === true || 'errors' in data)) {
        /** // TODO enable these lines to fix data in data problem for sa endpoints
         if (resp.data && typeof resp.data.success === 'boolean') {
            return resp.data;
        }
         */
        return data;
    }

    const success = 'success' in data
        ? data.success
        : data.status !== 'fail';
    return {
        success,
        errors: [data.message] || ['UNKNOWN_ERROR'],
        data: success && data,
        errorData: !success && data,
    };
}

function storeNewJwt(data, headers) {
    // clear old session promise cache when storing new jwt
    const authStore = useAuthStore();
    clearPromiseCache();
    if ('ma-jwt' in headers) {
        const userAuth = MaCookieStorage.get(MaCookieStorage.COOKIES.AUTH) || {};
        userAuth.jwt = headers['ma-jwt'];
        authStore.setUserAuth(userAuth);
    }
    return data;
}

function createAxios(baseURL = GATEWAY_DOMAIN) {
    return axios.create({
        baseURL,
        responseType: 'json',
        transformResponse: [
            ...axios.defaults.transformResponse, // put back default transformer to parse string to JSON see: https://github.com/axios/axios/issues/430#issuecomment-243481806
            storeNewJwt, // get ma jwt header from response if it exists
            convertDataToNorm,
        ],
        withCredentials: false,
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
    });
}

// Standard settings that should be used as default for all ajax calls
export const DEFAULT_OPTIONS = {
    cacheEnabled: false,
    deduplicate: true,
    cacheInvalidate: false,
    resolveFullData: false, // returns the response in full for cases that have pagination etc. alongside data prop
    cacheResponseDelay: 10,
    cacheTimeout: DAY_IN_MS, // cache timeout time in ms
};

// clears the page pool with given pool_id
function clearPool(poolId) {
    if (!poolId) {
        app.$log.error('Invalid id given:', poolId);
        return;
    }
    if (!(poolId in pools)) {
        app.$log.error('Tried to clean non existing pool with id:', poolId);
        return;
    }
    const p = pools[poolId];
    Object.keys(p).forEach((reqId) => {
        p[reqId].cancel(); // cancel calls complete thus erases this
    });
    if (pools[poolId]) {
        delete pools[poolId];
    }
}

export const MaGatewayAxios = createAxios(GATEWAY_DOMAIN);
export const REQUESTERS = {
    gateway: MaGatewayAxios,
};
export const METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

let lastSessionExpired = 0;

function tokenExpired(multipleLoginDetected = false) {
    const authStore = useAuthStore();
    authStore.setUserAuth(null);
    clearPromiseCache();
    if (Date.now() - lastSessionExpired < SEC_IN_MS * 5) { // to prevent multiple warning messages in short time
        redirectToLogin(false);
        return;
    }
    lastSessionExpired = Date.now();
    EventBus.bus.$emit(EventBus.c.MULTI_LOGIN_DETECTED, multipleLoginDetected);
    clear();
    redirectToLogin(false);
    if (!DEBUG_SESSION_EXPIRED) {
        return;
    }
    app.$log.error('Session expired at', (new Error('Session Expired!')).stack);
}

function notifyIntegrationsExpired() {
    EventBus.bus.$emit(EventBus.c.INTEGRATIONS_EXPIRED);
}

// temporary bugsnag log to see what's unexpected error
function safeErrorCodesSpread (e, url) {
    if (!e) { // no need to proceed further if error code's undefined
        return [];
    }
    const stringifyError = (err, filter, space) => {
        const plainObject = {};
        Object.getOwnPropertyNames(err).forEach(key => plainObject[key] = err[key]);
        return JSON.stringify(plainObject, filter, space);
    };

    let error = '';
    if (e instanceof Error) {
        error = `stringifyError; ${ stringifyError(e, null, '\t') }`;
    } else {
        error = `stringifyJson; ${ JSON.stringify(e) }`;
    }
    try {
        return [...e];
    } catch (_) {
        sendSentryError('unexpected error code:', { error, url });
        return [];
    }
}

// public functions
// makes a request to given url with ajax options along with pooler options
export function makeRequest(url, axiosCfg, poolerOpts, PoolerAxios = REQUESTERS.gateway) {
    // load axios config with defaults
    const _axiosCfg = Object.assign({ method: METHOD.GET, formatter: null }, axiosCfg);
    app.$log.debug(`Making request ${ _axiosCfg.method } ${ url }`);

    // load pooler options with defaults
    let _poolerOpts = Object.assign(
        { id: window.location.href },
        DEFAULT_OPTIONS,
        poolerOpts);

    const isPromiseCacheable = _axiosCfg.method === METHOD.GET && _poolerOpts.deduplicate && !_poolerOpts?.cacheInvalidate;
    // no need to create new promise, return cached promise which is in pending process
    if (isPromiseCacheable && promiseCache.has(url)) {
        return promiseCache.get(url);
    }

    // generate a random request id
    const reqId = randomString(16);
    const cancelSource = axios.CancelToken.source();

    // will return a promise I promise :)
    const p = new Promise(((resolve, reject) => {
        // check whether if cache enabled and we cached it
        if (_poolerOpts.cacheEnabled && !_poolerOpts.cacheInvalidate) {
            // check cached response and if it exists return it
            const cachedResponse = app.$maSessionStorage.get(REQUEST_PREFIX + url);
            // if cached response is valid return it
            if (cachedResponse) {
                // if it has a valid expiration time and not expired yet return it
                if (cachedResponse.exp && cachedResponse.exp <= Date.now()) {
                    app.$log.debug(`Using cached data at ${ cachedResponse.ts } ${ _axiosCfg.method } ${ url }`,
                        cachedResponse);
                    setTimeout(() => {
                        resolve(_poolerOpts.resolveFullData ? cachedResponse : cachedResponse.data);
                    }, _poolerOpts.cacheResponseDelay);
                    return;
                }

                app.$maSessionStorage.del(REQUEST_PREFIX + url);
            }
        }
        // if not cached make a request and return Promise
        // add xhr to pool and return
        pools[_poolerOpts.id] = pools[_poolerOpts.id] || {};
        pools[_poolerOpts.id][reqId] = {
            id: reqId,
            cancel: cancelSource.cancel,
            ts: Date.now(),
        };

        const headers = {};
        if (_axiosCfg.headers) {
            Object.assign(headers, _axiosCfg.headers);
        }
        const authStore = useAuthStore();
        const { userAuth, rftData, jwtData  } = storeToRefs(authStore);


        let authToken = null;
        // do auth header magic only on gateway requests that require auth and use relative urls
        if (PoolerAxios === REQUESTERS.gateway && url[0] === '/' && !_poolerOpts.disableAuthCheck) {
            if (userAuth.value) {
                const { jwt, refreshToken } = userAuth.value;
                // adding 30 seconds as request flight time so token won't be expired when it arrives (30s is heroku limit)
                const nowInSecs = new Date() / 1000 + 30;
                if (jwtData.value && jwtData.value.exp > nowInSecs) { // if jwt is valid use it
                    authToken = jwt;
                } else { // otherwise use refresh token and get new jwt in response
                    // if refresh token is invalid reset values and show not logged in
                    if (!rftData.value || rftData.value.exp < nowInSecs) {
                        app.$log.warn('Token expired is occurred with no-expected way.');
                        tokenExpired();
                        return reject({ errors: [Errors.AUTH_CHECK_FAILED, Errors.TOKEN_EXPIRED] });
                    }
                    // otherwise make request with refresh token to get new jwt
                    app.$log.warn('Refreshing jwt token by using refresh token');
                    authToken = refreshToken;
                }
            } else {
                tokenExpired();
                app.$log.warn('Token expired is occurred with expected way.');
                return reject({ errors: [Errors.AUTH_CHECK_FAILED, Errors.NO_TOKEN] });
            }
        }
        if (authToken) {
            headers.authorization = `Bearer ${ authToken }`;
        }
        const accountStore = useAccountStore();
        const { accountIntegrated, activeOrg } =  storeToRefs(accountStore);
        if (accountIntegrated.value) {
            const account =  activeOrg.value;
            headers['x-sa-integ-id'] = _axiosCfg.integId || account.integId;
            headers['x-sa-org-id'] = _axiosCfg.orgId || account.orgId;
        }

        PoolerAxios
            .request(Object.assign(_axiosCfg, {
                url,
                headers,
                cancelToken: cancelSource.token,
            }))
            .then(({ data, status, statusText }) => {
                const normData = convertDataToNorm(data);

                // format data if data formatter function defined
                const { formatter } = _axiosCfg;
                if (normData.data?.length && formatter && typeof formatter === 'function') {
                    normData.data = formatter(normData.data);
                }

                app.$log.debug(`Data received ${ status }-${ statusText } ${ _axiosCfg.method } ${ url } normData:`,
                    normData);

                if (!normData.success) {
                    const errors = normData.errors || [];
                    if (isNoData(normData)) {
                        resolve([]);
                        return;
                    }
                    if (checkError(errors, Errors.NOT_LOGGED_IN)) {
                        if (!isLayoutAuthentication() && !_poolerOpts.disableAuthCheck) {
                            app.$log.warn(`Token expired is occurred with ${Errors.NOT_LOGGED_IN} error.`);
                            tokenExpired();
                        }
                    }
                    const isSaHeaderMissing = !headers['x-sa-integ-id'] || !headers['x-sa-org-id'];
                    if (checkError(errors, Errors.MISSING_SA_HEADER) && isSaHeaderMissing) {
                        app.$log.error('Request required sa headers but was missing:', normData);
                        const accountStore = useAccountStore();
                        const { integrations } =  storeToRefs(accountStore);
                        const validIntegId = Object.keys(integrations).find(integId => integrations[integId].length);
                        if (validIntegId) {
                            accountStore.changeOrg(integrations[validIntegId][0]).catch(e => this.$log.error('failed to change org:', e));
                        }
                    }
                    app.$log.error(`Failed to make request ${ _axiosCfg.method } ${ url } with:`, errors, data);
                    reject(normData);
                    return;
                }

                if (_poolerOpts.cacheEnabled) {
                    const ts = Date.now();

                    const exp = ts + _poolerOpts.cacheTimeout;
                    app.$maSessionStorage.set(REQUEST_PREFIX + url, {
                        data: normData.data,
                        pagination: normData.pagination,
                        ts,
                        exp,
                    });
                    app.$log.debug(`Cached data at ${ ts } until ${ exp } ${ _axiosCfg.method } ${ url } normData:`, normData);
                }

                if (_poolerOpts.resolveFullData) {
                    resolve(normData);
                } else {
                    if (normData.pagination) {
                        sendSentryError(new Error(`Possible missing resolveFullData for request on url=${ url }`));
                        app.$log.warn('Possible missing resolveFullData for request', _axiosCfg.method, url);
                    }
                    resolve(normData.data);
                }
            })
            .catch((error) => { // normally no request should respond with fail
                if (axios.isCancel(error)) { // axios cancel handling
                    reject({ errors: [Errors.REQUEST_CANCELED] });
                    return;
                }
                const { response, message } = error;
                const data = response?.data || {};
                const status = response?.status || message;
                if (status === 401 && checkError(data?.errors, Errors.AUTHENTICATION_FAILED)) {
                    // make sure both requester and layout is correct
                    if (PoolerAxios === REQUESTERS.gateway && !isLayoutAuthentication()) {
                        if (!_poolerOpts.disableAuthCheck) {
                            app.$log.warn(`Token expired is occurred with ${Errors.AUTHENTICATION_FAILED} error.`);
                            tokenExpired(data?.errors?.includes(Errors.AUTH_MULTIPLE_LOGIN));
                        }
                    }
                    data.errors = [...safeErrorCodesSpread(data?.errors, url), Errors.NOT_LOGGED_IN];
                    reject(data, status);
                    return;
                }

                // show user a warning if all integrations of organization are expired
                if (checkError(data?.errors, Errors.ACCOUNT_NOT_FOUND)) {
                    notifyIntegrationsExpired();
                }

                if (status === 404) {
                    sendSentryError(`Not found endpoint ${ _axiosCfg.method } ${ url }`);
                }

                // default to have FETCH_ERROR to know it came from us
                let errors = ['FETCH_ERROR'];
                if (status === 'Network Error') {
                    errors.push(Errors.NETWORK_ERROR);
                }
                // concat errors with backend error codes
                errors = [
                    ...errors,
                    ...safeErrorCodesSpread(data?.errors, url),
                ];
                reject({ errors, errorData: error?.data }, status);
            })
            .finally(() => {
                // remove promise from cache after it's done resolved
                if (isPromiseCacheable && promiseCache.has(url)) {
                    setTimeout(() => promiseCache.delete(url), 10000);
                }
            });
    }));

    // add promise to the cache if it's cacheable
    if (isPromiseCacheable) {
        promiseCache.set(url, p);
    }

    // set pool, request id, and cancel function for future cancel/clears
    p.poolId = _poolerOpts.id;
    p.reqId = reqId;
    p.cancel = cancelSource.cancel;
    return p;
}

// clears promiseCache
export function clearPromiseCache() {
    // clear promise cache
    promiseCache.clear();

}
// clears all page pools if no id is given otherwise clears only given pool
export function clear(id) {
    // if id is given just destroy given id
    if (id || id in pools) {
        clearPool(id);
        return;
    }
    // check all pools of all ids
    Object.keys(pools).forEach((poolId) => {
        clearPool(poolId);
    });
}

export function makeRequestNoAuth(url, axiosCfg, poolerOpts, clearCache) {
    if (clearCache) {
        clearPromiseCache();
    }
    return makeRequest(url, axiosCfg, Object.assign({}, poolerOpts, { disableAuthCheck: true }));
}

export function cacheRequest(url, axiosCfg, poolerOpts) {
    return makeRequest(url, axiosCfg, Object.assign({}, poolerOpts, { cacheEnabled: true }));
}

export function makePreRequest(url) {
    return axios.get(GATEWAY_DOMAIN + url);
}
