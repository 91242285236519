import { defineStore } from 'pinia';
import { getOnboardingStatus } from '@/common/MaRequests/Summary';
import { globalProperties as app } from '@/main';
import { sortByKey } from '@/common/MaUtils.mjs';

const ORDER = [
    'integration',
    'mmp',
    'goal',
    'competitor',
    'other',
];

export const useSetupGuideStore = defineStore('setup-guide', {
    state: () => ({
        onboardingStatus: null,
        statusFetched: null,
        loading: null,
    }),
    getters: {
        onboardingProgress() {
            if (!this.statusFetched) {
                return;
            }
            return this.onboardingStatus?.total;
        },
        integration() {
            if (this.loading || !this.statusFetched) {
                return [];
            }
            return this.onboardingStatus?.sections?.integration;
        },
        mmpSteps() {
            if (this.loading || !this.statusFetched) {
                return [];
            }
            return this.onboardingStatus?.sections?.mmp?.steps;
        },
        hasNoIntegration() { // account does not have ASA integration
            return !this.integration.state;
        },
        timeZoneStatus() {
            if (this.loading || !this.statusFetched) {
                return [];
            }
            const timeZoneState = this.integration?.steps.find(a => a.name === 'TIME_ZONE');
            return timeZoneState?.state;
        },
        hasNoFirstAttribution() { // account does not have mmp attribution integration
            const firstAttribution = this.mmpSteps.find(a => a.name === 'FIRST_ATTRIBUTION');
            return !firstAttribution?.state;
        },
        statusList() {
            if (this.loading || !this.statusFetched) {
                return [];
            }
            const list = Object.keys(this.onboardingStatus?.sections).map((section) => {
                const obj = this.onboardingStatus?.sections[section];
                return {
                    section,
                    ...obj,
                    index: ORDER.indexOf(section),
                };
            });
            return sortByKey(list, 'index');
        },
    },
    actions: {
        async fetchOnboardingStatus(cacheInvalidate = false) {
            this.loading = true;
            this.statusFetched = false;
            try {
                this.onboardingStatus = await getOnboardingStatus(cacheInvalidate);
                this.statusFetched = true;
            } catch (e) {
                app.$log.error('Failed to fetch onboardingStatus', e);
            } finally {
                this.loading = false;
            }
        },
    },
});
