import { makeRequest } from '@/common/MaRequestPooler';
import { paramsToQuery } from '@/common/MaUtils.mjs';

export class TimeZoneController {
    constructor(pooler) {
        this.requestPooler = pooler || makeRequest;
    }

    /**
     * Updates campaign groups default time zones.
     */
    updateCampaignGroupTimeZones(data) {
        return this.requestPooler(`/sa/account/campaign-group-timezones`, {
            method: 'POST',
            data,
        });
    }

    /**
     * Returns campaign groups eligible time zones.
     * If some campaign groups which are belong to account was set before, for this campaign groups,
     * only default time zone that was set before will be returned.
     */
    fetchEligibleTimezones(data) {
        return this.requestPooler(`/sa/account/campaign-group-timezones/eligible`, {
            method: 'POST',
            data,
        });
    }

    /**
     * Returns campaign groups default time zones.
     * orgId parameter is not required. If the field is empty, endpoint will return all campaign groups
     * of account default zones
     */

    fetchDefaultTimeZones(orgId) {
        return this.requestPooler(`/sa/account/campaign-group-timezones?${paramsToQuery(orgId)}`);
    }
}

export const useTimeZoneController = () => new TimeZoneController();
