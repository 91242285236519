import en from '@/i18n/pages/AddKeywordsPage/en.json';
import cn from '@/i18n/pages/AddKeywordsPage/cn.json';
import ja from '@/i18n/pages/AddKeywordsPage/ja.json';
import ko from '@/i18n/pages/AddKeywordsPage/ko.json';

export default {
    messages: {
        cn,
        en,
        ja,
        ko,
    },
};
