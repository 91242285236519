import { authEvent, sidebarEvent } from '@/plugins/segment/eventDefinitions';
import { useGlobal } from '@/composables';

export default {
    login: authEvent.login,
    signup: authEvent.signup,
    pageView: authEvent.pageView,
};


export const useTrackEvents = () => {
    const { $segment } = useGlobal();

    const sidebarCPPMenuItemClicked = () => {
        $segment.track(...sidebarEvent.cppIntelligenceClicked.toSegment());
    };

    return { sidebarCPPMenuItemClicked };
};
