<template>
    <ma-tooltip :title="t('youHaveSpend', { spend, startDate, endDate })" type="primary" placement="left">
        <ma-select
            :value="currencyView"
            :options="options"
            size="small"
            class="!pl-0"
            :dropdown-match-select-width="false"
            dropdown-class-name="!z-[1000]"
            :loading="!props.spend"
            @change="accountStore.toggleCurrencyView"
        >
            <template #option="{ text }">
                {{ text }}
            </template>
        </ma-select>
    </ma-tooltip>
</template>

<script setup>
    import { MaSelect, MaTooltip } from '@mobileaction/action-kit';
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import CurrencySymbolMap from 'currency-symbol-map';
    import dayjs from '@/plugins/dayjs.mjs';
    import messages from '@/components/Topbar/Topbar.i18n';
    import { ISO_DATE_FORMAT } from '@/common/MaUtils.mjs';
    import { useAccountStore } from '@/stores/Account';
    import { storeToRefs } from 'pinia';

    const { t } = useI18n();
    defineOptions({
        i18n: { messages },
    });
    const props = defineProps({
        spend: { type: [String, Number], required: true },
    });

    const accountStore = useAccountStore();
    const { currencyView, currency } = storeToRefs(accountStore);

    const CURRENCY_VIEW = {
        CODE: 'code',
        SYMBOL: 'symbol',
    };
    const options = computed(() => [
        { value: CURRENCY_VIEW.CODE, label: props.spend || '', text: t('common.currencyCode', { code: currency.value }) },
        { value: CURRENCY_VIEW.SYMBOL, label: props.spend || '', text: t('common.currencySymbol', { currency: CurrencySymbolMap(currency.value) }) },
    ]);

    const startDate = dayjs().add(-30, 'days').format(ISO_DATE_FORMAT);
    const endDate = dayjs().format(ISO_DATE_FORMAT);
</script>
