import { isTestUser } from '@/common/MaUtils.mjs';
import { useAuthStore } from '@/stores/Auth';


export function initialize(user) {
    // just in case compare hashes for test email
    if (!user || isTestUser(user.username)) {
        return;
    }
    if (window.location.host !== 'searchads.mobileaction.co') {
        return;
    }

    window._fs_debug = false;
    window._fs_host = 'www.fullstory.com';
    window._fs_script = '//edge.fullstory.com/s/fs.js';
    window._fs_org = '3TA9V';
    window._fs_namespace = 'FS';

    function initFS(l) {
        const t = 'script';
        const ns = window._fs_namespace;
        if (ns in window && window.console && window.console.log) {
            window.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
            return;
        }
        const g = window[ns] = (a, b) => {
            if (g.q) {
                g.q.push([a, b]);
            } else {
                g._api(a, b);
            }
        };
        g.q = [];
        const o = document.createElement(t);
        o.async = 1;
        o.src = window._fs_script;
        const y = document.getElementsByTagName(t)[0];
        y.parentNode.insertBefore(o, y);
        g.identify = function (i, v) {
            g(l, { uid: i });
            if (v) {
                g(l, v);
            }
        };
        g.setUserVars = function (v) {
            g(l, v);
        };
        g.identifyAccount = function (i, v) {
            const o = 'account';
            const _v = v || {};
            _v.acctId = i;
            g(o, _v);
        };
        g.clearUserCookie = function (cookie) { // noinspection JSCheckFunctionSignatures
            if (!cookie || document.cookie.match('fs_uid=[`;`]*`[`;`]*`[`;`]*`')) {
                let d = document.domain;
                // eslint-disable-next-line no-constant-condition
                while (1) {
                    document.cookie = `fs_uid=;domain=${ d };path=/;expires=${ new Date(0).toUTCString() }`;
                    const i = d.indexOf('.');
                    if (i < 0) {
                        break;
                    }
                    d = d.slice(i + 1);
                }
            }
        };
    }

    initFS('user');

    const authStore = useAuthStore();
    const imp = authStore.maImp;

    const prefix = imp !== null ? `MA-admin:${ imp }:` : '';

    const fsData = {
        displayName: `${ prefix }${ user.username }`,
        email: `${ prefix }${ user.username }`,
        pricingPlan_str: user.productMap ? Object.values(user.productMap).join(', ') : '',
        createdAt_date: new Date(+user.createdAt * 1000),
        maAdmin: imp !== null,
    };
    if (imp !== null) {
        fsData.imp_int = imp;
    }
    // if our admins logged in we have to give a different user id so it
    // won't mess up user info on FS
    const userId = `${ prefix }${ user.userId }`;
    window.FS.identify(userId, fsData);
}

export default {
    initialize,
};
