<template>
    <div class="relative">
        <ma-tooltip placement="left" type="primary">
            <template #title v-if="!collapse">
                {{ $t('addedKeywords') }} ({{ keywords.length }})
            </template>
            <div
                v-if="!collapse"
                class="ma-keyword-cart-toggle"
            >
                <a-badge :count="keywords && keywords.length" :offset="[-40, 0]">
                    <a-button
                        type="link"
                        @click="toggleCollapse(true)"
                    >
                        <fa
                            size="2x"
                            class="ma-collapse-icon"
                            icon="chevron-left"
                        />
                    </a-button>
                </a-badge>
            </div>
        </ma-tooltip>
        <a-spin :spinning="loading">
            <a-drawer
                :open="collapse"
                :style="borderStyle"
                :closable="false"
                :body-style="{ height: '100%' }"
                width="25%"
                @close="toggleCollapse(false)"
            >
                <div class="ma-keyword-cart">
                    <div class="ma-keyword-list">
                        <a-table
                            v-if="keywords.length > 0"
                            ref="keywordCartList"
                            :data-source="keywords"
                            :columns="columns"
                            :bordered="false"
                            :pagination="false"
                            size="small"
                        >
                            <template #headerCell="{ column }">
                                <template v-if="column.dataField === 'keyword'">
                                    <div class="flex items-center space-x-1">
                                        <a-tooltip :title="`${$t('common.delete')} ${$t('common.all')}`" placement="top">
                                            <fa
                                                class="cursor-pointer px-2 text-gray-400 hover:text-red-600"
                                                icon="trash"
                                                @click="removeAll"
                                            />
                                        </a-tooltip>
                                        <span>{{ $t('columns.keyword') }}</span>
                                    </div>
                                </template>
                                <template v-if="column.dataField === 'popularity'">
                                    <span>{{ $t('popularity') }}</span>
                                </template>
                            </template>
                            <template #bodyCell="{ record, column }">
                                <template v-if="column.dataField === 'keyword'">
                                    <div class="flex whitespace-normal items-center">
                                        <fa
                                            class="cursor-pointer px-2 text-gray-400 hover:text-red-600"
                                            icon="trash"
                                            @click="removeFromList(record.keyword)"
                                        />
                                        {{ record.keyword }}
                                    </div>
                                </template>
                                <template v-if="column.dataField === 'popularity'">
                                    {{ record.searchScore }}
                                </template>
                            </template>
                        </a-table>
                        <a-alert
                            v-else
                            center
                            :message="$t('noKeywordAddedList')"
                            type="info"
                            :closable="false"
                        />
                    </div>

                    <div class="flex flex-col space-y-2">
                        <small v-if="keywords.length">
                            {{ $t('total') }} {{ keywords.length }}/{{ maxLengthAllowed }}<br>
                        </small>
                        <div class="flex items-center space-x-2">
                            <a-popover
                                trigger="click"
                                placement="left"
                            >
                                <template #content>
                                    <div class="w-full">
                                        <ma-button
                                            variant="ghost"
                                            class="!w-full"
                                            @click="openModal(KEYWORD_TYPES.KEYWORD)"
                                        >
                                            {{ $t('addToKeywords') }}
                                        </ma-button>
                                    </div>
                                    <div class="w-full mt-1">
                                        <ma-button
                                            variant="ghost"
                                            class="!w-full"
                                            @click="openModal(KEYWORD_TYPES.NEGATIVE)"
                                        >
                                            {{ $t('addToNegativeKeywords') }}
                                        </ma-button>
                                    </div>
                                </template>
                                <ma-button
                                    color="blue"
                                    :disabled="!keywords.length"
                                >
                                    {{ $t('runAds') }}
                                </ma-button>
                            </a-popover>

                            <ma-button
                                color="blue"
                                variant="ghost"
                                icon="download"
                                :loading="csvDownloading"
                                :disabled="!keywords.length"
                                @click="csvDownload"
                            >
                                {{ $t('csv') }}
                            </ma-button>
                        </div>
                    </div>
                </div>

                <ma-keyword-add-modal
                    v-model="keywordsModal.show"
                    :keywords="keywordsModal.list"
                    :add-keyword-type="keywordsModal.addKeywordType"
                    @keywords-saved="keywordsSaved"
                />
            </a-drawer>
        </a-spin>
    </div>
</template>

<script setup>
    import { MaButton, MaTooltip } from '@mobileaction/action-kit';
</script>
<script>
    import { downloadDataAsCsv } from '@/common/MaUtils.mjs';
    import MaKeywordAddModal from '@/components/KeywordAddModal/index.vue';
    import Formatter from '@/mixins/Formatter.mjs';
    import MetadataUtils from '@/mixins/MetadataUtils.mjs';
    import { KEYWORD_TYPE } from '@/common/ManagementUtils.mjs';
    import dayjs from '@/plugins/dayjs.mjs';
    import FetchCompletedCheck from '@/mixins/FetchCompletedCheck.mjs';
    import { useKeywordCartStore } from '@/stores/KeywordCart';
    import { keywordAdvisorEvents } from '@/plugins/segment/eventDefinitions';
    import { mapActions, mapState } from 'pinia';
    import { useKeywordAuctionInsightEvents } from '@/plugins/segment/definitions/keywordAuctionInsightEvents';
    import messages from '@/components/KeywordCart/KeywordCart.i18n';

    export default {
        name: 'ma-keyword-cart',
        components: {
            MaKeywordAddModal,
        },
        mixins: [Formatter, MetadataUtils, FetchCompletedCheck],
        setup() {
            const event = useKeywordAuctionInsightEvents();
            return { event };
        },
        data() {
            return {
                collapse: this.$route.name === 'optimization',
                showBasket: false,
                loading: false,
                startDate: dayjs().add(-7, 'days').format('YYYY-MM-DD'),
                endDate: dayjs().format('YYYY-MM-DD'),
                keywordLimit: 5000,
                maxLengthAllowed: 1000,
                csvDownloading: false,
                keywordsModal: {
                    list: [],
                    show: false,
                    addKeywordType: KEYWORD_TYPE.KEYWORD,
                },
                KEYWORD_TYPES: {
                    NEGATIVE: 'NEGATIVE',
                    KEYWORD: 'KEYWORD',
                },
            };
        },
        computed: {
            ...mapState(useKeywordCartStore, { keywords: 'keywords' }),
            columns() {
                const keyword = { dataField: 'keyword' };
                const popularity = { dataField: 'popularity', align: 'right' };
                if (!this.isKeywordAdvisor) {
                    return [keyword, popularity];
                }
                return [keyword];
            },
            icon() {
                return this.disabled === true ? 'el-icon-close' : 'el-icon-search';
            },
            borderStyle() {
                return this.collapse
                    ? { 'border-left': 'solid 0 #e6e6e6' }
                    : { 'border-left': 'solid 1px #e6e6e6', 'box-shadow': '0 2px 12px 0 rgba(0, 0, 0, 0.1)' };
            },
            isKeywordAdvisor() {
                return this.$route.name === 'keyword-advisor';
            },
            isSearchByApp() {
                return this.$route.name === 'search-by-app';
            },
            isKeywordGap() {
                return this.$route.name === 'keyword-gap';
            },
        },
        methods: {
            ...mapActions(useKeywordCartStore, {
                addToCart: 'addToCart',
                emptyCart: 'emptyCart',
                removeFromCart: 'removeFromCart',
            }),
            openModal(type) {
                if (!this.fetchCompleted) {
                    this.$message.warning({
                        content: this.$t('dataIsNotReady'),
                        duration: 7,
                    });
                    return;
                }
                this.keywordsModal.list = (this.keywords || []).slice();
                this.keywordsModal.show = true;
                this.keywordsModal.addKeywordType = KEYWORD_TYPE[type];
                // track events
                if (this.KEYWORD_TYPES.KEYWORD === type) {
                    this.event.trackAddToKeywords();
                } else {
                    this.event.trackAddToNegativeKeywords();
                }
            },
            toggleCollapse(collapse) {
                this.collapse = collapse;

                if (collapse) {
                    this.$refs.keywordCartList?.$el?.focus();
                }
            },
            addKeyword(keyword) {
                if (this.keywords.length < this.keywordLimit) {
                    this.addToCart(keyword);
                } else {
                    this.$message.warning({
                        content: this.$t('limitForAdGroupKeywords'),
                        duration: 7,
                    });
                }
            },
            removeIfExceedsLimit(keywords) {
                const totalCount = this.keywords.length + keywords.length;
                if (totalCount > this.keywordLimit) {
                    const remainingCount = this.keywordLimit - this.keywords.length;
                    this.$message.warning({
                        content: this.$t('limitForAdGroupKeywords2'),
                        duration: 7,
                    });
                    return keywords.slice(0, remainingCount);
                }
                return keywords;
            },
            removeFromList(keyword) {
                const keywordToRemove = this.keywords
                    .find(k => k.keyword === keyword);
                this.removeFromCart(keywordToRemove);
            },
            removeAll() {
                this.emptyCart();
            },
            csvDownload() {
                this.csvDownloading = true;
                this.formData()
                    .then((data) => {
                        this.$maIntercom.trackEvent('exported_sa_data', {
                            dataType: 'KEYWORD_CART',
                        });
                        downloadDataAsCsv(`keyword-cart-${dayjs().format('YYYY-MM-DD')}.csv`, data);
                    })
                    .catch(this.$log.error)
                    .then(() => {
                        this.csvDownloading = false;
                    });
            },
            formData() {
                return new Promise((resolve, reject) => {
                    try {
                        setTimeout(() => {
                            const headers = ['Keyword', 'Popularity', 'Number of Apps'];
                            let csvData = this.keywords.map(d => [d.keyword, d.searchScore, d.numberOfApps]);

                            csvData.unshift(headers);
                            resolve(csvData);
                        }, 500);
                    } catch (e) {
                        reject(e);
                    }
                });
            },
            keywordsSaved(params, callback) {
                this.saveKeywords(params)
                    .then(() => {
                        callback && callback();
                        this.keywordsModal.show = false;
                        // clear keyword cart
                        this.removeAll();
                        if (this.isKeywordAdvisor) {
                            const keywordAdvisorAddedKeywordsEvent = keywordAdvisorEvents.addKeywords;
                            keywordAdvisorAddedKeywordsEvent.setPropertyValues({
                                numberOfKeywords: params.keywords.length,
                            });
                            this.$segment.track(...keywordAdvisorAddedKeywordsEvent.toSegment());
                        }
                        // track ads run on search by app
                        if (this.isSearchByApp) {
                            this.event.trackSbaAdsRun();
                        } else if (this.isKeywordGap) {
                            this.event.trackKgAdsRun();
                        }
                    })
                    .catch((e) => {
                        this.keywordsModal.show = false;
                        this.$log.error(e);
                    });
            },
        },
        i18n: { messages },
    };
</script>

<style lang="scss" scoped>
    .ma-select {
        width: 100%;
        margin-bottom: 5px;
    }
    .ma-keyword-cart-toggle {
        @apply mt-10 z-[1001] absolute top-12 right-0 rounded-l shadow-lg bg-gray-300 hover:bg-gray-400 transition duration-150 ease-in;
        .ant-btn {
            @apply px-1.5 py-6 h-full text-gray-800 hover:text-gray-900;
        }
    }

    .ma-keyword-cart {
        @apply h-full flex flex-col justify-between;

        .ma-keyword-list {
            @apply w-full overflow-auto;
        }
    }
</style>

<style lang="less">
    .dx-header-row {
        @apply bg-gray-100 leading-8 font-bold;
    }
</style>
