import { AnalyticsBrowser } from '@segment/analytics-next';
import { REQUIRED_FEATURES } from '@/common/MaSmartBiddingUtils';
import { accessPropertyOption } from '@/plugins/segment/eventDefinitions';
import SegmentWrapper from '@/plugins/segment/SegmentWrapper';

export default {
    install(app, { key, vueRouter }) {
        //initialize Segment witht the given API write key
        if (!key) {
            console.error('[Segment] Segment API write key is required');
            return;
        }

        const segmentAnalytics = AnalyticsBrowser.load({ writeKey: key });

        //Did not find these logs to be useful. We can enable this whenever we want.
        // if (!isProduction) {
        //     segmentAnalytics.debug(true);
        // }

        const segmentWrapper = new SegmentWrapper(segmentAnalytics);

        //Add Segment methods to the Vue app instance
        app.config.globalProperties.$segment = segmentWrapper;

        //Setup page view tracking with Vue Router navigation guards
        vueRouter.beforeEach((to, from, next) => {
            const url = document?.location?.origin + to.path;
            if (to.path !== from.path) {
                const { path } = to;
                const Access = pageAccessProperty(to);

                // Using the same naming convention as we were using in the segment plugin.
                segmentAnalytics.page('SA-Dashboard Page View', {
                    Page: to.meta?.title || path,
                    Access,
                    path,
                    url,
                });
            }
            next();
        });
        const pageAccessProperty = (to) => {
            let requiredFeature = to.meta.feature;
            if (to.name === 'smart-bidding' && REQUIRED_FEATURES[to.params.tab]) {
                requiredFeature = REQUIRED_FEATURES[to.params.tab];
            }
            if (!requiredFeature) {
                return accessPropertyOption.GRANTED;
            }
            const validFeatures = app.config?.globalProperties?.$maSessionStorage?.validFeatures;
            if (Array.isArray(requiredFeature)) {
                return requiredFeature.some(feature => validFeatures.includes(feature))
                    ? accessPropertyOption.GRANTED
                    : accessPropertyOption.DENIED;
            } else {
                return validFeatures.includes(requiredFeature) ? accessPropertyOption.GRANTED : accessPropertyOption.DENIED;
            }
        };
    },
};
