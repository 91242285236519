<template>
    <a-card
        shadow="none"
        class="ma-keyword-suggestions"
    >
        <template #title>
            <div class="flex items-center justify-between">
                <div class="text-sm font-normal" v-html="kwSuggestionHeader"/>
                <ma-keyword-group-filter
                    v-if="activeTab === TABS.ASO"
                    v-model:value="selectedGroupId"
                    :keyword-groups="keywordGroups"
                    :loading="loadingGroups"
                    class="m-1"
                />
            </div>
        </template>
        <div class="ma-kw-suggestion-filters-section">
            <div
                v-if="!searchOpened"
                class="ma-open-search"
                @click="openSearch"
            >
                <search-outlined v-if="selectedApp"/>
                <ma-app-info
                    v-if="selectedApp"
                    :app="selectedApp"
                    :hide-publisher="true"
                />
                <h1 v-else class="text-black">
                    {{ $t('chooseAnApp') }}
                </h1>
            </div>
            <ma-app-search
                v-show="searchOpened"
                ref="appSearch"
                class="ma-app-search"
                icon-placement="suffix"
                :hide-publisher="true"
                @keypress.esc="searchOpened = false"
                @select="handleSelect"
            />
            <div v-if="!searchOpened" class="flex justify-end items-center">
                <div class="h-12 border-r"/>
                <ma-country-select
                    v-model:value="countryCode"
                    :countries="countries"
                    show-selected-country-code
                    dropdown-class-name="z-[1000]"
                    size="small"
                    class="mx-1"
                />
                <div class="h-12 border-r"/>
                <ma-switch
                    :active="activeTab"
                    type="secondary"
                    :options="[
                        { value: TABS.ORGANIC, label: $t('organic') },
                        { value: TABS.ASO, label: $t('asoTracked') },
                        { value: TABS.PAID, label: $t('paid') },
                    ]"
                    class="mx-1"
                    @update:active="handleTabChange"
                >
                    <template #optionLabel="{ value, label }">
                        <div class="flex gap-1 items-center w-max">
                            {{ label }}
                            <ma-pro-badge
                                v-if="userHasLimitedAccess && value === TABS.PAID"
                                :limit-reached="true"
                            />
                        </div>
                    </template>
                </ma-switch>
            </div>
        </div>

        <div class="pb-2">
            <a-spin
                v-if="activeTab === TABS.ORGANIC"
                :spinning="loading.organic"
            >
                <ma-input
                    v-model:value="searchKeyword"
                    prefix-icon="search-normal"
                    :placeholder="$t('common.searchKeyword')"
                    :disabled="userHasLimitedAccess"
                    class="mt-4 mx-4 ma-borderless"
                >
                    <template #suffix>
                        <ma-pro-badge
                            v-if="userHasLimitedAccess"
                            :limit-reached="true"
                        />
                    </template>
                </ma-input>
                <div class="relative mt-4 mx-4">
                    <a-table
                        :data-source="organicListPage"
                        :pagination="false"
                        :row-key="record => JSON.stringify(record)"
                        :show-sorter-tooltip="false"
                        class="ma-keyword-suggestions-table"
                        @change="(p, f, sort) => organicSortChanged(sort)"
                    >
                        <a-table-column
                            :width="50"
                            align="center"
                        >
                            <template #title>
                                <ma-tooltip
                                    :title="$t('addAllKeywords')"
                                    placement="top"
                                    type="primary"
                                    overlay-class-name="ma-kw-suggestion-tooltip"
                                >
                                    <ma-button
                                        v-if="allAdded"
                                        icon="check-line"
                                        size="small"
                                        class="mx-auto"
                                        color="blue"
                                        @click="deselectAll"
                                    />
                                    <ma-button
                                        v-else
                                        icon="add"
                                        size="small"
                                        class="mx-auto"
                                        color="blue"
                                        variant="stroke"
                                        @click="keywordAll"
                                    />
                                </ma-tooltip>
                            </template>
                            <template #default="{ record: row }">
                                <ma-tooltip
                                    v-if="addedKeywordsMap[row.keyword]"
                                    :title="$t('added')"
                                    placement="right"
                                    type="primary"
                                    overlay-class-name="ma-kw-suggestion-tooltip"
                                >
                                    <ma-button
                                        icon="check-line"
                                        size="small"
                                        class="mx-auto"
                                        color="blue"
                                        @click="deselect(row)"
                                    />
                                </ma-tooltip>
                                <ma-button
                                    v-else
                                    icon="add"
                                    size="small"
                                    class="mx-auto"
                                    color="blue"
                                    variant="stroke"
                                    @click="keywordSelected(row)"
                                />
                            </template>
                        </a-table-column>
                        <a-table-column
                            data-index="keyword"
                            min-width="150"
                            :title="$t('columns.keyword')"
                            :sorter="true"
                            :sort-order="sortOrder['keyword']"
                        />
                        <a-table-column
                            data-index="searchScore"
                            min-width="150"
                            align="center"
                            :sorter="true"
                            :sort-order="sortOrder['searchScore']"
                        >
                            <template #title>
                                <ma-table-header header-key="searchScore"/>
                            </template>
                        </a-table-column>
                        <a-table-column
                            data-index="rank"
                            min-width="170"
                            align="center"
                            :sorter="true"
                            :sort-order="sortOrder['rank']"
                        >
                            <template #title>
                                <ma-table-header header-key="todaysRank"/>
                            </template>
                            <template #default="{ record: row }">
                                <ma-todays-rank
                                    text=""
                                    :rank="row.rank"
                                    :diff="row.change"
                                    :hide-ranking-button="true"
                                />
                            </template>
                        </a-table-column>
                    </a-table>
                    <div v-if="userHasLimitedAccess && organicPage.current > 1" class="absolute inset-0 top-10 backdrop-blur flex items-center justify-center">
                        <div class="w-[350px] flex flex-col items-center justify-center text-center gap-4">
                            <ma-badge
                                variant="green"
                                type="secondary"
                                icon="cup-bold"
                                size="large"
                            >
                                {{ $t('proFeature') }}
                            </ma-badge>
                            <ma-typography type="body-2" weight="medium" class="text-black">
                                {{ $t('upgradeToPremium') }}
                            </ma-typography>
                            <a :href="REQUEST_DEMO_URL" target="_blank">
                                <ma-button size="medium" variant="ghost" color="blue">
                                    {{ $t('talkToUs') }}
                                </ma-button>
                            </a>
                        </div>
                    </div>
                </div>

                <ma-pagination
                    v-model:current="organicPage.current"
                    v-model:per-page="organicPage.size"
                    :page-size-options="[5, 10, 20]"
                    :total-items="organicList.length"
                    :limits="{ xs: 3, sm: 3, md: 3, lg: 3, default: 3 }"
                    size="small"
                    show-less-items
                    class="m-4"
                />
            </a-spin>
            <a-spin
                v-if="activeTab === TABS.PAID"
                :spinning="loading.paid"
            >
                <a-table
                    :data-source="paidKeywords"
                    :row-key="record => JSON.stringify(record)"
                    :pagination="false"
                    :show-sorter-tooltip="false"
                    class="ma-keyword-suggestions-table mt-4 mx-4"
                    @change="(p, f, sort) => paidSortChanged(sort)"
                >
                    <a-table-column
                        :width="50"
                        align="center"
                    >
                        <template #title>
                            <ma-button
                                v-if="allAdded"
                                icon="check-line"
                                size="small"
                                class="mx-auto"
                                color="blue"
                                @click="deselectAll"
                            />
                            <ma-tooltip
                                v-else
                                :title="$t('addAllKeywords')"
                                placement="top"
                                type="primary"
                                overlay-class-name="ma-kw-suggestion-tooltip"
                            >
                                <ma-button
                                    icon="add"
                                    size="small"
                                    class="mx-auto"
                                    color="blue"
                                    variant="stroke"
                                    @click="keywordAll('paid')"
                                />
                            </ma-tooltip>
                        </template>
                        <template #default="{ record: row }">
                            <ma-tooltip
                                v-if="addedKeywordsMap[row.keyword]"
                                :title="$t('added')"
                                placement="right"
                                type="primary"
                                overlay-class-name="ma-kw-suggestion-tooltip"
                            >
                                <ma-button
                                    icon="check-line"
                                    size="small"
                                    class="mx-auto"
                                    color="blue"
                                    @click="deselect(row)"
                                />
                            </ma-tooltip>
                            <ma-button
                                v-else
                                icon="add"
                                size="small"
                                class="mx-auto"
                                color="blue"
                                variant="stroke"
                                @click="keywordSelected(row)"
                            />
                        </template>
                    </a-table-column>
                    <a-table-column
                        data-index="keyword"
                        :title="$t('columns.keyword')"
                        :sorter="true"
                        :sort-order="sortOrder['keyword']"
                        min-width="150"
                    />

                    <a-table-column
                        data-index="searchVolume"
                        min-width="150"
                        align="center"
                        :sorter="true"
                        :sort-order="sortOrder['popularity']"
                    >
                        <template #title>
                            <ma-table-header header-key="searchScore"/>
                        </template>
                    </a-table-column>
                </a-table>

                <ma-pagination
                    v-model:current="paidPage.current"
                    v-model:per-page="paidPage.size"
                    :page-size-options="[5, 10, 20]"
                    :total-items="paidPage.total"
                    size="small"
                    :limits="{ xs: 3, sm: 3, md: 3, lg: 3, default: 3 }"
                    show-less-items
                    class="m-4"
                />
            </a-spin>
            <ma-aso-tracked-keywords-table
                v-if="activeTab === TABS.ASO"
                :app-id="selectedApp?.trackId"
                :country-code="countryCode"
                :selected-group-keywords="selectedGroupKeywords"
                :added-keywords-map="addedKeywordsMap"
                class="mt-4 mx-4"
                @clear-filters="selectedGroupId = null"
                @add-keyword="keywordSelected"
                @remove-keywords="rows => $emit('remove-keywords', rows)"
                @add-all-keywords="keywords => keywordAll(TABS.ASO, keywords)"
            />
        </div>
    </a-card>
</template>

<script>
    import MaAppSearch from '@/components/AppSearch.vue';
    import MaTableHeader from '@/components/TableHeader.vue';
    import MaTodaysRank from '@/components/TodaysRank.vue';
    import MaAppInfo from '@/components/AppInfo.vue';
    import { dateToStr, DAY_IN_MS, generateFetchKey, SA_COUNTRIES, sortByKey } from '@/common/MaUtils.mjs';
    import { getASOReport } from '@/common/MaRequests/AsoReport';
    import { SearchOutlined } from '@ant-design/icons-vue';
    import {
        MaBadge,
        MaButton,
        MaCountrySelect, MaInput,
        MaPagination,
        MaSwitch,
        MaTooltip,
        MaTypography,
    } from '@mobileaction/action-kit';
    import { useLimitedAdsManager } from '@/composables/useLimitedAdsManager';
    import { REQUEST_DEMO_URL } from '@/common/Config/index';
    import MaProBadge from '@/components/MaProFeatureIndicators/MaProBadge.vue';
    import MaAsoTrackedKeywordsTable from '@/components/KeywordAddModal/MaASOTrackedKeywordsTable.vue';
    import MaKeywordGroupFilter from '@/components/KeywordAddModal/MaKeywordGroupFilter.vue';
    import { useASOTrackedKeywordsController } from '@/controllers/ASOTrackedKeywordsController';
    import { useKeywordAuctionController } from '@/controllers/KeywordAuctionController';
    import messages from '@/components/KeywordAddModal/KeywordSuggestions.i18n';

    const TABS = { PAID: 'paid', ORGANIC: 'organic', ASO: 'asoTracked' };

    export default {
        name: 'ma-keyword-suggestions',
        components: {
            MaInput,
            MaPagination,
            MaTooltip,
            MaKeywordGroupFilter,
            MaAsoTrackedKeywordsTable,
            MaCountrySelect,
            MaSwitch,
            MaProBadge,
            MaButton,
            MaTypography,
            MaBadge,
            MaAppSearch,
            MaTableHeader,
            MaTodaysRank,
            MaAppInfo,
            SearchOutlined,
        },
        props: {
            addedKeywords: { type: Array, required: false },
        },
        emits: ['add-all-keywords', 'add-keyword', 'remove-keywords'],
        setup() {
            const { userHasLimitedAccess } = useLimitedAdsManager();
            const ASOTrackedController = useASOTrackedKeywordsController();
            return {
                userHasLimitedAccess,
                ASOTrackedController,
            };
        },
        data() {
            return {
                selectedApp: this.$maLocalStorage.lastSearchedApp,
                countryCode: this.$maLocalStorage.countryCode,
                storeName: 'ios',
                organicKeywords: [],
                paidKeywords: [],
                searchOpened: false,
                activeTab: TABS.ORGANIC,
                searchKeyword: '',
                keywordAuctionController: useKeywordAuctionController(),
                organicTableSort: null,
                paidTableSort: null,
                loading: {
                    organic: false,
                    paid: false,
                },
                columnsRange: {
                    chance: { min: 0, max: 0 },
                    searchScore: { min: 0, max: 0 },
                    todaysRank: { min: 0, max: 0 },
                    yesterdaysRank: { min: 0, max: 0 },
                },
                paginationOptions: {
                    currentPage: 1,
                    pageSizes: [50, 100, 250, 500],
                    pageSize: 100,
                    total: 0,
                },
                organicPage: {
                    current: 1,
                    size: 10,
                },
                paidPage: {
                    current: 1,
                    size: 10,
                },
                TABS,
                lastOrganicFetchKey: null,
                lastPaidFetchKey: null,
                REQUEST_DEMO_URL,
                keywordGroups: [],
                loadingGroups: false,
                selectedGroupId: null,
            };
        },
        computed: {
            countries() {
                return SA_COUNTRIES.map(cc => ({ code: cc.alpha2, name: cc.name }));
            },
            sortOrder() {
                const sort = this.activeTab === TABS.ORGANIC
                    ? this.organicTableSort
                    : this.paidTableSort;
                const { field, order } = sort || {};
                return order
                    ? { [field]: order }
                    : {};
            },
            kwSuggestionHeader() {
                const MAX_LENGTH = 18;
                const appName = this.selectedApp?.name || '';
                return this.$t('keywordSuggestionsFrom', {
                    app: appName.length <= MAX_LENGTH ? appName : this.selectedApp.name.substring(0, MAX_LENGTH - 3) + '...',
                    country: this.countryCode,
                    type: this.$t(this.activeTab),
                });
            },
            filteredList() {
                let list = this.organicKeywords;

                // search keyword
                if (this.searchKeyword && this.searchKeyword.length) {
                    const filter = this.searchKeyword.toLowerCase();
                    list = list.filter(k => k.keyword.toLowerCase().indexOf(filter) > -1);
                }

                // sorting
                if (this.organicTableSort) {
                    list = sortByKey(list.slice(), this.organicTableSort.field);
                    if (this.organicTableSort.order === 'descend') {
                        list = list.slice().reverse();
                    }
                }

                const { currentPage, pageSize } = this.paginationOptions;
                const offset = (currentPage - 1) * pageSize;
                const limit = currentPage * pageSize;
                return list.slice(offset, limit);
            },
            organicList() {
                return this.filteredList.slice();
            },
            organicListPage() {
                // paginate
                const start = (this.organicPage.current - 1) * this.organicPage.size;
                const end = this.organicPage.current * this.organicPage.size;
                return this.filteredList.slice(start, end);
            },
            addedKeywordsMap() {
                return this.addedKeywords.reduce((m, k) => {
                    m[k.text] = true;
                    return m;
                }, {});
            },
            allAdded() {
                const keywords = this.activeTab === TABS.ORGANIC ? this.organicListPage : this.paidKeywords;
                return keywords.every((k) => {
                    return this.addedKeywordsMap[k.keyword];
                });
            },
            organicParams() {
                return {
                    storeName: this.storeName,
                    trackId: this.selectedApp?.trackId,
                    countryCode: this.countryCode,
                    device: 'iphone',
                };
            },
            keywordGroupParams() {
                return {
                    trackId: this.selectedApp?.trackId,
                    countryCode: this.countryCode,
                };
            },
            keywordGroupFetchKey() {
                return generateFetchKey(this.keywordGroupParams);
            },
            selectedGroupKeywords() {
                if (!this.selectedGroupId) {
                    return null;
                }
                return this.keywordGroups.find(kg => kg.groupId === this.selectedGroupId)?.keywords || null;
            },
            organicFetchKey() {
                return generateFetchKey(this.organicParams);
            },
            paidParams() {
                const startDate = dateToStr(new Date().getTime() - 7 * DAY_IN_MS); //7 days earlier
                const endDate = dateToStr(new Date().getTime() - 2 * DAY_IN_MS); //2 days earlier
                return {
                    trackId: this.selectedApp?.trackId,
                    region: this.countryCode,
                    startDate,
                    endDate,
                    pageSize: this.paidPage.size,
                    currentPage: this.paidPage.current - 1,
                    sortColumn: this.paidTableSort
                        ? this.paidTableSort.field
                        : '',
                    order: this.paidTableSort
                        ? (this.paidTableSort.order === 'ascend' ? 'ASC' : 'DESC')
                        : '',
                };
            },
            paidFetchKey() {
                return generateFetchKey(this.paidParams);
            },
        },
        methods: {
            handleTabChange(tab) {
                if (tab === TABS.PAID && this.userHasLimitedAccess) {
                    return;
                }
                this.activeTab = tab;
            },
            openSearch() {
                this.searchOpened = true;
                this.$nextTick(() => this.$refs.appSearch?.focus?.());
            },
            handleSelect(app) {
                this.searchOpened = false;
                this.$refs.appSearch?.clear?.();
                this.selectedApp = app;
            },
            organicSortChanged(sort) {
                if (!sort?.order) {
                    this.organicTableSort = null;
                    return;
                }
                this.organicTableSort = sort;
            },
            paidSortChanged({ field, order }) {
                if (!order) {
                    this.paidTableSort = null;
                    return;
                }
                this.paidTableSort = {
                    field: field === 'searchVolume' ? 'popularity' : field,
                    order,
                };
                this.fetchStatistics();
            },
            // Add current page keywords
            keywordAll(dataType = TABS.ORGANIC, keywords = null) {
                const keywordList = keywords || (dataType === TABS.PAID
                    ? this.paidKeywords
                    : this.organicListPage);
                const added = this.addedKeywords.map(k => k.text);
                const notAdded = keywordList.filter(k => !added.includes(k.keyword));
                this.$emit('add-all-keywords', notAdded);
            },
            deselectAll() {
                const keywordList = this.activeTab === TABS.PAID
                    ? this.paidKeywords
                    : this.organicListPage;
                this.$emit('remove-keywords', keywordList);
            },
            deselect(row) {
                this.$emit('remove-keywords', [row]);
            },
            keywordSelected(row) {
                this.$emit('add-keyword', row);
            },
            fetch() {
                if (this.activeTab === TABS.ORGANIC) {
                    this.fetchASOReport();
                } else {
                    this.fetchStatistics();
                }
            },
            fetchASOReport() {
                if (this.activeTab === TABS.PAID || this.lastOrganicFetchKey === this.organicFetchKey) {
                    return;
                }
                this.lastOrganicFetchKey = this.organicFetchKey;

                this.loading.organic = true;
                this.organicKeywords = [];
                getASOReport(this.organicParams)
                    .then((data) => {
                        if (!data.length) {
                            return;
                        }
                        data.forEach((d) => {
                            d.rank = d.todaysRank ? parseInt(d.todaysRank, 10) : 0;
                            d.change = d.todaysRank ? (d.yesterdaysRank - d.todaysRank) : 0;
                            d.searchScore = d.searchVolume;
                            this.minMaxFinder(d);
                        });
                        this.organicKeywords = data;
                    })
                    .catch((e) => {
                        this.$log.error('Failed to get organic keywords:', e);
                    })
                    .then(() => {
                        this.loading.organic = false;
                    });
            },
            minMaxFinder(row) {
                Object.keys(this.columnsRange).forEach((key) => {
                    if (row[key] < this.columnsRange[key].min) {
                        this.columnsRange[key].min = row[key];
                    }
                    if (row[key] > this.columnsRange[key].max) {
                        this.columnsRange[key].max = row[key];
                    }
                });
            },
            fetchStatistics() {
                // skip if this tab is not active
                if (this.activeTab === TABS.ORGANIC || this.lastPaidFetchKey === this.paidFetchKey) {
                    return;
                }
                this.lastPaidFetchKey = this.paidFetchKey;

                this.paidKeywords = [];
                this.loading.paid = true;
                this.keywordAuctionController.getAppStatistics(this.paidParams)
                    .then((data) => {
                        this.paidKeywords = (data.keywords || [])
                            .map((d) => {
                                d.searchVolume = d.info ? d.info.searchVolume : '-';
                                return d;
                            });
                        this.paidPage.total = data.totalElements;
                    })
                    .catch((err) => {
                        this.$log.error(err);

                        const errors = err.errors || [];
                        this.$message.error(this.$t('failedToGetStatistics') + ` ${ errors ? errors.join('/') : '?' }`);
                    })
                    .then(() => {
                        this.loading.paid = false;
                    });
            },
            async fetchMaKeywordGroups() {
                if (!this.selectedApp?.trackId) {
                    return;
                }
                this.loadingGroups = true;
                try {
                    this.keywordGroups = await this.ASOTrackedController.fetchASOTrackedKeywordGroups(this.keywordGroupParams);
                } catch (e) {
                    console.error('Failed to fetch Keyword Groups:', e);
                } finally {
                    this.loadingGroups = false;
                }
            },
        },
        mounted() {
            this.fetch();
            this.fetchMaKeywordGroups();
        },
        watch: {
            searchOpened() {
                if (!this.searchOpened) {
                    this.$refs.appSearch?.blur?.();
                }
            },
            activeTab() {
                this.fetch();
            },
            organicFetchKey() {
                this.fetchASOReport();
            },
            paidFetchKey() {
                this.fetchStatistics();
            },
            keywordGroupFetchKey() {
                this.fetchMaKeywordGroups();
            },
            filteredList: {
                deep: true,
                handler() {
                    const { currentPage, pageSize } = this.paginationOptions;
                    const start = (currentPage - 1) * pageSize;
                    this.paginationOptions.total = this.filteredList.length;
                    if (this.paginationOptions.total < start) {
                        this.paginationOptions.currentPage = 1;
                    }
                },
            },
        },
        i18n: {
            messages,
        },
    };
</script>

<style lang="less">
    @import '@/actionkit/MaBorderlessInput.less';

    .ma-keyword-suggestions {
        @apply mr-2;

        .ant-card-head {
            @apply py-3 px-2;
            .ant-card-head-wrapper, .ant-card-head-title {
                @apply p-0;
            }
        }
        .ant-card-body {
            @apply p-0;

            .ma-kw-suggestion-filters-section {
                @apply flex justify-between items-center border-b;

                .ma-open-search {
                    @apply flex items-center ~"pl-2.5";

                    .anticon {
                        @apply ml-2;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    .ma-selected-app-info {
                        .ma-app-icon {
                            margin: 0 10px;
                            width: 25px;
                            height: 25px;
                            border-radius: 3px;
                        }
                        .ma-app-name {
                            max-width: 120px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .ma-developer-name {
                            font-size: 0.8em;
                        }
                        div {
                            margin: 0;
                        }
                    }
                }
                .ma-app-search {
                    @apply p-2 w-full;
                }

                .ant-radio-group {
                    @apply px-2;
                }
            }

            .ma-kw-suggestion-add {
                @apply text-gray-300 hover:text-blue-500;
            }
            .ma-kw-suggestion-added {
                @apply text-green-500 border-green-500;
            }
        }

        //.ma-keyword-suggestions-table {
        //    .ant-table-thead {
        //        th.ant-table-cell {
        //            @apply bg-[#F6F8FA] text-gray-900 text-sm font-medium px-3 py-2 border-none;
        //            &:first-child {
        //                @apply rounded-l-md;
        //            }
        //            &:last-child {
        //                @apply rounded-r-md;
        //            }
        //            .ant-table-column-title {
        //                @apply text-sm;
        //            }
        //        }
        //    }
        //    .ant-table-tbody {
        //        td.ant-table-cell {
        //            @apply text-gray-900 text-sm font-medium !p-3 !border-none;
        //        }
        //    }
        //}
    }

    .ma-kw-suggestion-tooltip {
        z-index: 2050;
    }

</style>
