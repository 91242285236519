import { SegmentEvent } from '@/common/Segment';

const bannerEvents = route => ({
    // Verify Email Banner
    verifyEmailBannerResend: new SegmentEvent(
        'SA-Verify Email Banner-ResendEmail',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    verifyEmailBannerClose: new SegmentEvent(
        'SA-Verify Email Banner-Closed',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    // Trial Status Banner
    trialStatusBannerContact: new SegmentEvent(
        'SA-Trial Status Banner-ContactSales',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    trialStatusBannerClose: new SegmentEvent(
        'SA-Trial Status Banner-Closed',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    expiredIntegrationsBannerClose: new SegmentEvent(
        'SA-Expired Integrations Banner-Closed',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    expiredIntegrationsBannerConnect: new SegmentEvent(
        'SA-Expired Integrations Banner-Connect',
        [{ name: 'Page', default: route?.fullPath }]
    ),
});

export default bannerEvents;
