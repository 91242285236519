import { mapState, mapActions } from 'pinia';
import {
    assignLabel, addLabel,
    labelsOfEntity, removeLabelAssignment,
} from '@/common/MaRequests/MetadataLabel';
import { parseError } from '@/common/MaUtils.mjs';
import { CAMPAIGN_LABELS } from '@/common/DashboardUtils';
import { FILTER_LEVELS } from '@/common/FilteringUtils';
import { useLabelsStore } from '@/stores/Labels';


export default {
    data() {
        return {
            predefineColors: [
                '#ff4500',
                '#ff8c00',
                '#ffd700',
                '#90ee90',
                '#00ced1',
                '#1e90ff',
                '#c71585',
                '#DA0D0D',
                '#da7e0d',
                '#BF40FF',
            ],
        };
    },
    computed: {
        ...mapState(useLabelsStore, {
            labels: 'labels',
        }),
        mx_preDefinedLabels() {
            const data = [];
            const keys = Object.keys(CAMPAIGN_LABELS);
            let i = 1;
            for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                data.push({
                    id: -(i++),
                    level: FILTER_LEVELS.CAMPAIGN,
                    name: CAMPAIGN_LABELS[key],
                    description: this.$t(`common.categories.description.${key}`),
                    label: CAMPAIGN_LABELS[key],
                    color: this.predefineColors[j],
                    campaignType: key,
                    children: [],
                });
            }
            return data;
        },
    },
    methods: {
        ...mapActions(useLabelsStore, {
            getLabels: 'getLabels',
            updateLabels: 'updateLabels',
        }),
        mx_isPreDefinedLabel(data) {
            return data?.name?.startsWith('#') || data?.id < 0;
        },
        mx_getEntityId(row) {
            switch (this.requestLevel) {
                case FILTER_LEVELS.KEYWORD:
                    return row.keywordId;
                case FILTER_LEVELS.AD_GROUP:
                    return row.adGroupId;
                case FILTER_LEVELS.CAMPAIGN:
                    return row.campaignId;
                case 'AUTOMATION':
                    return row.id;
            }
        },
        mx_fetchEntityCategory(entity, originalEntity, callback) {
            return labelsOfEntity(this.requestLevel, entity.id)
                .then((data) => {
                    const category = data.filter(d => this.mx_isPreDefinedLabel(d))?.[0];
                    entity.category = category?.name;
                    if (originalEntity) {
                        originalEntity.category = category?.name;
                        originalEntity.categoryId = category?.id;
                    }
                    callback && callback();
                    return category;
                })
                .catch((error) => {
                    this.$log.error('Failed to fetch entity label for:', entity, error);
                });
        },
        mx_findLabel(category) {
            const preLabel = this.mx_preDefinedLabels.find(p => p.name === category);
            const label = this.labels.find(p => p.name === category);
            return { preLabel, label };
        },
        mx_updateEntityCategory(entity, originalEntity, isCampaign = true) {
            const prop = isCampaign === true ? 'campaignId' : 'adGroupId';
            this.chosenAssignmentData = [{ [prop]: entity.id || entity[prop] }];
            if (originalEntity.category) {
                if (entity.category === originalEntity.category) {
                    return;
                } else {
                    const { preLabel, label } = this.mx_findLabel(entity.category);
                    const orLabel = this.mx_findLabel(originalEntity.category);
                    const callback = (label || preLabel) ? () => this.mx_checkAndAssignLabel(label?.id || preLabel.id, 1) : null;
                    this.chosenAssignmentData[0][this.labelsProp] = [orLabel.label?.id || orLabel.preLabel?.id];
                    this.mx_removeAssignment(originalEntity.categoryId, 1, callback);
                }
            } else if (entity.category) {
                const { preLabel, label } = this.mx_findLabel(entity.category);
                if (label || preLabel) {
                    const orLabel = this.mx_findLabel(originalEntity.category);
                    this.chosenAssignmentData[0][this.labelsProp] = [orLabel.label?.id || orLabel.preLabel?.id];
                    this.mx_checkAndAssignLabel(label?.id || preLabel.id, 1);
                }
            }
        },
        mx_createLabelForEntity(entity, isCampaign = true) {
            if (!entity.category) {
                return;
            }
            const prop = isCampaign === true ? 'campaignId' : 'adGroupId';
            this.chosenAssignmentData = [{ [prop]: entity.id }];
            const preLabel = this.mx_preDefinedLabels.find(p => p.name === entity.category);
            const label = this.labels.find(p => p.name === entity.category);
            if (label || preLabel) {
                this.mx_checkAndAssignLabel(label?.id || preLabel.id, 1);
            }
        },
        mx_removeAssignment(labelId, size, callback) {
            const removableList = this.chosenAssignmentData.filter(c => c[this.labelsProp] && c[this.labelsProp].includes(labelId));
            if (!removableList?.length) {
                this.stateCount = this.stateCount + 1;
                this.checkCompleteState(size);
            }
            const data = Object.assign({}, this.labels.find(l => l?.id === labelId));
            data.entityList = [];
            const ids = [...new Set(removableList.map(a => this.mx_getEntityId(a)))];
            ids.forEach(d => data.entityList.push({ entityId: d, entityLevel: this.requestLevel }));
            removeLabelAssignment(labelId, data)
                .then(() => {
                    this.stateCount = this.stateCount + 1;
                    callback && callback();
                })
                .catch(({ errors, errorData }) => {
                    this.$log.error('Failed to remove-assignment label:', errors, errorData);
                })
                .then(() => {
                    this.checkCompleteState(size);
                });
        },
        mx_checkAndAssignLabel(labelId, size) {
            let preLabel;
            if (labelId < 0) {
                preLabel = this.mx_preDefinedLabels.find(p => p.id === labelId);
            }
            if (preLabel) {
                addLabel(preLabel)
                    .then((data) => {
                        const { labels } = this;
                        labels.push(data);
                        this.updateLabels(labels);
                        this.mx_assignLabel(data.id, size);
                    })
                    .catch((error) => {
                        this.loading = false;
                        const { displayedMessage } = parseError(error);
                        this.$log.error('Failed to create label:', error);
                        this.$message.error(this.$t('failedToCreateLabel', { msg: displayedMessage }));
                    });
            } else {
                this.mx_assignLabel(labelId, size);
            }
        },
        mx_assignLabel(labelId, size) {
            const assignableList = this.chosenAssignmentData.filter(c => !c[this.labelsProp]?.includes(labelId));
            if (!assignableList?.length) {
                this.stateCount = this.stateCount + 1;
                this.checkCompleteState(size);
            }
            const data = Object.assign({}, this.labels.find(l => l.id === labelId));
            data.entityList = [];
            const ids = [...new Set(assignableList.map(a => this.mx_getEntityId(a)))];
            ids.forEach(d => data.entityList.push({ entityId: d, entityLevel: this.requestLevel }));
            assignLabel(data, labelId)
                .then(() => {
                    this.stateCount = this.stateCount + 1;
                })
                .catch(({ errors, errorData }) => {
                    this.$log.error('Failed to assign label:', errors, errorData);
                })
                .then(() => {
                    this.checkCompleteState(size);
                });
        },
        // eslint-disable-next-line no-unused-vars
        checkCompleteState(size) {
            //Complete it on sub-class
        },
    },
    async mounted() {
        await this.getLabels();
    },
};
