import { computed, ref } from 'vue';
import { useGlobal, useMaStorage } from '@/composables/index';
import dayjs from '@/plugins/dayjs.mjs';
import bannerEvents from '@/plugins/segment/definitions/bannerEvents';
import { useRoute } from 'vue-router';
import { usePlanStore } from '@/stores/Plan';

export default function useTrialStatusBanner () {
    const app = useGlobal();
    const planStore = usePlanStore();
    const trialRemainingDays = computed(() => planStore.trialRemainingDays);
    const hasSearchAdsTrial = computed(() => planStore.hasSearchAdsTrial);

    const hasSearchAdsTrialLast7Days = computed(() => planStore.hasSearchAdsTrialLast7Days);
    const route = useRoute();

    const remainingBannerCloseTime = ref(useMaStorage('remainingBannerCloseTime', 0, sessionStorage));
    const isClosedWithinADay = computed(() => remainingBannerCloseTime.value
        && dayjs(remainingBannerCloseTime.value).diff(dayjs(), 'days') === 0);

    const showBanner = computed(() => hasSearchAdsTrialLast7Days.value
        && !isClosedWithinADay.value);

    const showIntercom = () => {
        const event = bannerEvents(route).trialStatusBannerContact;
        app.$segment.track(...event.toSegment());

        app.$maIntercom.show();
    };

    return {
        showIntercom,
        trialRemainingDays,
        hasSearchAdsTrial,
        showBanner,
    };
}
