import { computed } from 'vue';
import { useAccountStore } from '@/stores/Account';
import { storeToRefs } from 'pinia';

export const USER_ACCESS_TYPE = {
    LIMITED: 'LIMITED',
    ALL_ACCESS: 'ALL_ACCESS',
    NO_ACCESS: 'NO_ACCESS',
};

export const MEMBERSHIP_TYPE = {
    ADMIN: 'ADMIN',
    TEAM_MEMBER: 'TEAM_MEMBER',
};

export const useTeamMemberOptions = (t) => {
    const userAccessOptions = computed(() => {
        return Object.entries(USER_ACCESS_TYPE).map(([key, value]) => {
            return ({
                label: t(`userAccessType.${key}`),
                value,
            });
        });
    });

    const userRoleOptions = computed(() => {
        return Object.entries(MEMBERSHIP_TYPE).map(([key, value]) => {
            return {
                label: t(`membership.${key}`),
                value,
            };
        });
    });

    const accountStore = useAccountStore();
    const { orgList } = storeToRefs(accountStore);
    const campaignGroupOptions = computed(() => {
        return orgList.value.map(({ orgId, orgName }) => {
            return {
                label: orgName,
                value: orgId,
            };
        });
    });

    return {
        userAccessOptions,
        userRoleOptions,
        campaignGroupOptions,
    };
};
