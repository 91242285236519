import { getIntegrations } from '@/common/MaRequests/User';
import AuthorizationUtils from './AuthorizationUtils.mjs';
import dayjs from '@/plugins/dayjs.mjs';
import { mapActions, mapState } from 'pinia';
import { useAccountStore } from '@/stores/Account';

export default {
    mixins: [AuthorizationUtils],
    data() {
        return {
            fetchCompletedChecking: false,
        };
    },
    computed: {
        ...mapState(useAccountStore, ['integrations']),
        completedOrgs() {
            const { integrations } = this;
            return integrations && Object.keys(integrations)
                .flatMap(k => integrations[k])
                .filter(k => k.valid && this.isOrgCompleted(k));
        },
        notCompletedOrgs() {
            const { integrations } = this;
            return integrations && Object.keys(integrations)
                .flatMap(k => integrations[k])
                .filter(k => k.valid && !this.isOrgCompleted(k));
        },
        isOrgsUsable() {
            const comp = this.completedOrgs?.length || 0;
            const not = this.notCompletedOrgs?.length || 0;
            //If most of them is completed, dashboard is usable.
            return comp > not;
        },
        fetchCompleted() {
            return this.isOrgsUsable;
        },
    },
    methods: {
        ...mapActions(useAccountStore, ['updateIntegrations']),
        isOrgCompleted(org) {
            //Wait user at most 1 day.
            const diff = org.createDate ? dayjs().diff(dayjs(org.createDate), 'days') : 0;
            return diff > 0 || org.fetchCompleted;
        },
        checkFetchCompletedState() {
            this.fetchCompletedChecking = true;
            getIntegrations()
                .then((integrations) => {
                    this.updateIntegrations(integrations);
                    this.$nextTick(() => {
                        this.mx_fetchUserOrganizations();
                    });
                })
                .catch((e) => {
                    this.$log.error('Failed to get integrations:', e);
                })
                .then(() => {
                    this.fetchCompletedChecking = false;
                });
        },
    },
};
