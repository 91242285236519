import { SegmentEvent } from '@/common/Segment';
import { DASHBOARD_TABS } from '@/common/FilteringUtils';
import { toTitleCase } from '@/common/MaUtils.mjs';

export const contactSalesWallEvent = route => new SegmentEvent(
    'SA-Contact Sales',
    [{ name: 'Page', default: route?.fullPath }]
);
export const signInWithAppleWallEvent = route => new SegmentEvent(
    'SA-Sign in with ASA',
    [{ name: 'Page', default: route?.fullPath }]
);
export const requestDemoEvent = new SegmentEvent('SA-Request Demo');
export const contactSupportEvent = new SegmentEvent('SA-Contact Support');
export const addCreditCardEvent = new SegmentEvent('SA-Settings - Payment - Add Credit Card');
export const saMaSwitchEvent = new SegmentEvent('SA-MA Switch');
export const graderScheduleDemoEvent = new SegmentEvent('SA-Schedule Demo from Grader');
export const negativeKeywordUploadEvent = new SegmentEvent('SA-Ads Manager - Negative Keywords - Upload');
export const removeIntegrationEvent = new SegmentEvent('SA-Integrations - Remove Integration Button Clicked');
export const currencyViewSwitchEvent = new SegmentEvent('SA-Currency View Switch');
export const callLatestDataEvent = new SegmentEvent('SA-Ads Manager - Call Latest Data');
export const chartTypeSelectedEvent = new SegmentEvent('SA-Ads Manager - Chart Type Selected', [{ name: 'Chart Type', default: '' }]);
export const compareItemsEvent = new SegmentEvent('SA-Ads Manager - Compare Selected Items', [{ name: 'Level', default: '' }]);
export const keywordBidUpdateEvent = new SegmentEvent('SA-Ads Manager - Keyword Bid Updated', [{ name: 'Keyword Id', default: '' }]);
export const dailyBudgetUpdateEvent = new SegmentEvent('SA-Ads Manager - Daily Budget Updated', [{ name: 'Campaign Id', default: '' }]);
export const organicRankChartEvent = new SegmentEvent('SA-Ads Manager - Keyword - Organic Rank Graph');
export const calendarPresetSelection = new SegmentEvent(
    'SA-Ads Manager - Calender - Preset Range Selection',
    [
        {
            name: 'Preset Range',
            default: null,
        },
    ]
);
export const keywordAdvisorEvents = {
    motivationSelected: new SegmentEvent('SA-Keyword Advisor - Motivation Selection', [{ name: 'Options', default: [] }]),
    addExclusiveCompetitor: new SegmentEvent('SA-Keyword Advisor - Add Competitor from Exclusive Competitors'),
    removeExclusiveCompetitor: new SegmentEvent('SA-Keyword Advisor - Remove Competitor from Exclusive Competitors'),
    addSuggestedCompetitor: new SegmentEvent('SA-Keyword Advisor - Add Competitor from Suggested Competitors'),
    removeSuggestedCompetitor: new SegmentEvent('SA-Keyword Advisor - Remove Competitor from Suggested Competitors'),
    runAds: new SegmentEvent('SA-Keyword Advisor - Run Ads'),
    requestBeta: new SegmentEvent('SA-Keyword Advisor - Request to Join Beta Program'),
    submit: new SegmentEvent('SA-Keyword Advisor - Submit'),
    addKeywords: new SegmentEvent(
        'SA-Keyword Advisor Added Keywords',
        [
            {
                name: 'numberOfKeywords',
                default: 0,
            },
        ]),
};
export const apiIntegratedEvent = route => new SegmentEvent(
    'SA-API Integrated',
    [
        {
            name: 'Page',
            default: route?.fullPath,
        },
        {
            name: 'Place',
            default: '',
        },
        {
            name: 'Experiment',
            default: '',
        },
    ]);
export const apiIntegrationClickedEvent = route => new SegmentEvent(
    'SA-API Integration Clicked',
    [
        {
            name: 'Page',
            default: route?.fullPath,
        },
        {
            name: 'Place',
            default: '',
        },
        {
            name: 'Experiment',
            default: '',
        },
    ]);
export const onboardingEvent = {
    stepOneView: new SegmentEvent('SA-Onboarding-Step 1 View'),
    integrateApple: new SegmentEvent('SA-Onboarding-Step 1 Sign in with Apple'),
    continueStepOne: new SegmentEvent('SA-Onboarding-Step 1 Continue'),
    stepTwoView: new SegmentEvent('SA-Onboarding-Step 2 View'),
    integrateMMP: new SegmentEvent('SA-Onboarding-Step 2 Integrate'),
    completeMMPIntegration: new SegmentEvent('SA-Onboarding-Step 2 Complete Integration Switch'),
    continueStepTwo: new SegmentEvent('SA-Onboarding-Step 2 Continue'),
    exploreDashboard: new SegmentEvent('SA-Onboarding-Step 3 Explore your dashboard'),
};
export const quickCampaignEvent = {
    stepOneView: new SegmentEvent('SA-Quick Campaign Setup-Step 1 View'),
    stepOneNext: new SegmentEvent('SA-Quick Campaign Setup-Step 1 Next'),
    stepTwoView: new SegmentEvent('SA-Quick Campaign Setup-Step 2 View'),
    stepTwoNext: new SegmentEvent('SA-Quick Campaign Setup-Step 2 Next'),
    stepThreeView: new SegmentEvent('SA-Quick Campaign Setup-Step 3 View'),
    stepThreeNext: new SegmentEvent('SA-Quick Campaign Setup-Step 3 Next'),
    stepFourView: new SegmentEvent('SA-Quick Campaign Setup-Step 4 View'),
    stepFourNext: new SegmentEvent('SA-Quick Campaign Setup-Step 4 Create Campaign'),
    addExclusiveCompetitor: new SegmentEvent('SA-Quick Campaign Add Exclusive Competitor'),
    removeExclusiveCompetitor: new SegmentEvent('SA-Quick Campaign Remove Exclusive Competitor'),
    addSuggestedCompetitor: new SegmentEvent('SA-Quick Campaign Add Suggested Competitor'),
    removeSuggestedCompetitor: new SegmentEvent('SA-Quick Campaign Remove Suggested Competitor'),
    openAdGroupSettings: new SegmentEvent('SA-Quick Campaign Open Ad Group Settings'),
};
export const campaignForecastingEvent = {
    betaRequest: new SegmentEvent('SA-Campaign Forecasting - Beta Request'),
    estimate: new SegmentEvent('SA-Campaign Forecasting - Estimate'),
    updateDailyBudget: new SegmentEvent('SA-Campaign Forecasting - Update Daily Budget'),
    updateDailyBudgetSuccessful: new SegmentEvent('SA-Campaign Forecasting - Updated Daily Budget'),
    updateDailyBudgetCancelled: new SegmentEvent('SA-Campaign Forecasting - Cancelled update Daily Budget'),
    lowConfidence: new SegmentEvent('SA-Campaign Forecasting - Low Confidence'),
    cancelledLowConfidence: new SegmentEvent('SA-Campaign Forecasting - Cancelled Low Confidence'),
    notEnoughData: new SegmentEvent('SA-Campaign Forecasting - Not Enough Data'),
    cancelledNotEnoughData: new SegmentEvent('SA-Campaign Forecasting - Cancelled Not Enough Data'),
    contactUs: new SegmentEvent('SA-Campaign Forecasting - Contact Us'),
    estimationGiven: new SegmentEvent('SA-Campaign Forecasting - Gives estimation'),
};
export const adsManagerEvent = route => ({
    pause: (level) => {
        return new SegmentEvent(`SA-Ads Manager-${level}-Pause`, [
            {
                name: 'Placement',
                default: '',
            },
            {
                name: `${level} Id`,
                default: '',
            },
        ]);
    },
    activate: (level) => {
        const levelAsTitleCase = toTitleCase(level);
        return new SegmentEvent(`SA-Ads Manager-${levelAsTitleCase}-Activate`, [
            {
                name: 'Placement',
                default: '',
            },
            {
                name: `${levelAsTitleCase} Id`,
                default: '',
            },
        ]);
    },
    download: new SegmentEvent(
        'SA-Ads Manager-Download',
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
            {
                name: 'Reporting Type',
                default: '',
            },
            {
                name: 'Download Item',
                default: '',
            },
        ]),
    label: new SegmentEvent(
        'SA-Ads Manager-Label',
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
        ]),
    editColumns: new SegmentEvent(
        'SA-Ads Manager-Edit Columns',
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
        ]),
    editColumnsApply: new SegmentEvent(
        'SA-Ads Manager-Edit Columns-Apply',
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
            {
                name: 'Column Name',
                default: [],
            },
        ]),
    goalSelection: new SegmentEvent(
        'SA-Ads Manager - Goal Selection Filter',
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
        ]),
    goalCreation: new SegmentEvent(
        'SA-Ads Manager - Goal Creation Filter',
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
        ]),
    updateKeywordBid: new SegmentEvent('SA-Ads Manager - Update Keyword Bid',
        [{
            name: 'Option',
        }]),
});
export const accountsEvent = {
    create: new SegmentEvent('SA-Ads Manager-Accounts-Create'),
};
export const campaignsEvent = {
    create: new SegmentEvent('SA-Ads Manager-Create Campaign'),
    delete: new SegmentEvent('SA-Ads Manager-Delete Campaign'),
    start: new SegmentEvent(
        'SA-Ads Manager-Start Campaign',
        [
            {
                name: 'App',
                default: '',
            },
            {
                name: 'Ad Chanel',
                default: '',
            },
            {
                name: 'Storefront',
                default: [],
            },
            {
                name: 'Campaign Name',
                default: '',
            },
            {
                name: 'Daily Budget',
                default: 0,
            },
            {
                name: 'Ad Group Name',
                default: '',
            },
            {
                name: 'Default Max CPT',
                default: 0,
            },
            {
                name: 'Campaign Type',
                default: '',
            },
            {
                name: 'Devices',
                default: '',
            },
            {
                name: 'Start Time',
                default: Date.now(),
            },
            {
                name: 'End Time',
                default: Date.now(),
            },
            {
                name: 'CPA Cap',
                default: 0,
            },
            {
                name: 'Search Match',
                default: false,
            },
            {
                name: 'Customer Types',
                default: '',
            },
            {
                name: 'Age Range',
                default: false,
            },
            {
                name: 'Gender',
                default: '',
            },
            {
                name: 'Ad Creative',
                default: [],
            },
        ]),
    duplicateStart: new SegmentEvent('SA-Ads Manager-Campaign Duplication-Start'),
    duplicateEnd: new SegmentEvent(
        'SA-Ads Manager-Campaign Duplication-End',
        [
            { name: 'Campaign Name', default: '' },
            { name: 'Storefront', default: [] },
            { name: 'Schedule', default: 'Set Pause' },
            { name: 'Start Date', default: null },
        ]),
};
export const adGroupsEvent = {
    create: new SegmentEvent('SA-Ads Manager-Ad Groups-Create'),
    delete: new SegmentEvent('SA-Ads Manager-Ad Groups-Delete'),
    label: new SegmentEvent('SA-Ads Manager-Ad Groups-Label'),
};
export const keywordsEvent = {
    add: new SegmentEvent('SA-Ads Manager-Keyword-Add Keywords'),
    upload: new SegmentEvent('SA-Ads Manager-Keyword-Upload'),
    bidHistory: new SegmentEvent('SA-Ads Manager-Keyword-Bid History'),
};
export const adsEvent = {
    create: new SegmentEvent('SA-Ads Manager-Ads-Create Ad'),
    createSave: new SegmentEvent('SA-Ads Manager-Ads-Create Ad-Save'),
};
export const negativeKeywordsEvent = {
    add: new SegmentEvent('SA-Ads Manager-Negative Keywords-Add Negative Keywords'),
    delete: new SegmentEvent('SA-Ads Manager-Negative Keywords-Delete Negative Keywords'),
};
export const funnelsEvent = {
    download: new SegmentEvent('SA-Funnels-Download'),
    downloadAllData: new SegmentEvent('SA-Funnels-All Data Download'),
    createFunnelFromDropdown: new SegmentEvent(
        'SA-Funnels - Create Funnel From Dropdown',
        [
            {
                name: 'Level',
                default: '',
            },
        ]
    ),
    segmentByDropdown: new SegmentEvent('SA-Funnels - Segment by Dropdown',
        [
            {
                name: 'Level',
                default: '',
            },
        ]
    ),
};
export const optimizationsEvent = {
    recommendations: {
        view: new SegmentEvent('SA-Recommendations-View'),
        apply: new SegmentEvent('SA-Recommendations-View-Apply'),
    },
};
export const keywordHuntEvent = {
    download: new SegmentEvent('SA-Organic Keyword Hunt-Download'),
    runAdsForSingleKW: new SegmentEvent('SA-Organic Keyword Hunt-Run Ads for kw'),
    runAdsForAllKW: new SegmentEvent('SA-Organic Keyword Hunt-Run Ads for all kws'),
};
export const goalsEvent = {
    create: new SegmentEvent(
        'SA-Goals-Create Goal',
        [
            {
                name: 'Goal Category',
                default: [],
            },
            {
                name: 'App',
                default: [],
            },
        ]),
};
export const integrationEvent = {
    signInWithApple: new SegmentEvent('SA-Integration-Sign in with Apple'),
};
export const smartBiddingEvent = {
    createNew: new SegmentEvent(
        'SA-Smart Bidding-Create New',
        [
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    levelSelection: new SegmentEvent(
        'SA-Smart Bidding-Level Selection',
        [
            {
                name: 'Level',
                default: 'Campaign Based',
            },
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    strategySelection: new SegmentEvent(
        'SA-Smart Bidding-Strategy Selection',
        [
            {
                name: 'Strategy',
                default: 'Optimization',
            },
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    created: new SegmentEvent(
        'SA-Smart Bidding-Created',
        [
            {
                name: 'Level',
                default: 'Campaign Based',
            },
            {
                name: 'Strategy',
                default: 'Optimization',
            },
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    editClicked: new SegmentEvent(
        'SA-Smart Bidding-Target Value Edit Clicked',
        [
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    edited: new SegmentEvent(
        'SA-Smart Bidding-Target Value Edited',
        [
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    pause: new SegmentEvent(
        'SA-Smart Bidding-Paused Clicked',
        [
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    run: new SegmentEvent(
        'SA-Smart Bidding-Run Clicked',
        [
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    stop: new SegmentEvent(
        'SA-Smart Bidding-Stop Clicked',
        [
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    logs: new SegmentEvent(
        'SA-Smart Bidding-Logs',
        [
            {
                name: 'Tab',
                default: 'CPA',
            },
        ]),
    contactUs: new SegmentEvent(
        'SA-Smart Bidding - Contact us',
        [
            {
                name: 'Tab',
                default: 'CPI',
            },
        ]
    ),
    joinBeta: new SegmentEvent(
        'SA-Smart Bidding - Beta Request',
        [
            {
                name: 'Tab',
                default: 'CPI',
            },
        ]
    ),
};

export const sidebarEvent = {
    cppIntelligenceClicked: new SegmentEvent('SA- CPP Intelligence Clicked'),
};
export const authEvent = {
    login: new SegmentEvent(
        'SA-Login',
        [
            {
                name: 'UserId',
                default: null,
            },
            {
                name: 'LoginProduct',
                default: '',
            },
            {
                name: 'LoginBy',
                default: '',
            },
            {
                name: 'Place',
                default: null,
            },
        ]),
    signup: new SegmentEvent(
        'SA-Signup',
        [
            {
                name: 'UserId',
                default: null,
            },
            {
                name: 'SignupProduct',
                default: '',
            },
            {
                name: 'SignupBy',
                default: '',
            },
            {
                name: 'Place',
                default: null,
            },
        ]),
    pageView: new SegmentEvent(
        'SA-Dashboard Page View',
        [
            {
                name: 'Page',
                default: '',
            },
            {
                name: 'Access',
                default: '',
            },
        ]),
};

export const accessPropertyOption = {
    GRANTED: 'Granted',
    DENIED: 'Denied',
};

export const learnButtonEvent = {
    click: new SegmentEvent('SA-Learn Button Click', [
        {
            name: 'Page',
            default: '',
        },
    ]),
};

export const filterEvent = level => ({
    apply: (filterName, filterValue, operator, isSavedFilter = false) => new SegmentEvent(`SA-${level}-Filter Applied`, [
        {
            name: filterName,
            value: filterValue,
        },
        {
            name: 'Operator',
            value: operator,
        },
        {
            name: 'Metric',
            value: filterName,
        },
        {
            name: 'Is Saved Filter',
            value: isSavedFilter,
        },
    ]),
});

export const strategiesColumnEvents = {
    automationPopupOpen: (route, level) => new SegmentEvent(
        `SA-Ads Manager - ${level} AU Label clicked`,
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
        ]
    ),
    automationRuleOpen: (route, level) => new SegmentEvent(
        `SA-Ads Manager - ${level} AU Label rule name clicked`,
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
        ]
    ),
    smartBiddingPopupOpen: (route, level) => new SegmentEvent(
        `SA-Ads Manager - ${level} SB Label clicked`,
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
        ]
    ),
    smartBiddingRuleOpen: (route, level) => new SegmentEvent(
        `SA-Ads Manager - ${level} SB Label rule name clicked`,
        [
            {
                name: 'Page',
                default: route?.fullPath,
            },
        ]
    ),
};

const TAB_NAMES = {
    [DASHBOARD_TABS.ACCOUNTS]: 'Accounts',
    [DASHBOARD_TABS.APPS]: 'Apps',
    [DASHBOARD_TABS.CAMPAIGNS]: 'Campaigns',
    [DASHBOARD_TABS.AD_GROUPS]: 'Ad Groups',
    [DASHBOARD_TABS.KEYWORDS]: 'Keywords',
    [DASHBOARD_TABS.SEARCH_TERMS]: 'Search Terms',
    [DASHBOARD_TABS.ADS]: 'Ads',
    [DASHBOARD_TABS.NEGATIVE_KEYWORDS]: 'Negative Keywords',
};

export const getAttributedTimeSwitchEvent = (value, activeTab) => {
    const valueLabelMap = {
        EVENT_TIME: 'Event time',
        INSTALL_TIME: 'Install time',
    };
    return new SegmentEvent(`SA-Ads Manager ${TAB_NAMES[activeTab]} Level - Date Picker - ${valueLabelMap[value]} selected`);
};

export const overviewPageEvents = {
    filterSelection: new SegmentEvent(
        'SA-Overview - Filter Selection',
        [
            {
                name: 'Metric',
                default: '',
            },
        ]
    ),
    genderSelection: new SegmentEvent(
        'SA-Overview - Gender Filter Dropdown',
        [
            {
                name: 'Metric',
                default: '',
            },
        ]
    ),
    deviceSelection: new SegmentEvent(
        'SA-Overview - Device Filter Dropdown',
        [
            {
                name: 'Metric',
                default: '',
            },
        ]
    ),
    mapSelection: new SegmentEvent(
        'SA-Overview - Map Filter Dropdown',
        [
            {
                name: 'Metric',
                default: '',
            },
        ]
    ),
    top10KeywordsDropdown: new SegmentEvent(
        'SA-Overview - Top 10 Keywords Filter Dropdown',
        [
            {
                name: 'Metric',
                default: '',
            },
        ]
    ),
    top10AdgroupsDropdown: new SegmentEvent(
        'SA-Overview - Top 10 Ad Groups Filter Dropdown',
        [
            {
                name: 'Metric',
                default: '',
            },
        ]
    ),
    viewAuctionInsights: new SegmentEvent('SA-Overview - Go to Auction Insights '),
    viewAllKeywords: new SegmentEvent('SA-Overview - Go to All Keywords'),
    viewAllAdGroups: new SegmentEvent('SA-Overview - Go to All Ad Groups'),
    addCompetitors: new SegmentEvent('SA-Overview - Add your Competitors'),
    requestGraderReport: new SegmentEvent('SA-Overview - Request new grader report'),
    viewGraderLogs: new SegmentEvent('SA-Overview - See full grade logs'),
};

export const sidebarEvents = {
    competitorsIconClick: new SegmentEvent('SA-Competitors Icon'),
    settingsIconClick: (route) => {
        return new SegmentEvent('SA-Settings', [
            {
                name: 'Source Tab',
                default: route?.name,
            },
        ]);
    },
};


export const competitorEvents = {
    addCompetitor: new SegmentEvent('SA-Competitors - Add Competitor'),
};

export const adsManagerLiteEvents = {
    viewAdsManagerLite: new SegmentEvent('SA-Ads Manager Lite'),
    downloadCSV: new SegmentEvent('SA-Ads Manager Lite - Download CSV'),
    showChart: new SegmentEvent('SA-Ads Manager Lite - Show Chart'),
};

export const customColumnEvents = {
    createCustomColumnBtn: new SegmentEvent('SA-Ads Manager - Create Custom Column'),
    defaultCustomColumn: new SegmentEvent('SA-Ads Manager - Default Custom Column '),
    createDefaultBtn: new SegmentEvent('SA-Ads Manager - Default Custom Column Create'),
    goalCustomColumn: new SegmentEvent('SA-Ads Manager - Goal-based Custom Column'),
    createGoalBtn: new SegmentEvent('SA-Ads Manager - Goal-based Custom Column Create'),
    categoryCustomColumn: new SegmentEvent('SA-Ads Manager - Category-based Custom Column '),
    createCategoryBtn: new SegmentEvent('SA-Ads Manager - Category-based Column Create'),
};

export const compareDatepickerEvents = {
    compareEnabled: new SegmentEvent('SA-Ads Manager - Compare Enabled',
        [
            {
                name: 'Level',
            },
        ]),
    compareDisabled: new SegmentEvent('SA-Ads Manager - Compare Disabled', [
        {
            name: 'Level',
        },
    ]),
    comparePreviousPeriod: new SegmentEvent('SA-Ads Manager - Compare-Previous Period',
        [
            {
                name: 'Level',
            },
        ]),
    comparePreviousYear: new SegmentEvent('SA-Ads Manager - Compare-Previous Year',
        [
            {
                name: 'Level',
            },
        ]),
    compareCustom: new SegmentEvent('SA-Ads Manager - Compare-Custom',
        [
            {
                name: 'Level',
            },
        ]),
};

export const attributionsEvents = {
    addNew: new SegmentEvent('SA-Attribution-Add New'),
    userInformSwitch: new SegmentEvent('SA-Attribution-Switch'),
    confirm: new SegmentEvent('SA-Attribution-Confirm'),
    threeDots: new SegmentEvent('SA-Attribution-3 dots icon'),
    inAppEvents: new SegmentEvent('SA-Attribution-In App Events'),
    createFirstGoal: new SegmentEvent('SA-Attribution-Create Your First Goal'), // this doesn't exist on the design
    createGoal: new SegmentEvent('SA-Attribution-Create Goal'),
    createGoalConfirm: new SegmentEvent('SA-Attribution-Create'),
    removeAttribution: new SegmentEvent('SA-Attribution-Remove'),
};

export const quickAutomationEvents = {
    contactSales: new SegmentEvent('SA-Quick Automations - Contact Sales'),
    createCampaign: new SegmentEvent('SA-Quick Automations - Create Campaign'),
    startAutomation: new SegmentEvent('SA-Quick Automations - Start Automations'),
    automationStarted: new SegmentEvent('SA-Quick Automations - Automations started'),
    addCampaignType: new SegmentEvent('SA-Quick Automations - Add Campaign Type'),
    campaignSelection: new SegmentEvent('SA-Quick Automations - Campaign selection'),
    continueWithMissing: new SegmentEvent('SA-Quick Automations - Continue with Missing'),
    returnToUpdate: new SegmentEvent('SA-Quick Automations - Return to Update'),
};

export const budgetAllocationEvents = {
    createAllocation: new SegmentEvent('SA-Budget Allocation - Create Clicked'),
    created: new SegmentEvent('SA-Budget Allocation - Created', [
        {
            name: 'Type',
            default: 'Daily',
        },
        {
            name: 'Daily Budget',
            default: 0,
        },
    ]),
    pauseAllocation: new SegmentEvent('SA-Budget Allocation - Pause Clicked'),
    pauseConfirm: new SegmentEvent('SA-Budget Allocation - Pausing Budget Allocation'),
    deleteAllocation: new SegmentEvent('SA-Budget Allocation - Delete Clicked'),
    deleteConfirm: new SegmentEvent('SA-Budget Allocation - Deleting Budget Allocation'),
    startAllocation: new SegmentEvent('SA-Budget Allocation - Start Budget Allocation'),
    updateBudget: new SegmentEvent('SA-Budget Allocation - Update Daily Budget'),
    betaRequest: new SegmentEvent('SA-Budget Allocation - Beta Request'),
    contactSales: new SegmentEvent('SA-Budget Allocation - Contact Sales'),
    showChart: new SegmentEvent('SA-Budget Allocation - “>” Icon Clicked'),
};
