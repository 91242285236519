import { ref, watch, reactive, onMounted } from 'vue';
import { useMaStorage } from '@/composables';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '@/stores/Account';
import dayjs from '@/plugins/dayjs.mjs';
import { FORECAST_STATUS } from './useMetricForecast';
import { useReportController } from '@/controllers/ReportController';

export const METADATA_SELECTOR_TYPES = {
    accounts: 'accounts',
    campaigns: 'campaigns',
};

export const FIELDS = {
    ACCOUNT: 'ACCOUNT',
    CAMPAIGN: 'CAMPAIGN',
    DAILY_BUDGET: 'DAILY_BUDGET',
    STOREFRONT: 'STOREFRONT',
};

export default function useCampaignForecastForm() {
    const accountStore = useAccountStore();
    const filtersController = useReportController();

    const selectedAccount = ref({});
    const selectedCampaign = ref({});

    const accountPreFilter = ref([]);
    const preFilterStorefronts = ref([]);

    const selectedStorefront = ref(useMaStorage('countryCode', 'US').value);
    const selectedMetadataSelectorType = ref(null);
    const newDailyBudget = ref(undefined);

    const disabledFields = reactive({
        [FIELDS.CAMPAIGN]: true,
        [FIELDS.STOREFRONT]: true,
        [FIELDS.DAILY_BUDGET]: true,
        ESTIMATE: true,
    });

    const closeMetadataSelector = () => {
        selectedMetadataSelectorType.value = null;
    };
    const openMetadataSelector = (type) => {
        selectedMetadataSelectorType.value = METADATA_SELECTOR_TYPES[type];
    };

    const campaignForecastingStorage = useMaStorage(
        'campaignForecastingRequest',
        { status: null }
    );

    const fillAccountRelatedData = (accountId) => {
        selectedAccount.value = storeToRefs(accountStore).orgIdMapping.value[accountId];
        campaignForecastingStorage.value = { ...campaignForecastingStorage.value, accountId: accountId };
    };

    const fillCampaignRelatedData = async (campaignId, accountId) => {
        campaignForecastingStorage.value = { ...campaignForecastingStorage.value, campaignId: campaignId };
        const request = {
            filterFields: [
                {
                    column: 'campaignId',
                    value: `${campaignId}`,
                    type: 'NUMBER',
                    compareType: 'IN',
                    category: 'METADATA',
                },
            ],
            orgIdList: [
                `${accountId}`,
            ],
        };
        selectedCampaign.value = (await filtersController.fetchTableData({
            data: request,
            requestLevel: 'CAMPAIGN',
            start: dayjs().add(-30, 'days').format('YYYY-MM-DD'),
            end: dayjs().format('YYYY-MM-DD'),
        }))[0].tableData[0];
        preFilterStorefronts.value = selectedCampaign.value.countries;
        selectedStorefront.value = selectedCampaign.value.countries[0];

    };

    watch(selectedAccount, (val) => {
        if (val) {
            accountPreFilter.value.push(val);
        }
    });

    watch(newDailyBudget, async (val) => {
        campaignForecastingStorage.value = { ...campaignForecastingStorage.value, status: null };
        if (Number(val) > 0) {
            disabledFields.STOREFRONT = false;
        }
    });

    watch(
        [selectedStorefront, preFilterStorefronts, newDailyBudget],
        async ([selectedStorefrontNewVal, preFilterStorefrontsNewVal, newDailyBudgetNewVal]) => {
            if (
                selectedStorefrontNewVal &&
                preFilterStorefrontsNewVal.length > 0 &&
                newDailyBudgetNewVal &&
                Number(newDailyBudgetNewVal) > 0
            ) {
                disabledFields.ESTIMATE = false;
            } else {
                disabledFields.ESTIMATE = true;
            }
        }
    );

    const chooseData = async (selectedData) => {
        if (selectedMetadataSelectorType.value === METADATA_SELECTOR_TYPES.campaigns) {
            resetData([FIELDS.ACCOUNT, FIELDS.CAMPAIGN]);
            await fillCampaignRelatedData(selectedData, selectedAccount.value.orgId);
            disabledFields.DAILY_BUDGET = false;
        } else if (selectedMetadataSelectorType.value === METADATA_SELECTOR_TYPES.accounts) {
            resetData();
            fillAccountRelatedData(selectedData);
            disabledFields.CAMPAIGN = false;
        }
    };


    const resetData = (keepFields = []) => {
        if (
            keepFields.length > 0
            || Object.values(FIELDS).every(field => keepFields.includes(field))
        ) {
            if (!keepFields.includes(FIELDS.ACCOUNT)) {
                selectedAccount.value = {};
                accountPreFilter.value = [];
                // eslint-disable-next-line no-unused-vars
                const { accountId, ...rest } = campaignForecastingStorage.value;
                campaignForecastingStorage.value = rest;
            }
            if (!keepFields.includes(FIELDS.CAMPAIGN)) {
                selectedCampaign.value = {};
                preFilterStorefronts.value = [];
                // eslint-disable-next-line no-unused-vars
                const { campaignId, ...rest } = campaignForecastingStorage.value;
                campaignForecastingStorage.value = rest;
            }
            if (!keepFields.includes(FIELDS.DAILY_BUDGET)) {
                newDailyBudget.value = undefined;
                // eslint-disable-next-line no-unused-vars
                const { dailyBudget, ...rest } = campaignForecastingStorage.value;
                campaignForecastingStorage.value = rest;
            }
            if (!keepFields.includes(FIELDS.STOREFRONT)) {
                selectedStorefront.value = 'US';
                // eslint-disable-next-line no-unused-vars
                const { countryCode, ...rest } = campaignForecastingStorage.value;
                campaignForecastingStorage.value = { ...rest, status: null };
            }
        } else {
            selectedAccount.value = {};
            selectedCampaign.value = {};
            accountPreFilter.value = [];
            selectedStorefront.value = 'US';
            preFilterStorefronts.value = [];
            newDailyBudget.value = undefined;
            campaignForecastingStorage.value = { status: null };
        }
        for (let field in disabledFields) {
            if (!keepFields.includes(field)) {
                disabledFields[field] = true;
            } else {
                disabledFields[field] = false;
            }
        }
    };

    watch(campaignForecastingStorage, (newVal, oldVal) => {
        if (newVal.status !== oldVal.status && newVal.status === null) {
            resetData([FIELDS.ACCOUNT, FIELDS.CAMPAIGN, FIELDS.DAILY_BUDGET]);
        }
    });

    onMounted(async () => {
        if (campaignForecastingStorage.value.status === FORECAST_STATUS.PENDING) {
            newDailyBudget.value = campaignForecastingStorage.value.dailyBudget;
            fillAccountRelatedData(campaignForecastingStorage.value.accountId);
            if (campaignForecastingStorage.value.accountId) {
                await fillCampaignRelatedData(campaignForecastingStorage.value.campaignId, selectedAccount.value.orgId);
            }
        }
    });

    const bidUpdated = ref(false);

    watch(bidUpdated, (val) => {
        if (val) {
            fillCampaignRelatedData(campaignForecastingStorage.value.campaignId, campaignForecastingStorage.value.accountId);
            bidUpdated.value = false;
        }
    });

    return {
        newDailyBudget,
        selectedStorefront,
        selectedAccount,
        selectedCampaign,
        accountPreFilter,
        preFilterStorefronts,
        selectedMetadataSelectorType,
        disabledFields,
        bidUpdated,
        closeMetadataSelector,
        openMetadataSelector,
        chooseData,
        resetData,
    };
}
