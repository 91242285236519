<template>
    <ma-select
        :value="timeZone"
        :options="timeZoneList"
        size="small"
        class="!pl-0"
        :disabled="timeZoneDisabled"
        :get-popup-container="trigger => trigger.parentElement"
        :dropdown-match-select-width="false"
        dropdown-class-name="!z-[1000]"
        :loading="loading"
        @change="val => emit('update:timeZone', val)"
    />
</template>

<script setup>
    import messages from '@/components/Topbar/Topbar.i18n';
    import { MaSelect } from '@mobileaction/action-kit';

    const emit = defineEmits(['update:timeZone']);

    defineOptions({
        i18n: { messages },
    });

    defineProps({
        timeZoneList: { type: Array, required: true },
        timeZone: { type: String, default: '' },
        timeZoneDisabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    });
</script>
