import { SegmentEvent } from '@/common/Segment';
import { useGlobal } from '@/composables';
import { useRoute } from 'vue-router';

// Keyword Auction Insights Events
export const kaiEvent = {
    runAdsForAllKW: new SegmentEvent('SA-Keyword Auction Insights-Run Ads for all kws',[{ name: 'Tab', default: '' }]),
    runAdsForSingleKW: new SegmentEvent('SA-Keyword Auction Insights-Run Ads for kw',[{ name: 'Tab', default: '' }]),
    addToKeywords: new SegmentEvent('SA-KAI-Add to keywords'),
    addToNegativeKeywords: new SegmentEvent('SA-KAI-Add to negative keywords'),
    storefrontFilterApplied: new SegmentEvent('SA-KAI-Storefront Filter Applied',[{ name: 'Tab', default: '' }, { name: 'Storefront', default: '' }]),
    downloadCsv: new SegmentEvent('SA-KAI-Download Csv'),
    sbaAdsRun: new SegmentEvent('SA-KAI-Search by App-Ads Run'),
    sbaImpressionFilterApplied: new SegmentEvent('SA-KAI-Search by App-Impression Share Filter Applied'),
    sbaPopularityFilterApplied: new SegmentEvent('SA-KAI-Search by App-Popularity Filter Applied'),
    sbaKeywordFilterApplied: new SegmentEvent('SA-KAI-Search by App-Keyword Filter Applied'),
    taCategoryFilterApplied: new SegmentEvent('SA-KAI-Top Advertisers-Category Filter Applied',[{ name: 'Category', default: '' }]),
    kgAdsRun: new SegmentEvent('SA-KAI-Keyword Gap-Ads Run'),
    kgPageView: new SegmentEvent('SA-KAI-Keyword Gap-Page View'),
    kgMutualKeywords: new SegmentEvent('SA-KAI-Keyword Gap-Mutual Keywords'),
    kgUniqueKeyword: new SegmentEvent('SA-KAI-Keyword Gap-Unique Keyword'),
    kgImpressionFilterApplied: new SegmentEvent('SA-KAI-Keyword Gap-Impression Share Filter Applied'),
    kgPopularityFilterApplied: new SegmentEvent('SA-KAI-Keyword Gap-Popularity Filter Applied'),
    kgKeywordFilterApplied: new SegmentEvent('SA-KAI-Keyword Gap-Keyword Filter Applied'),
};

export const useKeywordAuctionInsightEvents = () => {
    const { $segment } = useGlobal();
    const route = useRoute();

    const trackRunAdsForAllKw = (tab) => {
        kaiEvent.runAdsForAllKW.setPropertyValue('Tab', tab);
        $segment.track(...kaiEvent.runAdsForAllKW.toSegment());
    };
    const trackRunAdsForSingleKw = (tab) => {
        kaiEvent.runAdsForSingleKW.setPropertyValue('Tab', tab);
        $segment.track(...kaiEvent.runAdsForSingleKW.toSegment());
    };
    const trackAddToKeywords = () => $segment.track(...kaiEvent.addToKeywords.toSegment());
    const trackAddToNegativeKeywords = () => $segment.track(...kaiEvent.addToNegativeKeywords.toSegment());
    const trackStorefrontFilterApplied = (storefront, tab) => {
        kaiEvent.storefrontFilterApplied.setPropertyValue('Storefront', storefront);
        kaiEvent.storefrontFilterApplied.setPropertyValue('Tab', tab);
        $segment.track(...kaiEvent.storefrontFilterApplied.toSegment());
    };
    const trackDownloadCsv = () => {
        kaiEvent.downloadCsv.setPropertyValue('Tab', route.name);
        $segment.track(...kaiEvent.downloadCsv.toSegment());
    };
    const trackSbaAdsRun = () => $segment.track(...kaiEvent.sbaAdsRun.toSegment());
    const trackSbaImpressionFilterApplied = () => $segment.track(...kaiEvent.sbaImpressionFilterApplied.toSegment());
    const trackSbaPopularityFilterApplied = () => $segment.track(...kaiEvent.sbaPopularityFilterApplied.toSegment());
    const trackSbaKeywordFilterApplied = () => $segment.track(...kaiEvent.sbaKeywordFilterApplied.toSegment());
    const trackTaCategoryFilterApplied = (category) => {
        kaiEvent.taCategoryFilterApplied.setPropertyValue('Category', category);
        $segment.track(...kaiEvent.taCategoryFilterApplied.toSegment());
    };
    const trackKgAdsRun = () => $segment.track(...kaiEvent.kgAdsRun.toSegment());
    const trackKgPageView = () => $segment.track(...kaiEvent.kgPageView.toSegment());
    const trackKgMutualKeywords = () => $segment.track(...kaiEvent.kgMutualKeywords.toSegment());
    const trackKgUniqueKeyword = () => $segment.track(...kaiEvent.kgUniqueKeyword.toSegment());
    const trackKgImpressionFilterApplied = () => $segment.track(...kaiEvent.kgImpressionFilterApplied.toSegment());
    const trackKgPopularityFilterApplied = () => $segment.track(...kaiEvent.kgPopularityFilterApplied.toSegment());
    const trackKgKeywordFilterApplied = () => $segment.track(...kaiEvent.kgKeywordFilterApplied.toSegment());

    return {
        trackRunAdsForAllKw,
        trackRunAdsForSingleKw,
        trackAddToKeywords,
        trackAddToNegativeKeywords,
        trackStorefrontFilterApplied,
        trackDownloadCsv,
        trackSbaAdsRun,
        trackSbaImpressionFilterApplied,
        trackSbaPopularityFilterApplied,
        trackSbaKeywordFilterApplied,
        trackTaCategoryFilterApplied,
        trackKgAdsRun,
        trackKgPageView,
        trackKgMutualKeywords,
        trackKgUniqueKeyword,
        trackKgImpressionFilterApplied,
        trackKgPopularityFilterApplied,
        trackKgKeywordFilterApplied,
    };
};
