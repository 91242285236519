export class SegmentEvent {
    /**
     * Properties array for structuring.
     * @type {{name: String, default: ?any, value: ?any}[]}
     */
    properties = [];
    event = '';
    prefix = '';
    constructor(event, properties = null, prefix = 'SA-') {
        this.event = event;
        this.prefix = prefix;
        if (properties) {
            this.properties = properties;
        }
    }

    /**
     * Transforms {@link properties} from array to an object structure that segment accepts.
     * @returns {null|object}
     */
    get propertiesAsObject() {
        if (this.properties) {
            return this.properties
                .reduce((propertiesObject, property) => {
                    propertiesObject[property.name] = property.value ?? property.default;
                    return propertiesObject;
                }, {});
        }
        return null;
    }

    /**
     * @typedef {number} EventIndex
     * @typedef {number} CustomPropertiesIndex
     * @typedef {[EventIndex, CustomPropertiesIndex]} SegmentTrackParams
     * @returns {SegmentTrackParams}
     */
    toSegment() {
        const eventName = !this.event?.startsWith(this.prefix) && ![null, undefined].includes(this.prefix)
            ? `${this.prefix}${this.event}`
            : this.event;
        return [eventName, this.propertiesAsObject];
    }

    /**
     * To set a single Segment Custom Property
     * @param {string} name
     * @param {any} value
     */
    setPropertyValue(name, value) {
        const propertyExists = this.properties.find(property => property.name === name);
        if (propertyExists) {
            propertyExists.value = value;
        } else {
            this.properties.push({ name: name, value: value });
        }
    }

    /**
     * To set a multiple Segment Custom Properties
     * @param {(properties|{Object})} propertyValueMappings
     */
    setPropertyValues(propertyValueMappings) {
        if (propertyValueMappings === null) {
            console.error('propertyValueMappings is null.');
        }

        if (typeof propertyValueMappings === 'object' &&
            !Array.isArray(propertyValueMappings)) {
            Object.entries(propertyValueMappings)
                .forEach((entry) => {
                    this.setPropertyValue(entry[0], entry[1]);
                });
        } else {
            propertyValueMappings
                .forEach((property) => {
                    if (property.value) {
                        this.setPropertyValue(property.name, property.value);
                    }
                });
        }
    }
}
