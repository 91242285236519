<template>
    <a-modal
        :open="dialogVisible"
        class="ma-dialog-lg ma-upgrade-plan-modal"
        :closable="false"
        @cancel="dialogVisible = false"
    >
        <template #title>
            <div class="flex justify-between items-center">
                <div class="font-medium text-white">
                    {{ $t('upgradeToStart') }}
                </div>


                <img
                    class="h-8"
                    src="../../assets/sa-logo-white.svg"
                    alt="sa logo"
                >
            </div>
        </template>

        <div class="ma-feature-list">
            <p class="font-medium">
                {{ $t('withUpgradedPlan') }}
            </p>
            <ul class="list-none leading-6">
                <li v-for="index in TOTAL_LIST_ITEMS" :key="index">
                    <fa icon="check" class="mr-1 text-blue-600"/>
                    {{ $t(`featureList.${index}`) }}
                </li>
            </ul>
        </div>

        <template #footer>
            <div class="flex justify-start">
                <a-button type="primary" @click="contact">
                    <fa icon="rocket" class="text-white"/>
                    {{ $t('contactTheTeam') }}
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
    import { REQUEST_DEMO_URL } from '@/common/Config/index';
    import messages from '@/components/MaUpgradePlanModal/MaUpgradePlanModal.i18n';
    import { mapActions } from 'pinia';
    import { useAccountStore } from '@/stores/Account';

    export default {
        name: 'ma-access-warning-dialog',
        props: {
            visible: { type: Boolean },
        },
        emits: ['update:visible'],
        data() {
            return {
                dialogVisible: false,
                TOTAL_LIST_ITEMS: 5,
            };
        },
        methods: {
            ...mapActions(useAccountStore, ['fetchAccountTotalSpend']),
            contact() {
                const url = REQUEST_DEMO_URL;
                const win = window.open(url, '_blank');
                win.focus();
                this.$emit('update:visible', false);
            },
        },
        created() {
            this.fetchAccountTotalSpend();
        },
        watch: {
            visible() {
                this.dialogVisible = this.visible;
            },
            dialogVisible() {
                if (this.visible !== this.dialogVisible) {
                    this.$emit('update:visible', this.dialogVisible);
                }
            },
        },
        i18n: { messages },
    };
</script>

<style lang="less">
    .ma-upgrade-plan-modal {
        .ant-modal-header {
            @apply bg-blue-600;
        }
    }
</style>
