<template>
    <div>
        <a-modal
            v-model:open="visible"
            class="ma-keyword-add-modal"
            :title="$t('addKeywords')"
            :mask-closable="false"
            :closable="false"
            :keyboard="false"
            width="1260px"
            @cancel="cancel"
        >
            <template #title>
                <span v-if="isKeyword">
                    <div class="font-medium">
                        {{ $t('buttons.addKeywords') }}
                        <a-tooltip overlay-class-name="ma-tooltip-md" placement="right">
                            <template #title>
                                <span v-html="adGroupKeywordsTooltip"/>
                            </template>
                            <span><small><fa icon="info-circle"/></small></span>
                        </a-tooltip>
                    </div>
                </span>
                <span v-else>
                    <div class="font-medium">
                        {{ $t('buttons.addNegativeKeywords') }}
                        <a-tooltip :title="$t('theseKeywordsWillNotBeShown')">
                            <fa icon="info-circle"/>
                        </a-tooltip>
                    </div>
                </span>
            </template>

            <ma-steps
                v-if="step !== 1"
                :current="step"
                class="ma-steps-wrapper"
            >
                <ma-step v-for="s in steps" :key="s.title" :title="s.title"/>
            </ma-steps>

            <div v-if="step === 0">
                <a-spin v-if="showTargetSelection" :spinning="loading.table">
                    <div class="flex flex-col justify-center items-center h-72">
                        <div class="text-sm my-4">
                            {{ selectMetadataInfo }}
                        </div>

                        <a-button
                            class="ma-clear-button"
                            type="text"
                            @click="showMetadataSelector"
                        >
                            <template #icon>
                                <edit-outlined/>
                            </template>
                            {{ selectMetadataLabel }}
                        </a-button>
                    </div>
                </a-spin>

                <div
                    v-else-if="showNegativeKwLevelSelection"
                    class="flex flex-col justify-center items-center h-72 bg-gray-100"
                >
                    <div class="text-sm mb-4">
                        {{ $t('chooseNegativeKeywordTarget') }}
                    </div>
                    <a-radio-group v-model:value="selectedNegativeType">
                        <a-radio :value="negativeTypes.NEGATIVE_CAMPAIGN">
                            {{ $t('negativeKwsToCampaign') }}
                        </a-radio>
                        <a-radio :value="negativeTypes.NEGATIVE_AD_GROUP">
                            {{ $t('negativeKwsToAdGroup') }}
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>

            <div v-if="step === 1">
                <div class="flex justify-between items-center my-6">
                    <div class="flex gap-2">
                        <ma-app-icon
                            v-if="selectedAppInfo"
                            :app-icon="selectedAppInfo?.iconUrl"
                        />
                        <div>
                            <ma-typography
                                type="body-3"
                                weight="medium"
                                class="text-gray-800"
                            >
                                {{ selectedAppInfo?.name }}
                            </ma-typography>
                            <div class="flex gap-2 items-center">
                                <div v-if="!isKeyword && selectedNegativeType" class="flex gap-1">
                                    <ma-typography
                                        type="caption-1"
                                        weight="medium"
                                        class="text-gray-500"
                                    >
                                        {{ $t('negativeKwLevel') }}
                                    </ma-typography>
                                    <ma-button
                                        color="blue"
                                        variant="ghost"
                                        size="medium"
                                        :disabled="isMetadataImmutable || loading.table"
                                        @click="changeNegativeLevel"
                                    >
                                        {{ negativeKeyLevelLabel }}
                                    </ma-button>
                                </div>
                                <div v-if="preDefinedCampaign.length" class="flex gap-1">
                                    <ma-typography
                                        type="caption-1"
                                        weight="medium"
                                        class="text-gray-500"
                                    >
                                        {{ $t('common.campaign') }}:
                                    </ma-typography>
                                    <ma-button
                                        color="blue"
                                        variant="ghost"
                                        size="x-small"
                                        :disabled="isMetadataImmutable || loading.table"
                                        class="w-[130px] justify-start [&_.ma-button\_\_label]:block"
                                        @click="changeCampaign"
                                    >
                                        {{
                                            preDefinedCampaign.length === 1
                                                ? definedCampaign.name
                                                : this.$tc('common.nCampaignsSelected', preDefinedCampaign.length, { n: preDefinedCampaign.length })
                                        }}
                                    </ma-button>
                                </div>
                                <div v-if="preDefinedAdGroup.length" class="flex gap-1">
                                    <ma-typography
                                        type="caption-1"
                                        weight="medium"
                                        class="text-gray-500"
                                    >
                                        {{ $t('common.adGroup') }}:
                                    </ma-typography>
                                    <ma-button
                                        color="blue"
                                        variant="ghost"
                                        size="x-small"
                                        :disabled="isMetadataImmutable || loading.table"
                                        class="w-[130px] justify-start [&_.ma-button\_\_label]:block"
                                        @click="changeAdGroup"
                                    >
                                        {{
                                            preDefinedAdGroup.length === 1
                                                ? (definedAdGroup.adGroupName || definedAdGroup.name)
                                                : this.$tc('common.nAdGroupsSelected', preDefinedAdGroup.length, { n: preDefinedAdGroup.length })
                                        }}
                                    </ma-button>
                                </div>
                                <div v-if="preDefinedAdGroup.length && !isMetadataImmutable && isKeyword" class="flex gap-1 items-center">
                                    <ma-typography
                                        type="caption-1"
                                        weight="medium"
                                        class="text-gray-500"
                                    >
                                        {{ $t('maxCPTBid') }}:
                                    </ma-typography>
                                    <ma-select
                                        v-model:value="CPCValueOption"
                                        size="small"
                                        class="!w-[150px] px-0 ma-cpc-selection"
                                        :dropdown-match-select-width="false"
                                        borderless
                                        @change="changeDefaultCPC"
                                    >
                                        <ma-select-option :value="1">
                                            {{ $t('useDefaultMaxCPT') }}
                                        </ma-select-option>
                                        <a-select-option :value="0">
                                            {{ $t('setCustomValuesOnTable') }}
                                        </a-select-option>
                                    </ma-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ma-steps
                        :current="step"
                        class="ma-steps-wrapper"
                    >
                        <ma-step v-for="s in steps" :key="s.title" :title="s.title"/>
                    </ma-steps>
                </div>
                <a-spin v-if="showMainSection" :spinning="loading.main">
                    <div class="flex">
                        <div :class="{ 'w-1/2': isKeyword, 'w-1/3': !isKeyword }">
                            <div v-if="!isKeyword" class="flex flex-col items-center mr-6 text-justify">
                                <div class="text-black thin-text break-normal mb-2">
                                    {{ $t('adGroupNegativeKeywords') }}
                                </div>
                                <div class="text-black thin-text break-normal">
                                    {{ $t('considerGivingCampaign') }}
                                </div>
                            </div>

                            <ma-keyword-suggestions
                                v-if="isKeyword"
                                :added-keywords="kwToAdd"
                                @add-keyword="addKeyword"
                                @add-all-keywords="addAllKeywords"
                                @remove-keywords="removeKeywords"
                            />
                        </div>
                        <div :class="{ 'w-1/2': isKeyword, 'w-2/3': !isKeyword }">
                            <ma-keyword-list
                                ref="maKeywordList"
                                v-model:list="kwToAdd"
                                :keyword-type="addKeywordType"
                                :currency="currency"
                                :use-default-cpc="useDefaultCPC"
                                :currency-symbol="currencySymbol"
                                :default-bid="amountOfDefaultCpcBid"
                                :campaign-id="selectedCampaign"
                                :ad-group-id="selectedAdGroup"
                                :show-label-creation="addKeywordType === KEYWORD_TYPE.KEYWORD && page === KEYWORD_MODAL_PAGE.ADS_MANAGER_KEYWORD"
                            />
                        </div>
                    </div>

                    <hr>
                </a-spin>
            </div>

            <ma-keywords-issue-check
                v-if="step === 2"
                ref="keywordsCheck"
                v-model:loading="loading.main"
                v-model:issues-count="issuesCount"
                v-model:keywords-to-be-added-count="keywordsToBeAddedCount"
                v-model:excluded-keywords="excludedKeywords"
                :campaigns="preDefinedCampaign"
                :ad-groups="preDefinedAdGroup"
                :type="keywordType"
                :country-codes="countryCodes"
                :is-campaign-level="negativeTypeOnCampaign"
                :keywords="keywordsForIssues"
                @submit="save"
            />

            <template #footer>
                <div
                    class="flex justify-between"
                >
                    <div>
                        <a-button
                            v-show="step > 1"
                            :disabled="loading.main"
                            @click="step -= 1"
                        >
                            <fa icon="arrow-left"/>
                            {{ $t('buttons.back') }}
                        </a-button>
                    </div>
                    <div class="ma-keyword-add-modal-footer-right">
                        <a-progress
                            v-if="Boolean(summaryProgress)"
                            class="ma-keyword-add-progress"
                            :percent="summaryProgress"
                            :stroke-width="10"
                        />

                        <a-button @click="cancel">
                            {{ $t('buttons.cancel') }}
                        </a-button>

                        <a-button
                            v-if="skipKeywordsIssueCheck"
                            type="primary"
                            class="ma-keyword-button-with-icon"
                            @click="save"
                        >
                            <template #icon>
                                <fa icon="plus"/>
                            </template>
                            {{ $t('buttons.save') }}
                        </a-button>
                        <a-tooltip v-else placement="top">
                            <template v-if="!!cannotSaveReason" #title>
                                {{ cannotSaveReason }}
                            </template>
                            <a-button
                                v-if="step === 1"
                                :disabled="cannotSave"
                                type="primary"
                                class="ma-keyword-button-with-icon"
                                @click="checkKeywords"
                            >
                                <span>{{ $t('buttons.next') }}</span>
                                <fa icon="chevron-right"/>
                                <fa v-if="!!cannotSaveReason" icon="exclamation-triangle"/>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip placement="top">
                            <template v-if="!!cannotSaveReason" #title>
                                {{ cannotSaveReason }}
                            </template>
                            <a-button
                                v-if="step === 2"
                                :disabled="cannotSave"
                                type="primary"
                                class="ma-keyword-button-with-icon"
                                @click="save"
                            >
                                <template #icon>
                                    <fa icon="plus"/>
                                </template>
                                {{ $t('buttons.save') }}
                                <fa v-if="!!cannotSaveReason" icon="exclamation-triangle"/>
                            </a-button>
                        </a-tooltip>
                    </div>
                </div>
            </template>
        </a-modal>

        <ma-metadata-selector
            v-if="!isMetadataImmutable"
            v-model:visible="metadataSelector.visible"
            :type="metadataSelector.type"
            :selected-data="metadataSelector.selectedData"
            @data-chosen="metadataSelected"
        />
    </div>
</template>

<script>
    import dayjs from '@/plugins/dayjs.mjs';
    import { sortByKey, KEYWORD_MODAL_PAGE } from '@/common/MaUtils.mjs';
    import { adGroupKeywordsTooltip } from '@/common/TooltipContents';
    import MaKeywordList from '@/components/KeywordAddModal/MaKeywordList.vue';
    import MaKeywordsIssueCheck from '@/components/MaKeywordsCheck/MaKeywordsCheck.vue';
    import AccountIntegratedCheck from '@/mixins/AccountIntegratedCheck.mjs';
    import MetadataUtils from '@/mixins/MetadataUtils.mjs';
    import { NEGATIVE_KEYWORD_TYPE } from '@/common/ManagementUtils.mjs';
    import MaMetadataSelector from '@/components/MaMetadataSelector/MaMetadataSelector.vue';
    import { getAdGroup } from '@/common/MaRequests/SearchAds';
    import { changeActiveOrgId } from '@/common/FilteringUtils';
    import NumberUtils from '@/mixins/NumberUtils.mjs';
    import MaKeywordSuggestions from './MaKeywordSuggestions.vue';
    import { EditOutlined } from '@ant-design/icons-vue';
    import messages from '@/components/KeywordAddModal/KeywordAddModal.i18n';
    import {
        MaStep,
        MaSteps,
        MaAppIcon,
        MaTypography,
        MaButton,
        MaSelect,
        MaSelectOption,
    } from '@mobileaction/action-kit';
    import { getSimpleAppInfo } from '@/common/MaRequests/AppInfo';
    import { useKeywordAddModalStore } from '@/stores/KeywordAdd';
    import { mapActions, mapState } from 'pinia';
    import { useAccountStore } from '@/stores/Account';
    export const KEYWORD_TYPE = {
        NEGATIVE_KEYWORD: 'negative-keyword',
        KEYWORD: 'keyword',
    };
    export default {
        name: 'ma-add-keyword-modal',
        components: {
            MaSelectOption,
            MaSelect,
            MaButton,
            MaTypography,
            MaStep,
            MaSteps,
            MaKeywordSuggestions,
            MaMetadataSelector,
            MaKeywordList,
            MaAppIcon,
            MaKeywordsIssueCheck,
            EditOutlined,
        },
        mixins: [AccountIntegratedCheck, MetadataUtils, NumberUtils],
        props: {
            keywords: { type: Array, default: () => [] },
            modelValue: { type: Boolean, default: true },
            addKeywordType: { type: String, default: KEYWORD_TYPE.KEYWORD },
            defaultCpcBid: { type: Number, required: false },
            unauthorized: { type: Boolean, default: false },
            givenCampaign: { type: Object },
            // todo remove given campaign and adGroup, countryCodes after old dashboard manager removed
            givenAdGroup: { type: Object },
            countryCodes: { type: Array },
            filterAdGroup: { type: Object },
            filterCampaign: { type: Object },
            skipKeywordsIssueCheck: { type: Boolean, default: false },
            page: { type: String, default: '', required: false },
        },
        emits: ['update:filter-ad-group', 'update:filter-campaign', 'update:keywords', 'keywords-saved', 'update:modelValue'],
        data() {
            const kwToAdd = this.keywords
                .map(k => Object.assign({
                    bidAmount: k.bidAmount || { amount: 1, currency: '' },
                }, k));

            const safeCampaignId = (adGroup) => {
                adGroup.campaignId = adGroup.campaignId || this.givenCampaign?.id;
                return adGroup;
            };
            return {
                KEYWORD_TYPE,
                KEYWORD_MODAL_PAGE,
                issuesCount: 0,
                visible: false,
                keywordsToBeAddedCount: 0,
                excludedKeywords: [],
                invalidChars: ['[', '{', '}', ']', '"', '\\', ';'],
                keywordAddingError: null,
                loading: {
                    table: false,
                    main: false,
                },
                // if campaign and adGroup defined, start step from 1. no need to show metadata selector
                step: this.resetStep(),
                kwToAddStr: '',
                kwToAdd,
                keepKeywords: false,
                adGroupKeywordsTooltip,
                startDate: dayjs().add(-7, 'days').format('YYYY-MM-DD'),
                endDate: dayjs().format('YYYY-MM-DD'),
                selectAll: false,
                matchTypeSelected: '',
                bidAmountSelected: null,
                invalid: {
                    keywords: [],
                    showKeywords: false,
                    visible: false,
                },
                storeName: 'ios',
                selectedApp: this.$maLocalStorage.lastSearchedApp,
                pagerCount: 5,
                useDefaultCPC: false,
                CPCValueOption: 0,
                loadingCount: 0,
                preDefinedCampaign: this.givenCampaign ? [this.givenCampaign] : [],
                preDefinedAdGroup: this.givenAdGroup ? [safeCampaignId(this.givenAdGroup)] : [],
                negativeTypes: NEGATIVE_KEYWORD_TYPE,
                metadataSelector: { visible: false, type: 'ad-groups', selectedData: [], previousData: {} },
                selectedData: [],
                selectedNegativeType: this.givenAdGroup ? NEGATIVE_KEYWORD_TYPE.NEGATIVE_AD_GROUP :
                    (this.givenCampaign ? NEGATIVE_KEYWORD_TYPE.NEGATIVE_CAMPAIGN : null),
                selectedAppInfo: null,
            };
        },
        computed: {
            ...mapState(useKeywordAddModalStore, ['doneRequestsCount, total']),
            ...mapState(useAccountStore, ['currency', 'activeOrg', 'integrations', 'currencySymbol']),
            summaryProgress() {
                if (this.total === 0) {
                    return 0;
                }
                return parseInt(`${ this.doneRequestsCount / this.total * 100 }`);
            },
            isKeyword() {
                return this.addKeywordType === KEYWORD_TYPE.KEYWORD;
            },
            keywordType() {
                return this.addKeywordType === KEYWORD_TYPE.KEYWORD ? 'KEYWORD' : 'NEGATIVE';
            },
            steps() {
                const list = [
                    this.$t('selectTarget'),
                    this.$t('addKeywords'),
                    this.$t('checkKeywordIssues'),
                ];

                if (this.skipKeywordsIssueCheck) {
                    list.splice(2, 1); // pop checkKeywordIssues
                }

                return list
                    .map((title, i) => ({
                        showHere: i === this.step,
                        title,
                    }));
            },
            selectedCampaign() {
                return 0;
            },
            selectedAdGroup() {
                return 0;
            },
            addedKeywordsMap() {
                return this.kwToAdd.reduce((m, k) => {
                    m[k.text] = true;
                    return m;
                }, {});
            },
            definedAdGroup() {
                return this.preDefinedAdGroup.length ? this.preDefinedAdGroup[0] : {};
            },
            definedCampaign() {
                return this.preDefinedCampaign.length ? this.preDefinedCampaign[0] : {};
            },
            keywordsForIssues() {
                return this.kwToAdd
                    .map(k => ({
                        ...k,
                        adGroupId: this.definedAdGroup.id,
                        campaignId: this.definedCampaign.id,
                    }));
            },
            campaignList() {
                return sortByKey(this.campaigns, 'campaignStatus');
            },
            hasMissingAmount() {
                return this.isKeyword && !!this.kwToAdd
                    .filter(k => !k.bidAmount || !k.bidAmount.amount || this.mx_validateNumber(k.bidAmount.amount)).length;
            },
            cannotSave() {
                if (!this.isKeyword && !this.selectedNegativeType) {
                    return true;
                }
                if (this.loading.table || this.loading.main) {
                    return true;
                }
                return !this.kwToAdd.length || this.hasMissingAmount
                    || (!this.keywordsToBeAddedCount && this.step === 2);
            },
            cannotSaveReason() {
                if (!this.kwToAdd.length) {
                    return this.$t('noKeywordAddedList');
                } else if (this.hasMissingAmount) {
                    return this.$t('makeSureBidAmountFilled');
                } else if (!this.keywordsToBeAddedCount && this.step === 2) { // check this for only step 2
                    return this.$t('allKeywordsExcluded');
                }
                return null;
            },
            thereIsSelection() {
                return this.kwToAdd.filter(k => k.selected).length;
            },
            negativeKeyLevelLabel() {
                return this.negativeTypeOnAdGroup
                    ? this.$t('common.adGroup')
                    : this.$t('common.campaign');
            },
            negativeTypeOnAdGroup() {
                return !this.isKeyword && this.selectedNegativeType &&
                    this.selectedNegativeType === NEGATIVE_KEYWORD_TYPE.NEGATIVE_AD_GROUP;
            },
            negativeTypeOnCampaign() {
                return !this.isKeyword && this.selectedNegativeType &&
                    this.selectedNegativeType === NEGATIVE_KEYWORD_TYPE.NEGATIVE_CAMPAIGN;
            },
            showTargetSelection() {
                return !this.preDefinedCampaign.length
                    && (this.isKeyword || this.loading.table
                        || (!this.isKeyword && this.selectedNegativeType));
            },
            showNegativeKwLevelSelection() {
                return !this.isKeyword && !this.selectedNegativeType;
            },
            showMainSection() {
                if (this.step > 1 || !this.preDefinedCampaign.length) {
                    return false;
                }
                const keywordOrNegativeForAdGroup = this.isKeyword || this.negativeTypeOnAdGroup;
                return (keywordOrNegativeForAdGroup && this.preDefinedAdGroup)
                    || (!this.isKeyword && this.preDefinedCampaign.length);
            },
            showAdGroupSelection() {
                return (this.isKeyword || !this.negativeTypeOnCampaign)
                    ? (this.preDefinedCampaign.length && !this.preDefinedAdGroup.length)
                    : false;
            },
            isMetadataImmutable() {
                return !!(this.givenCampaign || this.givenAdGroup);
            },
            metadataType() {
                return this.negativeTypeOnCampaign
                    ? this.$t('common.campaign')
                    : this.$t('common.adGroup');
            },
            selectMetadataInfo() {
                return this.$t('typeToUploadKws', { type: this.metadataType });
            },
            selectMetadataLabel() {
                return `${this.$t('select')} ${this.metadataType}`;
            },
            amountOfDefaultCpcBid() {
                if (this.defaultCpcBid) {
                    return typeof this.defaultCpcBid === 'object' ? this.defaultCpcBid.amount : this.defaultCpcBid;
                }
                if (!this.preDefinedAdGroup.length) {
                    return 1;
                }
                return this.definedAdGroup.defaultCpcBid ? this.definedAdGroup.defaultCpcBid.amount : 1;
            },
        },
        methods: {
            ...mapActions(useKeywordAddModalStore, ['resetSummary']),
            changeDefaultCPC(val) {
                this.useDefaultCPC = val === 1;
            },
            metadataSelected(chosenData, list) {
                if (!list.length) {
                    return;
                }

                // go to second stage
                this.step = 1;
                let campIds = [];
                this.preDefinedCampaign = [];
                list.forEach(({ orgId, campaignName, campaignId }) => {
                    if (!campIds.includes(campaignId)) {
                        this.preDefinedCampaign.push({
                            id: campaignId, name: campaignName,
                            orgId: orgId,
                            app: { appName: list[0].appName, adamId: list[0].appId },
                        });
                        campIds.push(campaignId);
                    }
                });
                this.preDefinedAdGroup = [];
                this.loadingCount = list.length;
                list.forEach(({ orgId, adGroupId, campaignId }) => {
                    if (adGroupId) {
                        this.fetchAdGroup(orgId, campaignId, adGroupId);
                    }
                });
            },
            fetchAdGroup(orgId, campaignId, adGroupId) {
                if (!campaignId || !adGroupId) {
                    return;
                }
                this.loading.table = true;
                changeActiveOrgId(orgId);
                getAdGroup(campaignId, adGroupId)
                    .then((data) => {
                        let adGroupIds = this.preDefinedAdGroup.map(a => a.id);
                        if (data && data.length) {
                            const adGroup = Object.assign({ orgId: orgId, adGroupName: data[0].name }, data[0]);
                            if (!adGroupIds.includes(adGroup.id)) {
                                this.preDefinedAdGroup.push(adGroup);
                            }
                        }
                        this.init(true);
                    })
                    .catch((e) => {
                        this.$log.error('Failed to fetch ad group with id', adGroupId, 'error:', e);
                    })
                    .then(() => {
                        --this.loadingCount;
                        if (this.loadingCount <= 0) {
                            this.loading.table = false;
                        }
                    });
            },
            showMetadataSelector() {
                let mm;
                if (this.negativeTypeOnCampaign) {
                    mm = this.selectedData.map(s => s.campaignId.toString());
                    this.metadataSelector.type = 'campaigns';
                } else {
                    mm = this.selectedData.map(s => s.adGroupId.toString());
                    this.metadataSelector.type = 'ad-groups';
                }
                this.metadataSelector.selectedData = mm || [];
                this.metadataSelector.visible = true;
            },
            changeCampaign() {
                this.preDefinedCampaign = null;
                this.changeAdGroup();
                this.preDefinedCampaign = [];
                this.preDefinedAdGroup = [];
            },
            changeAdGroup() {
                this.$emit('update:filter-ad-group', null);
                this.$emit('update:filter-campaign', null);
                this.init();
                this.preDefinedCampaign = [];
                this.preDefinedAdGroup = [];
                this.step = 0;
            },
            changeNegativeLevel() {
                if (this.givenCampaign || this.givenAdGroup) {
                    return;
                }
                this.changeCampaign();
                this.selectedNegativeType = null;
            },
            removeKeyword(kw) {
                this.kwToAdd = this.kwToAdd.filter(k => k.text !== kw.text);
            },
            removeSelected() {
                this.kwToAdd = this.kwToAdd
                    .filter(k => !k.selected);
            },
            containsInvalidChars(str) {
                let invalid = 0;
                this.invalidChars.forEach((char) => {
                    if (str.indexOf(char) > -1) {
                        invalid++;
                    }
                });
                return invalid > 0;
            },
            setSelectedBidAmount() {
                this.kwToAdd.forEach((k) => {
                    if (k.selected) {
                        k.bidAmount.amount = this.bidAmountSelected;
                    }
                });
            },
            cancel() {
                this.loading.main = false;
                this.step = this.resetStep();
                this.$emit('update:modelValue', false);
            },
            save() {
                if (!this.issuesCount) {
                    this.submit();
                } else {
                    const content = this.$t('thisWillProcessConflictingKeywords');
                    const title = this.$t('areYouSureYouWantContinue');
                    this.$confirm({
                        content,
                        title,
                        appContext: this.$.appContext,
                        key: 'areYouSureYouWantContinue',
                        type: 'warning',
                        okText: this.$t('buttons.OK'),
                        onOk: () => this.submit(),
                    });
                }
            },
            submit() {
                const keywords = this.kwToAdd
                    .map(({ text, matchType, bidAmount, labels }) => ({ text, matchType, bidAmount, labels }));

                this.loading.main = true;
                this.$emit('update:keywords', keywords);
                const opts = {
                    keywords,
                    addKeywordType: this.addKeywordType,
                    campaigns: this.preDefinedCampaign,
                    adGroups: this.preDefinedAdGroup,
                    selectedNegativeType: this.selectedNegativeType,
                    useDefaultCPC: this.useDefaultCPC,
                    excludedKeywords: this.excludedKeywords,
                };
                this.$emit('keywords-saved', opts, () => {
                    this.loading.main = false;
                    this.step = this.resetStep();
                });
            },
            checkKeywords() {
                this.step = 2;
            },
            // Add current page keywords
            addAllKeywords(list) {
                if (!list?.length) {
                    return;
                }
                const newKwsToAdd = list.map(kwo => ({
                    text: kwo.keyword,
                    selected: false,
                    matchType: 'EXACT',
                    bidAmount: this.isKeyword ? {
                        amount: this.amountOfDefaultCpcBid,
                        currency: this.currency,
                    } : null,
                }));
                this.kwToAdd.unshift(...newKwsToAdd);
            },
            addKeyword(row) {
                const text = row.keyword;
                if (!text || this.addedKeywordsMap[text]) {
                    return;
                }
                let obj = {
                    text,
                    selected: false,
                    matchType: 'EXACT',
                };
                if (this.isKeyword) {
                    obj.matchType = 'EXACT';
                    obj.bidAmount = {
                        amount: this.amountOfDefaultCpcBid,
                        currency: this.currency,
                    };
                }
                this.kwToAdd.unshift(obj);
            },
            removeKeywords(row) {
                const keywords = new Set(row.map(r => r.keyword));
                this.kwToAdd = this.kwToAdd.filter(k => !keywords.has(k.text));
            },
            setInitialKeywords(newOne) {
                if (!this.keywords || !this.keywords.length) {
                    this.kwToAdd = [];
                    return;
                }
                const newKwsToAdd = this.keywords.map(kwo => ({
                    text: kwo.keyword || kwo.text,
                    selected: false,
                    matchType: kwo.matchType || 'EXACT',
                    bidAmount: this.isKeyword ? {
                        amount: kwo.bidAmount?.amount ? kwo.bidAmount.amount : this.amountOfDefaultCpcBid,
                        currency: this.currency,
                    } : null,
                }));

                if (newOne) {
                    this.kwToAdd = newKwsToAdd;
                } else {
                    this.kwToAdd.unshift(...newKwsToAdd);
                    this.kwToAdd = this.kwToAdd.filter(k => k.text);
                }
            },
            checkCampaignFilter() {
                if (!this.isKeyword && this.filterCampaign) {
                    this.selectedNegativeType = this.negativeTypes.NEGATIVE_CAMPAIGN;
                }
                this.metadataSelected([], this.filterCampaign ? [this.filterCampaign] : []);
            },
            checkAdGroupFilter() {
                if (!this.isKeyword && this.filterAdGroup) {
                    this.selectedNegativeType = this.negativeTypes.NEGATIVE_AD_GROUP;
                }
                if (!this.filterAdGroup) {
                    this.preDefinedAdGroup = [];
                }
                this.metadataSelected([], this.filterAdGroup ? [this.filterAdGroup] : []);
            },
            resetStep() {
                // if campaign and adGroup defined or filtered, start step from 1.
                // no need to show metadata selector
                if ((this.givenCampaign && this.givenAdGroup)
                    || this.filterAdGroup || this.filterCampaign) {
                    return 1;
                }
                return 0;
            },
            init(newOne) {
                if (this.preDefinedAdGroup.length) {
                    this.setInitialKeywords(newOne);
                } else {
                    this.checkCampaignFilter();
                    this.checkAdGroupFilter();
                }
            },
            getAppInfo() {
                const id = this.definedCampaign?.app?.adamId
                    || this.definedCampaign?.app?.trackId;
                if (!id) {
                    return;
                }
                getSimpleAppInfo({ trackIds: [id] })
                    .then((data) => {
                        if (data && data.length) {
                            this.selectedAppInfo = data[0];
                        }
                    })
                    .catch((e) => {
                        this.$log.error('Failed to fetch simple app info', e);
                    });
            },
        },
        watch: {
            modelValue: {
                handler(toValue, fromValue) {
                    this.visible = toValue;
                    this.loading.main = false;
                    this.step = this.resetStep();
                    if (!toValue) { // work only when dialog is becomes invisible
                        this.changeNegativeLevel();
                        return;
                    }
                    if (!fromValue && toValue) {
                        // can hold summary until keyword modal is opened again
                        this.resetSummary();
                    }
                    this.init();
                    if (this.keywords?.length !== this.kwToAdd?.length) {
                        this.setInitialKeywords(true);
                    }
                },
            },
            givenCampaign() {
                this.preDefinedCampaign = this.givenCampaign ? [this.givenCampaign] : [];
            },
            givenAdGroup() {
                this.preDefinedAdGroup = this.givenAdGroup ? [this.givenAdGroup] : [];
            },
            filterCampaign() {
                this.checkCampaignFilter();
            },
            filterAdGroup() {
                this.checkAdGroupFilter();
            },
            preDefinedAdGroup(fr ,to) {
                const frStr = fr.map(f => f.id || f.adGroupId).join('');
                const toStr = to.map(f => f.id || f.adGroupId).join('');
                if (frStr !== toStr) {
                    this.init(true);
                }
            },
            preDefinedCampaign() {
                if (!this.isKeyword && this.negativeTypeOnCampaign) {
                    this.setInitialKeywords(true);
                }
            },
            definedCampaign() {
                this.getAppInfo();
            },
        },
        i18n: {
            messages,
        },
    };
</script>

<style lang="less" scoped>
    .ant-modal {
        hr {
            @apply border-0 border-l-0 border-t border-solid;
            border-top-color: #ebeef5;
        }

        .ma-select {
            @apply w-full ~"mb-1.5";
        }

        .ma-keyword-adding-error {
            color: red;
        }

        .ma-invalid-keyword-warning {
            @apply mb-4 border-0 border-l-4 border-solid;
            border-left-color: orange;
            background-color: floralwhite;

            .ma-warning-text {
                color: #606266;
            }

            .ma-invalid-keyword-list {
                @apply text-sm;
                color: #c0c4cc;
            }

            .ant-button {
                color: #FFA500;

                &:hover {
                    color: #FFA50090;
                }
            }
        }
    }

    .ma-keyword-add-modal {
        .ant-pagination {
            @apply w-full;
        }

        .ant-modal-header {
            h3 {
                @apply m-0;
            }
        }

        .ant-modal-body {
            @apply py-3;
        }

        .ant-modal-footer {
            .ma-keyword-add-modal-footer-right {
                @apply flex justify-end items-center gap-x-2;

                .ma-keyword-button-with-icon {
                    @apply flex gap-x-2 items-center;
                }

                .ma-keyword-add-progress {
                    @apply ~"mr-3.5";
                    min-width: 200px;
                }
            }
        }

        .ma-max-cpt-bid-select {
            @apply w-64;
        }

        .ant-table-row {
            @apply cursor-pointer;

            td {
                @apply ~"py-2.5" px-0;
            }
        }

    }

    :deep(.ma-steps-wrapper) {
        @apply ~'w-[500px]';
        .ak-step__dot {
            @apply w-8 h-8;
        }
        .ak-step__title {
            @apply !text-md;
        }
    }

    :deep(.ma-cpc-selection) {
        .antd-select-selection-item {
            @apply ~'!text-[12px]';
        }
    }

    .ma-keyword-list {
        .ant-card-body {
            .ma-keyword-adding-list {
                @apply border-none;
            }
        }
    }
</style>

<style>
    .ma-keyword-add-modal {
        .ant-steps {
            .ant-steps-item-finish {
                .ant-steps-item-icon {
                    @apply border-none;
                    background-color: #67C23A;
                }
                .ant-steps-icon {
                    @apply text-white;
                }
            }
            @apply rounded-none my-4;
        }
    }
</style>
