<template>
    <ma-button
        v-bind="$attrs" size="large"
        class="mt-8"
        @click="sendEventTrack"
    >
        <slot>
            {{ t('buttons.contactSales') }}
        </slot>
    </ma-button>
</template>

<script setup>
    import { MaButton } from '@mobileaction/action-kit';

    import { useI18n } from 'vue-i18n';
    import { contactSalesWallEvent } from '@/plugins/segment/eventDefinitions';
    import { useGlobal } from '@/composables/index';
    import { useRoute } from 'vue-router';

    const { t } = useI18n();

    const app = useGlobal();

    const route = useRoute();

    const sendEventTrack = () => {
        app.$segment.track(...contactSalesWallEvent(route).toSegment());
    };
</script>
