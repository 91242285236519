/**
 *  DO NOT import vuex store here because it messes up the order of imports and causes ui-modules to fail
 *  see MaVuexUtils
 *  vuex store relies on ui-modules and ui-modules relies on MaUtils and if MaUtils uses store then it causes cyclic
 *  dependency
 */
import { globalProperties } from '@/main';
import Router from '@/router';
import countryData from 'country-data';
import dayjs from '@/plugins/dayjs.mjs';
import { INSIGHTS_DOMAIN, isProduction } from '@/common/Config';
import gravatar from 'gravatar';
import { captureException } from '@sentry/vue';

const { countries } = countryData;

export const SLACK_INTEGRATION_PAGE_URI = '/slack-integration';

export const KEYWORD_MODAL_PAGE = {
    ADS_MANAGER_KEYWORD: 'ADS_MANAGER_KEYWORD',
};

export const SEC_IN_MS = 1000;
export const MIN_IN_MS = 60 * SEC_IN_MS;
export const HOUR_IN_MS = 60 * MIN_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_FORMAT_SLASHED = 'YYYY/MM/DD';
export const READABLE_DAY_FORMAT = 'dddd - MMM DD, YYYY';
export const READABLE_SHORT_DAY_FORMAT = 'ddd, MMM DD, YYYY';
export const READABLE_DATE_FORMAT = 'MMM DD, YYYY';
export const READABLE_DATETIME_FORMAT = 'MMM DD, YYYY hh:mm A';
export const READABLE_DATETIME_SHORT_FORMAT = 'MMM DD, YYYY HH:mm';
export const ISO_DATETIME_WITH_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const SHORT_DATETIME_FORMAT = 'YYYY/MM/DD HH:mm A';

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TEST_USER_HASH = -643103943;

export const MEMBERSHIP_TYPE = {
    ADMIN: 'ADMIN',
    TEAM_MEMBER: 'TEAM_MEMBER',
};

export function padZero(_n, width = 2) {
    let n = `${ _n }`;
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

// timestamp to yyyy-MM-dd string
export const dateToStr = (ts) => {
    const d = new Date(ts);
    return `${ padZero(d.getFullYear()) }-${ padZero(d.getMonth() + 1) }-${ padZero(d.getDate()) }`;
};

export const ALPHANUMERIC_CHARS = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'];

export const randomString = (len = 8) => [...new Array(len)]
    .map(() => ALPHANUMERIC_CHARS[Math.floor(Math.random() * ALPHANUMERIC_CHARS.length)])
    .join('');

export const strTruncate = (s, len = 15) => (!s || s.length < len ? s : `${ s.substring(0, len - 1) }…`);

export const getTimeMS = d => (d || new Date()).getTime();
export const SA_COUNTRY_CODES = [
    'US', 'GB', 'AU', 'BE', 'BR', 'CA', 'CN', 'DK', 'FR', 'DE', 'GR', 'HK', 'IN', 'ID', 'IT', 'JO', 'JP', 'KR',
    'MX', 'NL', 'PT', 'RU', 'SG', 'ES', 'SE', 'CH', 'TR', 'TW', 'AL', 'AR', 'AT', 'AZ', 'BH', 'BG', 'KH', 'CL',
    'CO', 'HR', 'CZ', 'DO', 'EC', 'EG', 'SV', 'EE', 'FI', 'HU', 'IS', 'IE', 'IL', 'KZ', 'KW', 'LV', 'LB', 'LU',
    'MO', 'MY', 'NZ', 'NO', 'OM', 'PK', 'PE', 'PH', 'PL', 'QA', 'RO', 'SA', 'SK', 'SI', 'ZA', 'LK', 'TH', 'UA',
    'AE', 'VN', 'PA', 'KE', 'IQ', 'DZ', 'MA', 'BO', 'CR', 'GT', 'HN', 'PY', 'UZ', 'GH', 'KG', 'NP', 'AM', 'MN',
    'CY',
];
export const IOS_COUNTRY_CODES = [
    'US', 'GB', 'AU', 'BE', 'BR', 'CA', 'CN', 'DK', 'FR', 'DE', 'GR', 'HK', 'IN', 'ID', 'IT', 'JO', 'JP', 'KR',
    'MX', 'NL', 'PT', 'RU', 'SG', 'ES', 'SE', 'CH', 'TR', 'TW', 'AL', 'AR', 'AT', 'AZ', 'BH', 'BG', 'KH', 'CL',
    'CO', 'HR', 'CZ', 'DO', 'EC', 'EG', 'SV', 'EE', 'FI', 'HU', 'IS', 'IE', 'IL', 'KZ', 'KW', 'LV', 'LB', 'LI',
    'LT', 'LU', 'MO', 'MY', 'MT', 'NZ', 'NG', 'NO', 'OM', 'PK', 'PE', 'PH', 'PL', 'QA', 'RO', 'SA', 'SK', 'SI',
    'ZA', 'LK', 'TH', 'UA', 'UY', 'AE', 'VE', 'VN', 'BD', 'PA', 'TN', 'KE', 'IQ', 'DZ', 'MA', 'BO', 'CR', 'GT',
    'HN', 'PY',
];

export const SA_COUNTRIES = SA_COUNTRY_CODES.map(cc => countries[cc]);

export const SA_REGION_CODES = [
    {
        name: 'The United States and Canada',
        tk: 'regions.northAmerica',
        codes: ['US', 'CA'],
    },
    {
        name: 'Europe',
        tk: 'regions.europe',
        codes: [
            'AL', 'AM', 'AT', 'AZ', 'BE', 'BG', 'CH', 'CY', 'CZ', 'DE',
            'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IE',
            'IS', 'IT', 'KZ', 'LU', 'LV', 'NL', 'NO', 'PL', 'PT', 'RO',
            'RU', 'SE', 'SI', 'SK', 'TR', 'UA',
        ],
    },
    {
        name: 'Africa, The Middle East, and India',
        tk: 'regions.africaMiddleEast',
        codes: [
            'AE', 'BH', 'DZ', 'EG', 'GH', 'IL', 'IN', 'IQ', 'JO', 'KE',
            'KW', 'LB', 'MA', 'OM', 'PK', 'QA', 'SA', 'ZA',
        ],
    },
    {
        name: 'Asia Pacific',
        tk: 'regions.asiaPacific',
        codes: [
            'AU', 'CN', 'HK', 'ID', 'JP', 'KG', 'KH', 'KR', 'LK', 'MN',
            'MO', 'MY', 'NP', 'NZ', 'PH', 'SG', 'TH', 'TW', 'UZ', 'VN',
        ],
    },
    {
        name: 'Latin America',
        tk: 'regions.latinAmerica',
        codes: [
            'AR', 'BO', 'BR', 'CL', 'CO', 'CR', 'DO', 'EC', 'GT', 'HN',
            'MX', 'PA', 'PE', 'PY', 'SV',
        ],
    },
];

export const SA_REGIONS = SA_REGION_CODES.map((r) => {
    r.countries = r.codes
        .map(countryCode => countries[countryCode])
        .map(c => Object.assign({ flag: ['flag-icon', `flag-icon-${ c.alpha2.toLowerCase() }`] }, c));
    return r;
});

export const buildSearchLabel =(fields) => {
    return fields
        .filter(a => a)
        .join(' - ');
};

export function sortByKey(array, key, order = 'ascend') {
    if (!array || !Array.isArray(array)) {
        return array;
    }
    return array.sort((a, b) => {
        let x = (typeof key === 'string' && key.includes('.')) ? key.split('.').reduce((xx, v) => xx[v], a) : a[key];
        let y = (typeof key === 'string' && key.includes('.')) ? key.split('.').reduce((xx, v) => xx[v], b) : b[key];
        if (x && typeof x === 'string') {
            x = x.trim();
        }
        if (y && typeof y === 'string') {
            y = y.trim();
        }

        const direction = order === 'ascend' ? 1 : -1;
        if (x < y) {
            return (-1 * direction);
        } else if (x > y) {
            return (1 * direction);
        }
        return 0;
    });
}

// Ascending: Nulls first; Descending: Nulls last
export function sortByKeyWithNulls(array, key, order = 'ascending') {
    if (!array || !Array.isArray(array)) {
        return array;
    }

    return array.sort((x, y) => {
        const a = (typeof key === 'string' && key.includes('.')) ? key.split('.').reduce((xx, v) => xx[v], x) : x[key];
        const b = (typeof key === 'string' && key.includes('.')) ? key.split('.').reduce((xx, v) => xx[v], y) : y[key];
        const isAscending = ['ascending', 'ASC', 'asc'].includes(order);

        const multiply = isAscending ? -1 : 1;
        if (!a && !b) {
            return 0;
        } else if (!a) {
            return multiply;
        } else if (!b) {
            return multiply * -1;
        } else {
            return ((a < b) ? (multiply) : ((a > b) ? (multiply * -1) : 0));
        }
    });
}

export function downloadXlsxFile(binaryData, fileName = 'file') {
    const downloadLink = document.createElement('a');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadLink.style.display = 'none';
    downloadLink.href = `data:${fileType};base64,${encodeURIComponent(binaryData)}`;
    downloadLink.download = `${fileName}.xlsx`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

// when sorting, this function ignores the list of values specified
export function sortByKeyIgnoring(array, key, order = 'ascending', ignoreList = [null, 0]) {
    if (!array || !Array.isArray(array)) {
        return array;
    }

    return array.sort((x, y) => {
        const a = x[key];
        const b = y[key];
        const isAscending = order === 'ascending';

        const multiply = isAscending ? -1 : 1;
        if (ignoreList.includes(a)) {
            return 1;
        } else if (ignoreList.includes(b)) {
            return -1;
        } else {
            return ((a < b) ? (multiply) : ((a > b) ? (multiply * -1) : 0));
        }
    });
}

export function trimAndCleanKeyword(kw) {
    return !kw || !kw.length ? kw : kw.trim()
        .replace(/ +/g, ' '); // replace all multiple spaces with a single space
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function downloadAsFile(filename, text) {
    const pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' +
        encodeURIComponent(text));
    pom.setAttribute('download', filename);

    pom.style.display = 'none';
    document.body.appendChild(pom);

    pom.click();

    document.body.removeChild(pom);
}

/**
 * Utility function that converts 2 dimensional array or array of objects to
 * csv text and calls downloadAsCsv
 *
 * @param filename name of the file to be downloaded
 * @param data 2 dimensional array of values that can be converted to string
 */
export function downloadDataAsCsv(filename, data) {
    function csvEscape(val) {
        const strVal = `${ typeof val === 'undefined' ? '-' : val }`;
        return strVal.search(/([",\n])/g) >= 0 ? `"${ strVal.replace(/"/g, '""') }"` : strVal;
    }

    let headers = null;

    downloadAsFile(filename, data.map((row) => {
        if (Array.isArray(row)) {
            return row.map(csvEscape).join();
        } else {
            if (!headers) { // ensure same order by storing first headers
                headers = Object.keys(row);
            }
            return headers.map(k => csvEscape(row[k])).join();
        }
    }).join('\n'));
}

export function redirectToLogin(redirect = false) {
    let { location } = window;
    const forwardTo = location.pathname || '/';

    if (redirect) {
        window.location.href =  INSIGHTS_DOMAIN + '/auth/login/searchads';
    } else {
        Router.push({ name: 'login', query: { forwardTo } }).catch(globalProperties.$log.error);
    }
}

export function redirectToIntegration(redirect = false) {
    if (redirect) {
        window.location.href = INSIGHTS_DOMAIN + '/auth/login/searchads';
    } else {
        Router.push({ name: 'integration' }).catch(globalProperties.$log.error);
    }
}

export const sendSentryError = (...args) => {
    if (!isProduction) {
        globalProperties.$log.debug('Skipped logging error...', ...args);
        return;
    }
    try {
        let e;
        if (args.length <= 0) {
            e = new Error('UNKNOWN ERROR');
        } else if ((args[0] instanceof Error)) {
            args[0].message = args[0].message
                + '\n'
                + args.slice(1).map(a => typeof (a) === 'string'
                    ? a
                    : JSON.stringify(a)
                ).join('\n');
            e = args[0];
        } else {
            e = new Error(args.map(a => typeof (a) === 'string'
                ? a
                : JSON.stringify(a)).join('\n')
            );
        }
        captureException(e);
    } catch (err) {
        const errorStacked = new Error('Failed to send Sentry error', { cause: err });
        globalProperties.$log.error(errorStacked);
        captureException(errorStacked);
    }
};

export function secureIconUrl(url) {
    return !url || /^https:\/\//.test(url)
        ? url
        : url.replace(/http:\/\/is(\d+)\.mzstatic/, 'https://is$1-ssl.mzstatic'); // convert to ssl if not
}


/**
 * Returns data as singleton array if not already an array
 * @param data
 * @returns {*[]}
 */
export function getAsArray(data) {
    return Array.isArray(data) ? data : [data];
}

function findOutForwardTo(registerType) {
    const forwardToTypes = {
        audit: '/onboarding/audit',
        grader: '/onboarding/grader',
        benchmarks: '/onboarding/benchmarks',
    };
    if (!registerType && !Object.keys(forwardToTypes).includes(registerType)) {
        return null;
    }
    return forwardToTypes[registerType];
}

/**
 * Restores reference params from storage to be used by register
 */
export function createReferenceParamsForSignUp(registerType) {
    const { utmVars } = globalProperties.$maLocalStorage;
    // recover references from storage
    const referenceParams = {
        friendReferrer: globalProperties.$maLocalStorage.friendReferrer,
        initialReferrer: globalProperties.$maLocalStorage.initialReferrer,
        landingPage: globalProperties.$maLocalStorage.landingPage,
        utm_source: Object.keys(utmVars).sort().map(k => `${ k }=${ utmVars[k] }`).join('&'),
        forwardTo: findOutForwardTo(registerType),
    };

    Object.keys(referenceParams).forEach((k) => {
        if (!referenceParams[k] || !referenceParams[k].length) {
            delete referenceParams[k];
        }
    });

    return referenceParams;
}

//login callbacks (google, linkedin etc)
export function getAuthRedirectUri(type = '') {
    return `${ window.origin }/auth/${ type }/callback`;
}

//sa integration callback
export function getAuthRedirectUriForIntegration() {
    if (!isProduction) {
        // Apple does not allow localhost registration and http redirects so domain registered to redirect to localhost'
        return 'https://searchads.com/localhost-callback';
    }
    return `${ window.origin }/sa-integ/auth/callback`;
}

export function isMissingAccessSearchads(e) {
    const { errors, errorData } = e;
    return (errors?.includes('UNAUTHORIZED'))
        && (errorData?.featureType === 'ACCESS_SEARCHADS_COM');
}

export function isNoData(e) {
    const { errorMessage, errorData, errors } = e;
    const errMessage = errorMessage || (errorData && errorData.errorMessage);
    return errMessage === 'No data' || (errors && errors.includes('NO_DATA'));
}

export function isRequestCanceled(e) {
    const { errors } = e;
    return (errors && errors.includes('REQUEST_CANCELED'));
}

export const lessThanMinutesAgo = (date, minutes) => {
    return dayjs(date).isAfter(dayjs().subtract(minutes, 'minutes'));
};

export const lessThanOneHourAgo = (date) => {
    return dayjs(date).isAfter(dayjs().subtract(1, 'hours'));
};

export const lessThanThreeHourAgo = (date) => {
    return dayjs(date).isAfter(dayjs().subtract(3, 'hours'));
};

export const lessThanOneDayAgo = (date) => {
    return dayjs(date).isAfter(dayjs().subtract(1, 'days'));
};

export const getStoreName = trackId => /^\d+$/.test('' + trackId) ? 'ios' : 'play';

// safe cast track id
export const safeTrackId = id => Number(id) || id;

// long safe check
export const safeTrackIdEquals = (id1, id2) => safeTrackId(id1) === safeTrackId(id2);

export const safeAppEquals = (a1, a2) => safeTrackIdEquals(a1?.trackId, a2?.trackId);
export const safeCountryEquals = (c1, c2) => {
    const toUpper = str => str?.toUpperCase();
    return toUpper(c1) === toUpper(c2);
};

export function mergeDeep(target, source) {
    const isObject = item => item && typeof item === 'object' && !Array.isArray(item);
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) {
                    Object.assign(output, { [key]: source[key] });
                } else {
                    output[key] = mergeDeep(target[key], source[key]);
                }
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

export function parseError(error) {
    let apiErrors = [];
    let errorCodes = [];
    let messages = [];
    let displayedMessage = '';
    let errorMessage = '';
    let errors = [];
    let timeOut = isTimeout(error);
    let noData = isNoData(error);
    let requestCanceled = isRequestCanceled(error);
    if (!error) {
        return {
            errors,
            errorMessage,
            apiErrors,
            errorCodes,
            messages,
            displayedMessage,
            timeOut,
            noData,
            requestCanceled,
        };
    }
    errorMessage = error?.errorMessage || error?.errorData?.message || error?.message || '';
    let strError;
    if (errorMessage && errorMessage.length) {
        strError = errorMessage;
        if (isJson(errorMessage)) {
            const parsed = JSON.parse(errorMessage);
            if (Array.isArray(parsed)) {
                apiErrors = parsed;
            } else if (isJson(parsed.message)) {
                apiErrors = JSON.parse(parsed.message);
            } else {
                strError = parsed.message;
            }
            errorCodes = [...new Set(apiErrors.map(a => a.messageCode))];
            messages = [...new Set(apiErrors.map(a => a.message))];
        }
    }
    if (messages && messages.length) {
        displayedMessage = messages;
    } else if (errorCodes && errorCodes.length) {
        displayedMessage = errorCodes;
    } else {
        displayedMessage = strError || errorMessage;
    }
    return {
        errors,
        errorMessage,
        apiErrors,
        errorCodes,
        messages,
        displayedMessage,
        timeOut,
        noData,
        requestCanceled,
    };
}

export function isJson(item) {
    try {
        JSON.parse(item);
    } catch (e) {
        return false;
    }
    return true;
}

/**
 * Converts given params to query string
 * @param params object mapping keys to values
 * @param keepComma list of keys which can keep their commas as , instead of %2C
 * @returns {string} query string starting with ?
 */
export function paramsToQuery(params, keepComma = ['rating']) {
    if (typeof params !== 'object') {
        return '';
    }
    const _keepComma = keepComma || [];
    const encode = (k) => {
        const encoded = encodeURIComponent(params[k]);
        return _keepComma.includes(k) ? encoded.replace(/%2C/g, ',') : encoded;
    };

    return Object.keys(params)
        .filter(k => typeof params[k] !== 'undefined' && params[k] !== null) // undefined or null shouldn't be sent
        .map(k => `${ k }=${ encode(k) }`)
        .join('&');
}

export const safeTrim = str => (str || '').trim();

export const safeStringEquals = (s1, s2) => `${s1}` === `${s2}`;

export function isTimeout(e) {
    const { errorMessage, errorData, errors } = e || {};
    const message = errorMessage || (errorData?.errorMessage);
    return message === 'Backend Timeout' || errors?.includes('BACKEND_TIMEOUT');
}

/**
 * Loads a given script from source as async and returns a promise that resolves on onload
 * @param src
 * @param containerSelector target container that script will be appended to defaults to body
 * @param options
 * @returns {Promise<unknown>}
 */
export function loadScriptAsync(src, containerSelector = 'body', options) {
    return new Promise((resolve, reject) => {
        if (Array.from(document.querySelectorAll(`script[src="${ encodeURI(src) }"`)).length) {
            return resolve();
        }

        const s = document.createElement('script');
        s.async = true;
        s.onerror = reject;
        s.onload = resolve;
        s.src = src;
        if (options?.defer) {
            s.defer = 'defer';
        }
        if (options?.async) {
            s.async = 'async';
        }
        if (options?.type) {
            s.type = options.type;
        }
        const container = (containerSelector && document.querySelector(containerSelector)) || document.body;
        container.appendChild(s);
    });
}

/**
 * Loads a given link from source as async and returns a promise that resolves on onload
 * @param href
 * @param containerSelector target container that script will be appended to defaults to body
 * @param options
 * @returns {Promise<unknown>}
 */
export function loadLinkAsync(href, containerSelector = 'body', options) {
    return new Promise((resolve, reject) => {
        if (Array.from(document.querySelectorAll(`link[href="${ encodeURI(href) }"`)).length) {
            return resolve();
        }

        const s = document.createElement('link');
        s.async = true;
        s.onerror = reject;
        s.onload = resolve;
        s.href = href;
        s.rel = 'stylesheet';
        s.type = 'text/css';
        if (options?.defer) {
            s.defer = 'defer';
        }
        if (options?.async) {
            s.async = 'async';
        }
        const container = (containerSelector && document.querySelector(containerSelector)) || document.body;
        container.appendChild(s);
    });
}

// from: https://stackoverflow.com/a/7616484
export function strHash(s) {
    if (typeof s !== 'string') {
        return 0;
    }

    let hash = 0;
    for (let i = 0; i < s.length; i += 1) {
        // bit or converts to 32bit integer
        // eslint-disable-next-line no-bitwise
        hash = (((hash << 5) - hash) + s.charCodeAt(i)) | 0;
    }
    return hash;
}

export function isTestUser(username) {
    return !username || strHash(username.toLowerCase()) === TEST_USER_HASH;
}

export const nFormatter = (num, digits) => {
    const si = [
        { value: 1, symbol: '' },
        { value: 1E3, symbol: 'k' },
        { value: 1E6, symbol: 'M' },
        { value: 1E9, symbol: 'G' },
        { value: 1E12, symbol: 'T' },
        { value: 1E15, symbol: 'P' },
        { value: 1E18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

/**
 * Sorts given object keys to produce consistent results
 * @param o original object
 * @param processField field processing function with signature processField(key, value) for doing custom processing
 * @returns {{}|*}
 */
export function sortObjectKeys(o, processField) {
    if (!o || !['object', 'array'].includes(typeof o)) {
        return o;
    }
    if (Array.isArray(o)) {
        return o.map(v => sortObjectKeys(v));
    }
    // default field to value mapper
    let _processField = processField && typeof processField === 'function' ? processField : (k, v) => v;

    return Object.keys(o).sort().reduce((r, k) => {
        r[k] = o[k] && typeof o[k] === 'object' ? sortObjectKeys(o[k]) : _processField(k, o[k]);
        return r;
    }, {});
}

export function generateFetchKey(obj) {
    return JSON.stringify(sortObjectKeys(obj, (k, v) => {
        if (k === 'trackId') {
            return `${v}`;
        }
        if (v && dayjs.isDayjs(v)) {
            return v.format(ISO_DATE_FORMAT);
        }
        return v;
    }));
}

export function isColumnSymbolOrNumeric(metric) {
    return Object.hasOwn(metric, 'operation');
}

export function formatAgeRange(ageRange) {
    if (!ageRange || ageRange?.toLocaleString()?.toUpperCase() === 'ALL') {
        return ageRange;
    }

    const [start, end] = ageRange;
    const endValue = end === 65 || end === 'null'
        ? '65+'
        : end;
    return [
        start,
        endValue,
    ].join(' - ');
}

export function getGravatarUrl(email) {
    return gravatar.url(email, {
        s: 64,
        d: 'identicon',
    }, true);
}

export function toTitleCase(str) {
    if (!str) {
        return str;
    }
    return str.replace(/[_\s]+/g, ' ')
        .split(' ')
        .map(s => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase())
        .join(' ');
}
export function snakeToCamel(str) {
    if (!str) {
        return str;
    }
    return str.toLowerCase().replace(/(_\w)/g, m => m.toUpperCase().substr(1));
}

export const showBadgeUntil = until => until && dayjs().diff(dayjs(until, ISO_DATE_FORMAT), 'days') < 0;

export function createMetaTags({ title, description, url, cardImage }) {
    if (!title) {
        console.warn('Meta missing title', arguments);
    }
    if (!description) {
        console.warn('Meta missing description', arguments);
    }
    if (!url) {
        console.warn('Meta missing url', arguments);
    }
    if (!cardImage) {
        console.warn('Meta missing cardImage', arguments);
    }
    return [
        { vmid: 'title', key: 'title', name: 'title', itemprop: 'name', content: title },
        { vmid: 'description', key: 'description', name: 'description', itemprop: 'description', content: description },
        { vmid: 'url', itemprop: 'url', content: url },

        { property: 'og:site_name', content: 'SearchAds.com - #1 Apple Search Ads Campaign Management and Intelligence Tool' },
        // The list of types is available here: http://ogp.me/#types
        { vmid: 'og:type', property: 'og:type', content: 'website' },
        { vmid: 'og:title', key: 'og:title', property: 'og:title', content: title },
        { vmid: 'og:description', key: 'og:description', property: 'og:description', content: description },
        { vmid: 'og:url', property: 'og:url', content: url },
        { vmid: 'og:image', key: 'og:image', property: 'og:image', content: cardImage },

        { vmid: 'twitter:title', key: 'twitter:title', name: 'twitter:title', content: title },
        { vmid: 'twitter:description', key: 'twitter:description', name: 'twitter:description', content: description },
        { vmid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
        { vmid: 'twitter:site', name: 'twitter:site', content: '@mobileaction' },
        { vmid: 'twitter:creator', name: 'twitter:creator', content: '@mobileaction' },
        { vmid: 'twitter:image', key: 'twitter:image', name: 'twitter:image', content: cardImage },
    ];
}

export function parseQuery(search) {
    const args = search.substring(1).split('&');
    const argsParsed = {};
    let i, arg, kvp, key, value;
    for (i=0; i < args.length; i++) {
        arg = args[i];
        if (-1 === arg.indexOf('=')) {
            argsParsed[decodeURIComponent(arg).trim()] = true;
        } else {
            kvp = arg.split('=');
            key = decodeURIComponent(kvp[0]).trim();
            value = decodeURIComponent(kvp[1]).trim();
            argsParsed[key] = value;
        }
    }
    return argsParsed;
}

/**
 * finds median for given items on arr
 * @param arr
 * @returns {*|number}
 */
export function findMedian(arr) {
    if (arr.length === 0) {
        return; // 0.
    }
    arr.sort((a, b) => a - b); // 1.
    const midpoint = Math.floor(arr.length / 2); // 2.
    return arr.length % 2 === 1 ?
        arr[midpoint] : // 3.1. If odd length, just take midpoint
        (arr[midpoint - 1] + arr[midpoint]) / 2; // 3.2. If even length, take median of midpoints
}
